import React, {FC, useEffect, useState} from 'react'
import DataTable from 'react-data-table-component'
import useAppToast from 'src/hooks/useAppToast'
import {getOrdersListAsync, resyncOrderItem} from 'src/redux/actions/orderlogActions'
import {useAppDispatch, useAppSelector} from 'src/redux/sotre.hooks'
import StatusComp from '../modules/orders/StatusComp'
import CustomDateRangePicker from 'src/utils/CustomDateRangePicker'
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import { SYNC_STATUS_LIST, TABLE_ROWS } from 'src/utils/constants'
import moment from 'moment'
import TransparentLoader from 'src/utils/TransparentLoader'
import { getDashboardStatCounts } from 'src/redux/actions/commonActions'
import { setOrderlogPaginationData } from 'src/redux/slices/orderlogSlice'

const resyncTooltip = (props) => (
  <Tooltip id="button-tooltip" className='fw-bold custom-tooltip'  {...props}>
      Resync
  </Tooltip>
);

const commonTooltip = (props) => (
  <Tooltip id="button-tooltip" className='fw-bold custom-tooltip'  {...props}>
      {props.msg}
  </Tooltip>
);

const ResyncButton: FC<{
  rowData
  onResync: () => void
}> = ({onResync, rowData}) => {
  const {isDisconnecting} = useAppSelector((state) => state.accounts)
  const [syncing, setSyncing] = useState(false)
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      // show={true}
      overlay={(props)=> resyncTooltip(props,)}
    >
    <button
      disabled={isDisconnecting}
      className='btn btn-sm btn-info p-2'
      onClick={() => onResync()}
    >
      <i className='bi bi-arrow-repeat pe-0'></i>
    </button>
    </OverlayTrigger>
  )
}

const Orders: FC = () => {
  const dispatch = useAppDispatch()
  const { orderlogLoading, orderlogs, totalOrderLogCount, resyncLoader, paginationData } = useAppSelector((state) => state.orderlog)
  const { dashboardActiveTab} = useAppSelector((state) => state.common)

  const [opendaterangepicker, setOpendaterangepicker] = useState(false)
  const {successToast, errorToast} = useAppToast()
  const [resetFilters, setResetFilters] = useState(false)

  // const [paginationData, setPaginationData] = useState({
  //   page_num: 1,
  //   page_limit: 10,
  //   from_date: '',
  //   to_date: '',
  //   q: '',
  //   sync_status: '',
  //   sort_direction: '', // DES for descending
  //   sort_by:''
  // })
  const resyncOrderLogItem = (itemData) => {
    dispatch(resyncOrderItem({
      record_id : itemData.order_id+'',
      platform : 'ORDER'
     }))
     .then((response)=>{
     console.log('response in resync', response)
      if(response?.payload?.status==200){
        successToast(response.payload?.message)
        dispatch(getOrdersListAsync({...paginationData}))
        dispatch(getDashboardStatCounts(''))

        return
      }else{
        errorToast(response.payload?.data?.message)
        dispatch(getOrdersListAsync({...paginationData}))
        dispatch(getDashboardStatCounts(''))

        return 
      }
     })
     .catch((error)=>{
      console.log('error in resync', error)
     })
  }

  const columns = [
    
    {
      name: 'Source Order #',
      sortable: true,
      sortField: 'order_id',
      width:"170px",
      cell: (row) => (
        <OverlayTrigger
        placement="top"
        overlay={(props)=> commonTooltip({...props, msg:row.order_link })}
      >
        <a className='link text-truncate fw-bold' target='_blank' href={row.order_link}>{row.order_id}</a>
      </OverlayTrigger>
      ),
    },
    {
      name: 'Destination Order #',
      selector: (row) => row.destination_ref,
      sortable: true,
      sortField: 'destination_ref',
      width:"220px"
       
    },
    {
      name: 'Order Source',
      selector: (row) => row.source,
      sortable: false,
      sortField: 'source',
      width:"140px"
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      sortField: 'sync_status',
      cell: (row, index, column, id) => {
        return <StatusComp status={row.sync_status} message={row?.message} />
      },
    },
    
    {
      name: 'Synced / Updated At',
      selector: (row) => row.synced_at,
      sortable: true,
      sortField: 'synced_at',
      width:"240px",
      center:true,
      cell: (row, index, column, id) => {
        return <p>{ row?.synced_at ? moment.utc(row.synced_at).format("DD-MMM-YY, hh:mm A [(UTC)]") : '' }</p>
      },
    },
    {
      name: 'eBay Ordered At',
      // selector: (row) => row.updated_at,
      sortable: true,
      sortField: 'ordered_at',
      cell: (row, index, column, id) => {
        return <p>{ row?.ordered_at ? moment.utc(row.ordered_at).format("DD-MMM-YY, hh:mm A") : '' }</p>
      },
      width:"180px"
    },

    {
      name: 'Action',
      // selector: (row) => row.disconnect_url,
      sortable: false,
      sortField: 'action',
      cell: (row, index, column, id) => {
        return row.sync_status == 'FAILED' ? (
          <ResyncButton
            rowData={row}
            onResync={() => {
              resyncOrderLogItem(row)
            }}
          />
        ) : null
      },
    },
  ]

  const handleSort = (ev, direction)=>{
    console.log('ev', ev)
    dispatch(setOrderlogPaginationData({sort_by: ev.sortField, sort_direction:(direction+"").toString().toUpperCase() }))
  }
 

  useEffect(() => {
    if(dashboardActiveTab==1){
      dispatch(getDashboardStatCounts({...paginationData}))
      dispatch(getOrdersListAsync({...paginationData}))
    }

  }, [paginationData, dashboardActiveTab])


  return (
    <>
      <div>
        {/* table header section start */}
        <div className='navbar my-3 py-2'>
          <div> 
            <form className='form-inline ms-2'>
              <input
                className='form-control mr-sm-2 border border-secondary'
                type='search'
                value={paginationData.q}
                placeholder='Search Order'
                aria-label='Search'
                onChange={(ev) => {
                  dispatch(setOrderlogPaginationData({q: ev.target.value, page_num:1}))
                }}
              />
            </form>
          </div>
          <div className='d-inline-flex'>
              <div className='form-group me-2'>
                <button
                  style={{paddingTop:"8px", paddingBottom:"8px"}}
                  className='form-control border border-secondary'
                  onClick={() => {
                    setOpendaterangepicker(!opendaterangepicker)
                  }}
                >
                  {paginationData.from_date || paginationData.to_date
                    ? moment(paginationData.from_date).format('DD/MMM/YY') +
                      ' - ' +
                      moment(paginationData.to_date).format('DD/MMM/YY')
                    : 'Pick Date Range'}
                </button>
                <CustomDateRangePicker
                  isVisible={opendaterangepicker}
                  handleClose={() => setOpendaterangepicker(false)}
                  onPickRange={(range) => {
                    // setPaginationData((pre) => {
                    //   return {...pre, from_date: range.startDate, to_date: range.endDate}
                    // })
                    console.log('range', range)
                    dispatch(setOrderlogPaginationData({from_date: range.startDate, to_date: range.endDate}))

                  }}
                />
              </div>
              <div className='form-group me-2'>
                <select
                  style={{paddingTop:"8px", paddingBottom:"8px"}}
                  className='form-select border border-secondary'
                  id='exampleFormControlSelect1'
                  value={paginationData.sync_status}
                  onChange={(ev) => {
                    // setPaginationData((pre) => {
                    //   return {...pre, sync_status: ev.target.value}
                    // })
                    dispatch(setOrderlogPaginationData({sync_status: ev.target.value}))
                    
                  }}
                >
                  <option value=''>ALL</option>
                  {SYNC_STATUS_LIST.map((item) => {
                    return <option value={item}>{item}</option>
                  })}
                </select>
              </div> 
            <button
              className='btn btn-sm btn-info ms-2'
              onClick={() =>{

                dispatch(setOrderlogPaginationData({
                  page_num: 1,
                  page_limit: 10,
                  from_date: '',
                  to_date: '',
                  q: '',
                  sync_status: '',
                  sort_direction: '',
                  sort_by: '', // DES for descending
                }))
                dispatch(getDashboardStatCounts(''))
                setResetFilters(!resetFilters)
              }}
            >
              Reset Filters
            </button>
          </div>
        </div>
        {/* table header section end */}

        <div className='border'>
          <DataTable
            key={"ordertable"+resetFilters}
            responsive
            columns={columns}
            data={orderlogs}
            progressPending={orderlogLoading}
            paginationRowsPerPageOptions={TABLE_ROWS}
            paginationTotalRows={totalOrderLogCount}
            onSort={handleSort}
            pagination
            paginationServer
            className='align-items-center'
            highlightOnHover
            sortServer
            onChangePage={(pageNum, totalRows) => {
              // setPaginationData((pre) => {
              //   return {...pre, page_num: pageNum}
              // })
              dispatch(setOrderlogPaginationData({page_num: pageNum}))

            }}
            onChangeRowsPerPage={(currentPage)=>{
              // setPaginationData((pre) => {
              //   return {...pre, page_limit: currentPage }
              // })
              dispatch(setOrderlogPaginationData({page_limit: currentPage}))

            }}
           
            customStyles={{
              header: {
                style: {fontSize: 16, fontWeight: 'bold', borderBottom: '5px solid black'},
              },
              headCells: {
                style: {justifyContent: 'center', textAlign: 'center', fontSize: 16},
              },
              cells: {
                style: {alignItems: 'center', textAlign: 'center', justifyContent: 'center'},
              },
            }}
          />
        </div>
      </div>
      <TransparentLoader isVisible={resyncLoader} />
    </>
  )
}

export default Orders
