import {AsyncThunkConfig, GetThunkAPI} from '@reduxjs/toolkit/dist/createAsyncThunk'
import axios from 'axios'
import React, {FC} from 'react'
import {appEnv} from 'src/app/config'
import {localStorageTokenName, refreshTokenCount, refreshTokenName, showOnceErrorCount} from 'src/utils/constants'
import useAppToast from 'src/hooks/useAppToast'
import {logout} from './slices/authSlice'
import {refreshTokenAsync} from './actions/authActions'
import {messageTemplate} from 'src/utils/messageTemplate'
import { setInternalServerError } from './slices/commonSlice'

type REQUEST_METHODS = 'GET' | 'POST' | 'PUT' | 'DELETE'
type ENDPOINT = string
type TOOLKIT = GetThunkAPI<AsyncThunkConfig>
type PARAMS = Object
type HEADER_OPTIONS = any

const getTokenSync = () => {
  return localStorage.getItem(localStorageTokenName)
}

const refreshToken = localStorage.getItem(refreshTokenName)

const AxiosClient = async (
  method: REQUEST_METHODS,
  endPoint: ENDPOINT,
  payload: any,
  toolkit: TOOLKIT,
  params: PARAMS
) => {
  const token = getTokenSync()
  const {warningToast} = useAppToast()
  
  const headerOptions = {
    Accept: 'application/json',
  }

  if (payload._boundary) {
    headerOptions['Content-Type'] = `multipart/form-data, boundary=${payload._boundary}`
  }

  if (token) {
    headerOptions['Authorization'] = `Bearer ${token}`
  }

  return axios({
    url: appEnv.BASE_URL + endPoint,
    method: method,
    data: payload,
    params: {...params},
    headers: {...headerOptions},
  })
    .then((response) => {
      // if (response.status == 401) {
      //   console.log('1')

      //   //if token is exist but account is not then we are logging out
      //   if (response?.data?.code == 'user_not_found') {
      //     // toolkit.dispatch(logout())
      //   }

      //   // if token is not valid requesting for new token
      //   if (response.data.code == 'token_not_valid') {
      //     console.log('2')
      //   }
      // }
      return toolkit.fulfillWithValue(response.data)
    })
    .catch((error) => {
      console.log('response in axios--', error, error?.code )
      const showOnceErr = localStorage.getItem(showOnceErrorCount)
      const refreshCount = localStorage.getItem(refreshTokenCount)

      if(error?.code=='ERR_NETWORK'){
        if(showOnceErr=='0'){
          warningToast(error?.message)
          toolkit.dispatch(setInternalServerError({isError:true}))
        }
        localStorage.setItem(showOnceErrorCount,'1')
      }

      if (error.response.status == 401) {
        // console.log('7')

        //if token is exist but account is not then we are logging out
        if (error?.response.data.code == 'user_not_found') {
          toolkit.dispatch(logout())
        }

        if (error?.response.data.code == 'user_inactive') {
          toolkit.dispatch(logout())
        }

        // if token is not valid requesting for new token
        if (error.response.data.code == 'token_not_valid') {
          if(refreshToken){
            if(refreshCount=='0'){
              const formData = new FormData()
              formData.append('refresh', refreshToken )
              toolkit.dispatch(refreshTokenAsync(formData))
              .then((tokenResponse)=>{
                console.log('tokenResponse', tokenResponse)
                // when token is not valid like user is manually changed it
                if(tokenResponse?.payload?.access){
                    localStorage.setItem(refreshTokenCount, '0')
                    AxiosClient(method,endPoint,payload,toolkit,params)
                }
                if(tokenResponse.payload.status==401){
                  warningToast(messageTemplate.TOKEN_EXPIRED)
                  toolkit.dispatch(logout())
                }
              })
              localStorage.setItem(refreshTokenCount, '1')
            }
          }
          // toolkit.dispatch(logout())
        }
      }

      


      // toolkit.dispatch(errorMessage(error.response))
      return toolkit.rejectWithValue(error.response)
    })
}

export default AxiosClient
