import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {
  getConfigurationDataAsync,
  getConfigurationSettings,
  setConfigurationDataAsync,
  syncConfigurationDataAsync,
} from '../actions/configurationActions'
import {INITIAL_STATE_FOR_CREATE_ORDER} from 'src/interfaces/configuration.interface'

const initialState: INITIAL_STATE_FOR_CREATE_ORDER = {
  loadingConfigurationData: false,
  syncLoader: false,
  core_pay_method_list: [],
  coreforce_product_identifier_list: [],
  ebay_pay_method_list: [],
  ebay_product_identifier_list: [],
  coreforce_order_method_list: [],
  coreforce_location_list: [],
  ebay_shipping_methods:[],
  // coreforce_shipping_methods:[],
  status_list: [],
  configuredSetting: {
    id: null,
    sync_start_date: '',
    allow_order_sync: false,
    allow_inventory_sync: false,
    allow_shipment_sync:false,
    order_method_id: '',
    coreforce_product_identifier: '',
    ebay_product_identifier: '',
    inventory_coreforce_product_identifier: '',
    inventory_ebay_product_identifier: '',
    // inventory_coreforce_location_mapping:[],
    selected_locations: [],
    no_manage_stock_quantity: '',
    order_source: '',
    status_selected: '',
    updated_at: '',
    created_at: '',
    user: null,
    payment_mapping: [],
    shipment_methods:[],
    shipping_product_sku:'',
    buffer_quantity:null
  },
  unmapped_methods_list:[]
}

const configurationSlice = createSlice({
  name: 'configurationSlice',
  initialState,
  extraReducers: (builder) => {
    // get initial values for create order start
    builder.addMatcher(
      isAnyOf(
        getConfigurationDataAsync.pending,
        setConfigurationDataAsync.pending,
        getConfigurationSettings.pending
      ),
      (state) => {
        console.log('getConfigurationDataAsync is in pending state ')
        state.loadingConfigurationData = true
      }
    )
    builder.addMatcher(
      isAnyOf(
        getConfigurationDataAsync.rejected,
        setConfigurationDataAsync.rejected,
        getConfigurationSettings.rejected
      ),
      (state, error) => {
        console.log('getConfigurationDataAsync is in rejected state ', error)
        state.loadingConfigurationData = false
      }
    )
    builder.addMatcher(isAnyOf(getConfigurationDataAsync.fulfilled), (state, action) => {
      state.core_pay_method_list = action.payload?.core_force_payment_methods
      state.coreforce_product_identifier_list = action.payload?.coreforce_product_identifier
      state.status_list = action.payload?.ebay_order_status
      state.ebay_pay_method_list = action.payload?.ebay_payment_methods
      state.ebay_product_identifier_list = action.payload?.ebay_product_identifier
      state.loadingConfigurationData = false
      state.coreforce_order_method_list = action.payload?.coreforce_order_method
      state.coreforce_location_list = action.payload?.coreforce_location_list
      state.ebay_shipping_methods = action.payload?.ebay_shipping_methods
      // state.coreforce_shipping_methods = action.payload?.coreforce_shipping_methods
      state.unmapped_methods_list = action?.payload?.unmapped_methods_list
      if(action.payload?.platform_configurations?.length){
        state.configuredSetting = action.payload?.platform_configurations[0]
      }
      console.log('configuration data ----->', action.payload)
    })
    // get initial values for create order end

    // set configuration data start
    builder.addMatcher(isAnyOf(setConfigurationDataAsync.fulfilled), (state, action) => {
      state.loadingConfigurationData = false
      console.log('configuration data ----->', action.payload)
    })
    // set configuration data end

    // sync configuration data start
    builder.addMatcher(isAnyOf(syncConfigurationDataAsync.pending), (state) => {
      console.log('syncConfigurationDataAsync is in pending state ')
      state.syncLoader = true
    })
    builder.addMatcher(isAnyOf(syncConfigurationDataAsync.rejected), (state) => {
      console.log('syncConfigurationDataAsync is in rejected state ')
      state.syncLoader = false
    })
    builder.addMatcher(isAnyOf(syncConfigurationDataAsync.fulfilled), (state, action) => {
      state.syncLoader = false
      // state.core_pay_method_list = action.payload.core_force_payment_methods
      // state.coreforce_product_identifier_list = action.payload.coreforce_product_identifier
      // state.status_list = action.payload.ebay_order_status
      // state.ebay_pay_method_list = action.payload.ebay_payment_methods
      // state.ebay_product_identifier_list = action.payload.ebay_product_identifier
      // state.coreforce_location_list = action.payload.coreforce_location_list
      console.log('configuration data ----->', action.payload)
    })
    // sync configuration data end

    // get setting of configuration start
    builder.addMatcher(isAnyOf(getConfigurationSettings.fulfilled), (state, action) => {
      console.log('configured setting data ----->', action.payload)
      // we are not using it coz we are now also getting data in same api get-configuration-data 
      state.loadingConfigurationData = false
    })
    // get setting of configuration end
  },
  reducers: {},
})

export const {} = configurationSlice.actions

export default configurationSlice.reducer
