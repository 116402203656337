export const messageTemplate = {
    // Auth section
    SUCCESSFUL_LOGIN :"Logged in successfully",
    CREDENTIAL_MISMATCH:"Credential Mismatch",
    SOMETHING_WENT_WRONG :"Something went wrong",
    LOGIN_ERROR:"Credential mismatch or not found",

    // Message on account section
    DISCONNECT_TITLE:"Are you sure want to disconnect from service",
    DISCONNECT_SUBTITLE:"After this your account will disconnected ",

    //Token
    TOKEN_EXPIRED:"Session expired. please try after re login",

    NO_CONNECTION_MESSAGE:"To proceed with the integration, you'll need to set it up first.",
    NO_CONNECTION_HELPER_MESSAGE:" Click the button below to get started."
}