import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'

export const loginAsync = createAsyncThunk(
  'auth/loginAsync',
  async function (payload: FormData, toolkit) {
    return await AxiosClient('POST', '/login', payload, toolkit, [])
  }
  )
  
  export const registrationAsync = createAsyncThunk(
    'auth/registrationAsync',
    async function (
      payload: any,
      toolkit
      ) {
    console.log('payload---', payload.entries())
    return await AxiosClient('POST', '/register', payload, toolkit, [])
  }
)

export const refreshTokenAsync = createAsyncThunk(
  'auth/refreshTokenAsync',
  async function (payload: FormData, toolkit) {
    return await AxiosClient('POST', '/token/refresh', payload, toolkit, [])
  }
)


export const verifyEmailForPasswordResetAsync = createAsyncThunk(
  'auth/verifyEmailForPasswordResetAsync',
  async function (payload: {email:string}, toolkit) {
    return await AxiosClient('POST', '/forget-password-request', payload, toolkit, [])
  }
)

export const getLoggedInUserDetailAsync = createAsyncThunk(
  'auth/getLoggedInUserDetailAsync',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', '/user-detail', [], toolkit, params)
  }
)


export const resetPasswordAfterVerifyEmailAsync = createAsyncThunk(
  'auth/resetPasswordAfterVerifyEmailAsync',
  async function (payload: {email:string, token:string, password:string, confirm_password:string }, toolkit) {
    return await AxiosClient('POST', '/reset-user-password', payload, toolkit, [])
  }
)


export const getUserInfoAsync = createAsyncThunk(
  'auth/getUserInfoAsync',
  async function (_, toolkit ) {
    return await  AxiosClient('GET', '/get-user-info', [], toolkit, [])
    
  }
)

export const updateUserInfo = createAsyncThunk(
  'auth/updateUserInfo',
  async function ( payload:{first_name:string, last_name:string} , toolkit ) {
    return await  AxiosClient('PUT', '/update-user-info', payload, toolkit, [])
    
  }
)

export const updateUserPassword = createAsyncThunk(
  'auth/updateUserPassword',
  async function ( payload:{old_password:string, new_password:string} , toolkit ) {
    return await  AxiosClient('POST', '/change-password', payload, toolkit, [])
    
  }
)

export const getUserListAsync = createAsyncThunk(
  'auth/getUserListAsync',
  async function ( params:any , toolkit ) {
    return await  AxiosClient('GET', '/api-users', [], toolkit, params)
  }
)

export const changeUserStatusAsync = createAsyncThunk(
  'auth/changeUserStatusAsync',
  async function ( params:{userId:string, changeThisStatus:boolean} , toolkit ) {
    return await  AxiosClient('POST', `/user-status/${params.userId}/${params.changeThisStatus}`, [], toolkit, [])
  }
)

export const loginAsUserAsync = createAsyncThunk(
  'auth/loginAsUserAsync',
  async function ( params:{userId:string} , toolkit ) {
    return await  AxiosClient('POST', `/portal-admin-login/${params.userId}`, [], toolkit, [])
  }
)

export const deleteUserAsync = createAsyncThunk(
  'auth/deleteUserAsync',
  async function ( params:{userId:string} , toolkit ) {
    return await  AxiosClient('POST', `/delete-user/${params.userId}`, [], toolkit, [])
  }
)
