import React, { FC } from 'react'
import { Link } from 'react-router-dom'

const EmptyPageWithoutACDetails:FC<{title:string, subtitle:string, navigateTo:string }> = ({navigateTo,subtitle,title}) => {
  return (
    <div>
        <div className='card rounded shadow mt-16' >
            <div className='p-4 mb-4  bg-warning shadow-bottom shadow shadow-lg' >
                <p className='h3 text-dark' >Alert</p>
            </div>
            <div className='p-8 py-2' >
                <p className='h3' >{title} <strong className='text-info' >COREFORCE</strong> and <strong className='text-info' >EBAY</strong> </p>
            </div>
            <div className='p-8 pt-0' >
            <p className="h5 text-muted">{subtitle}</p>
            <Link to={navigateTo} className='fw-bold text-info text-decoration-underline' >Click Here To Create Account</Link>

            </div>
        </div>
    </div>
  )
}

export default EmptyPageWithoutACDetails