import React, {FC, useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import DataTable, {TableProps, TableColumn} from 'react-data-table-component'
import CoreForceConnectionCard from 'src/app/modules/connection/CoreForceConnectionCard'
import EbayConnectionCard from 'src/app/modules/connection/EbayConnectionCard'
import useAppToast from 'src/hooks/useAppToast'
import {ACCOUNT_DETAIL, ACTIVE_ACCOUNT_ITEM} from 'src/interfaces/account.interface'
import {
  disconnectAccountAsync,
  getAccountsDetailsAsync,
  getActiveAccountsListAsync,
  isInitialSyncProcessAsync,
} from 'src/redux/actions/connectionActions'
import {useAppDispatch, useAppSelector} from 'src/redux/sotre.hooks'
import AlertModal from 'src/utils/AlertModal'
import ListEmptyDetail from 'src/utils/ListEmptyDetail'
import Loading from 'src/utils/Loading'
import {messageTemplate} from 'src/utils/messageTemplate'
import AddConfigurationNotification from '../modules/connection/AccountsConnectedNotification'
import InitialSyncMessage from '../modules/common/components/InitialSyncMessage'
import { getConfigurationDataAsync } from 'src/redux/actions/configurationActions'

const statusComp = (st: boolean) => (
  <span className={`badge badge-success px-6`}>{'Connected'}</span>
)
const DisconnectButton: FC<{
  rowData: ACTIVE_ACCOUNT_ITEM
  onDisconnect: (itemData: ACTIVE_ACCOUNT_ITEM) => void
}> = ({onDisconnect, rowData}) => {
  const {isDisconnecting} = useAppSelector((state) => state.accounts)

  return (
    <button
      disabled={isDisconnecting}
      className='btn btn-sm btn-danger'
      onClick={() => onDisconnect(rowData)}
    >
      Disconnect
    </button>
  )
}

const Connections: FC = () => {
  const dispatch = useAppDispatch()
  const {successToast, errorToast} = useAppToast()
  const {loadingAccountDetail, accounts, active_accounts, isDisconnecting, isBothAccountConnected, initialSyncLoader, initialSyncedStatus, initialSyncMessage } = useAppSelector(
    (state) => state.accounts
  )

  const { configuredSetting } = useAppSelector(state=>state.configuration)
  const [openAlertModal, setOpenAlertModal] = useState(false)
  const [disconnectingData, setDisconnectingData] = useState<null | ACTIVE_ACCOUNT_ITEM>(null)

  const disconnectAccount = () => {
    if (disconnectingData) {
      dispatch(disconnectAccountAsync({disconnectId: disconnectingData.disconnect_url}))
        .then((response) => {
          if (response.payload.status == 200) {
            successToast(response.payload.message)
            dispatch(getActiveAccountsListAsync({}))
            dispatch(getAccountsDetailsAsync({}))
            setOpenAlertModal(false)
          }
        })
        .catch((err) => {
          console.log('err', err)
          errorToast('Unable to disconnect yet')
        })
    }
  }

  const onClickDisconnect = (itemData) => {
    setOpenAlertModal(true)
    setDisconnectingData(itemData)
  }

  const columns = [
    {
      name: '',
      selector: (row: ACTIVE_ACCOUNT_ITEM, index) => index,
      sortable: true,
      sortField: 'index',
      width: '100px',
      cell: (row, index, column, id) => {
        return index + 1
      },
    },
    {
      name: 'Platform',
      selector: (row: ACTIVE_ACCOUNT_ITEM) => row.platform,
      sortable: true,
      sortField: 'platform',
      // style:{ paddingStart:"50px", justifyContent:"flex-start" }
    },
    {
      name: 'Friendly Name',
      selector: (row: ACTIVE_ACCOUNT_ITEM) => row.friendly_name,
      sortable: true,
      sortField: 'friendly_name',
    },
    {
      name: 'Status',
      selector: (row: ACTIVE_ACCOUNT_ITEM) => row.status,
      sortable: true,
      sortField: 'status',
      cell: (row, index, column, id) => {
        return statusComp(row.status)
      },
    },
    {
      name: 'Action',
      selector: (row: ACTIVE_ACCOUNT_ITEM) => row.disconnect_url,
      sortable: true,
      sortField: 'action',
      cell: (row, index, column, id) => {
        return <DisconnectButton rowData={row} onDisconnect={onClickDisconnect} />
      },
    },
  ]

  useEffect(() => {
    dispatch(getActiveAccountsListAsync({}))
    dispatch(getAccountsDetailsAsync({}))
  }, [])
  
  useEffect(() => {
    if(isBothAccountConnected){
      dispatch(isInitialSyncProcessAsync(''))
      dispatch(getConfigurationDataAsync(''))
    } 
}, [isBothAccountConnected])

  if (loadingAccountDetail || initialSyncLoader ) return <Loading />

  console.log('configuredSetting', configuredSetting)

  return (
    <>
      <div>
        <div className='container pb-4'>
        { isBothAccountConnected &&
                initialSyncedStatus &&
                (
                  <div>
                    <InitialSyncMessage message={initialSyncMessage} />
                  </div>
                )
              }
          <div>
          { isBothAccountConnected && configuredSetting.id==null   ? <AddConfigurationNotification/> : null}
          </div>

          <div className='border border-2 rounded'>
            <DataTable
              title='Active Accounts'
              responsive
              paginationPerPage={5}
              columns={columns}
              data={active_accounts}
              // onSort={handleSort}
              pagination
              className='align-items-center'
              highlightOnHover
              // sortServer
              customStyles={{
                header: {
                  style: {fontSize: 16, fontWeight: 'bold'},
                },
                headCells: {
                  style: {justifyContent: 'center', textAlign: 'center', fontSize: 16},
                },
                cells: {
                  style: {alignItems: 'center', textAlign: 'center', justifyContent: 'center'},
                },
              }}
            />
          </div>
          <div className='separator my-8'></div>
          {active_accounts.length <= 2
            ? (!active_accounts.find((item) => item.platform == 'COREFORCE') ||
                !active_accounts.find((item) => item.platform == 'EBAY')) && (
                <div className='row gx-5'>
                  {accounts.length ? (
                    accounts.map((account: ACCOUNT_DETAIL) => {
                      if (account.type == 'EBAY') {
                        if((!active_accounts.find((item) => item.platform == 'EBAY'))){
                          return (
                            <div className='col-md-6 '>
                              <EbayConnectionCard data={account} />
                            </div>
                          )
                        }
                      } else {
                        if((!active_accounts.find((item) => item.platform == 'COREFORCE'))){
                          return (
                            <div className='col-md-6'>
                              <CoreForceConnectionCard data={account} />
                            </div>
                          )
                        }
                      }
                    })
                  ) : (
                    <ListEmptyDetail />
                  )}
                </div>
              )
            : null}
        </div>
      </div>
      <AlertModal
        successBtnTitle='Yes, Disconnect'
        cancelBtnTitle='Not now'
        successDisabled={isDisconnecting}
        onCancel={() => setOpenAlertModal(false)}
        onSuccess={() => {
          disconnectAccount()
        }}
        isVisible={openAlertModal}
        setIsVisible={() => setOpenAlertModal(false)}
        title={messageTemplate.DISCONNECT_TITLE}
        subtitle={messageTemplate.DISCONNECT_SUBTITLE}
      />
    </>
  )
}

export default Connections
