/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {Tab, Tabs} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {PageTitle} from 'src/_master-config/layout/core'
import EmptyPageWithoutACDetails from 'src/app/modules/configation/EmptyPageWithoutACDetails'
import {getConfigurationDataAsync} from 'src/redux/actions/configurationActions'
import {
  getActiveAccountsListAsync,
  isInitialSyncProcessAsync,
} from 'src/redux/actions/connectionActions'
import {useAppDispatch, useAppSelector} from 'src/redux/sotre.hooks'
import Loading from 'src/utils/Loading'
import Orders from '../Orders'
import InitialSyncMessage from 'src/app/modules/common/components/InitialSyncMessage'
import Inventories from '../Inventories'
import {getDashboardStatCounts} from 'src/redux/actions/commonActions'
import {messageTemplate} from 'src/utils/messageTemplate'
import Shipments from '../Shipments'
import {setDashboardActiveTab} from 'src/redux/slices/commonSlice'
import { setOrderlogPaginationData } from 'src/redux/slices/orderlogSlice'
import { setShipmentlogPaginationData } from 'src/redux/slices/shipmentlogSlice'
import { setInventoryLogPaginationData } from 'src/redux/slices/inventorySlice'
import ProductLogs from '../ProductsLog'
import { setProductLogPaginationData } from 'src/redux/slices/productlogSlice'
import { getProductsCountAsync } from 'src/redux/actions/productLogActions'

const DashboardPage: FC = () => {
  const dispatch = useAppDispatch()
  const activeTab = 'fs-4 fw-bold bg-white text-info'
  const inactiveTab = 'fs-4 fw-bold bg-white text-muted'
  const {counts, dashboardCountLoader, dashboardActiveTab, shipmentCount, inventoryCount } = useAppSelector((state) => state.common)
  const { productCount,productCountLoader,paginationData: productLogPaginationData } = useAppSelector(state=>state.productLog)
  const {
    active_accounts,
    loadingAccountDetail,
    isBothAccountConnected,
    initialSyncedStatus,
    initialSyncMessage,
  } = useAppSelector((state) => state.accounts)

  useEffect(() => {
    dispatch(getActiveAccountsListAsync(''))
  }, [])

  useEffect(() => {
    if (isBothAccountConnected) {
      dispatch(getConfigurationDataAsync(''))
      dispatch(isInitialSyncProcessAsync(''))
    }
  }, [isBothAccountConnected])

  useEffect(() => {
    if(dashboardActiveTab==4){
      dispatch(getProductsCountAsync({...productLogPaginationData}))
    }
  }, [dashboardActiveTab,productLogPaginationData])
  

  if (loadingAccountDetail) return <Loading />

  if (
    active_accounts.length < 2 || active_accounts.length >= 2
      ? !active_accounts.find((item) => item.platform == 'COREFORCE') ||
        !active_accounts.find((item) => item.platform == 'EBAY')
      : false
  )
    return (
      <EmptyPageWithoutACDetails
        title={messageTemplate.NO_CONNECTION_MESSAGE}
        subtitle={messageTemplate.NO_CONNECTION_HELPER_MESSAGE}
        navigateTo='/connections'
      />
    )

  return (
    <>
      {isBothAccountConnected && initialSyncedStatus && (
        <div>
          <InitialSyncMessage message={initialSyncMessage} />
        </div>
      )}
      {/* begin::Row */}
      { dashboardActiveTab ==1 && <div className='bg-white row py-8 pt-0 gy-5 g-xl-4'>
        <div className='col-xl-4' >
          <div className='mt-8 card card-custom card-flush shadow border border-2 '>
            <div
              className='p-4 bg-success rounded position-absolute bottom-75 ms-4'
              style={{width: 'max-content'}}
            >
              <i className='bi fs-2 bi-arrow-repeat text-white fw-bold'></i>
            </div>
            <div className='card-body p-5 mt-2 pb-0 d-flex justify-content-between align-items-center'>
              <p className=' mb-0 fs-4 fw-bold text-muted text-truncate btn btn-link text-hover-primary' onClick={()=>{
                dispatch(setOrderlogPaginationData({sync_status:"SYNCED", page_num:1 }))
              }} >Total Synced Orders</p>
              <p className=' mb-0 fs-4 fw-bold text-info cursor-pointer bg-secondary px-4 py-2 bg-secondary rounded' onClick={()=>{
                dispatch(setOrderlogPaginationData({sync_status:"SYNCED", page_num:1 }))
              }} >{counts['synced_orders']}</p>
            </div>
            <div className='separator m-4 border-secondary'></div>
          </div>
        </div>
        <div className='col-xl-4' >
          <div className='mt-8 card card-custom card-flush shadow border border-2 '>
            <div
              className='p-4 bg-warning rounded position-absolute bottom-75 ms-4'
              style={{width: 'max-content'}}
            >
              <i className='bi fs-2 bi-hourglass-top text-white fw-bold'></i>
            </div>
            <div className='card-body p-5 mt-2 pb-0 d-flex justify-content-between align-items-center'>
              <p className=' mb-0 fs-4 fw-bold text-muted btn btn-link text-hover-primary'  onClick={()=>{
                dispatch(setOrderlogPaginationData({sync_status:"PENDING", page_num:1 }))
              }}>Total Pending Orders</p>
              <p className=' mb-0 fs-4 fw-bold text-info cursor-pointer bg-secondary px-4 py-2 bg-secondary rounded' onClick={()=>{
                dispatch(setOrderlogPaginationData({sync_status:"PENDING", page_num:1 }))
              }} >{counts.pending_orders}</p>
            </div>
            <div className='separator m-4 border-secondary'></div>
          </div>
        </div>
        <div className='col-xl-4' >
          <div className='mt-8 card card-custom card-flush shadow border border-2 '>
            <div
              className='p-4 bg-danger rounded position-absolute bottom-75 ms-4'
              style={{width: 'max-content'}}
            >
              <i className='bi fs-2 bi-gear-wide-connected text-white fw-bold'></i>
            </div>
            <div className='card-body p-5 pb-0 mt-2 d-flex justify-content-between align-items-center'>
              <p className=' mb-0 fs-4 fw-bold text-muted btn btn-link text-hover-primary' onClick={()=>{
                dispatch(setOrderlogPaginationData({sync_status:"FAILED", page_num:1 }))
              }}>Total Failed Orders</p>
              <p className=' mb-0 fs-4 fw-bold text-info cursor-pointer bg-secondary px-4 py-2 bg-secondary rounded' onClick={()=>{
                dispatch(setOrderlogPaginationData({sync_status:"FAILED", page_num:1 }))
              }} >{counts.failed_orders}</p>
            </div>
            <div className='separator m-4 border-secondary'></div>
          </div>
        </div>
      </div>}
      { dashboardActiveTab ==2 && <div className='bg-white row py-8 pt-0 gy-5 g-xl-4'>
      <div className='col-xl-4' >
        <div className='mt-8 card card-custom card-flush shadow border border-2 '>
          <div
            className='p-4 bg-success rounded position-absolute bottom-75 ms-4'
            style={{width: 'max-content'}}
          >
            <i className='bi fs-2 bi-arrow-repeat text-white fw-bold'></i>
          </div>
          <div className='card-body p-5 mt-2 pb-0 d-flex justify-content-between align-items-center'>
            <p className=' mb-0 fs-4 fw-bold text-muted text-truncate btn btn-link text-hover-primary' onClick={()=>{
               dispatch(setInventoryLogPaginationData({sync_status:"SYNCED", page_num:1 }))
            }} >Total Synced Inventory</p>
            <p className=' mb-0 fs-4 fw-bold text-info cursor-pointer bg-secondary px-4 py-2 bg-secondary rounded' onClick={()=>{
               dispatch(setInventoryLogPaginationData({sync_status:"SYNCED", page_num:1 }))
            }} >{inventoryCount['synced_inventory']}</p>
          </div>
          <div className='separator m-4 border-secondary'></div>
        </div>
      </div>
      <div className='col-xl-4' >
        <div className='mt-8 card card-custom card-flush shadow border border-2 '>
          <div
            className='p-4 bg-warning rounded position-absolute bottom-75 ms-4'
            style={{width: 'max-content'}}
          >
            <i className='bi fs-2 bi-hourglass-top text-white fw-bold'></i>
          </div>
          <div className='card-body p-5 mt-2 pb-0 d-flex justify-content-between align-items-center'>
            <p className=' mb-0 fs-4 fw-bold text-muted btn btn-link text-hover-primary'  onClick={()=>{
               dispatch(setInventoryLogPaginationData({sync_status:"PENDING", page_num:1 }))
            }}>Total Pending Inventory</p>
            <p className=' mb-0 fs-4 fw-bold text-info cursor-pointer bg-secondary px-4 py-2 bg-secondary rounded' onClick={()=>{
               dispatch(setInventoryLogPaginationData({sync_status:"PENDING", page_num:1 }))
            }} >{inventoryCount['pending_inventory']}</p>
          </div>
          <div className='separator m-4 border-secondary'></div>
        </div>
      </div>
      <div className='col-xl-4' >
        <div className='mt-8 card card-custom card-flush shadow border border-2 '>
          <div
            className='p-4 bg-danger rounded position-absolute bottom-75 ms-4'
            style={{width: 'max-content'}}
          >
            <i className='bi fs-2 bi-gear-wide-connected text-white fw-bold'></i>
          </div>
          <div className='card-body p-5 pb-0 mt-2 d-flex justify-content-between align-items-center'>
            <p className=' mb-0 fs-4 fw-bold text-muted btn btn-link text-hover-primary' onClick={()=>{
               dispatch(setInventoryLogPaginationData({sync_status:"FAILED", page_num:1 }))
            }}>Total Failed Inventory</p>
            <p className=' mb-0 fs-4 fw-bold text-info cursor-pointer bg-secondary px-4 py-2 bg-secondary rounded' onClick={()=>{
               dispatch(setInventoryLogPaginationData({sync_status:"FAILED", page_num:1 }))
            }} >{inventoryCount['failed_inventory']}</p>
          </div>
          <div className='separator m-4 border-secondary'></div>
        </div>
      </div>
      </div>}
      { dashboardActiveTab ==3 && <div className='bg-white row py-8 pt-0 gy-5 g-xl-4'>
      <div className='col-xl-4' >
        <div className='mt-8 card card-custom card-flush shadow border border-2 '>
          <div
            className='p-4 bg-success rounded position-absolute bottom-75 ms-4'
            style={{width: 'max-content'}}
          >
            <i className='bi fs-2 bi-arrow-repeat text-white fw-bold'></i>
          </div>
          <div className='card-body p-5 mt-2 pb-0 d-flex justify-content-between align-items-center'>
            <p className=' mb-0 fs-4 fw-bold text-muted text-truncate btn btn-link text-hover-primary' onClick={()=>{
               dispatch(setShipmentlogPaginationData({sync_status:"SYNCED", page_num:1 }))
            }}  >Total Synced Shipments</p>
            <p className=' mb-0 fs-4 fw-bold text-info cursor-pointer bg-secondary px-4 py-2 bg-secondary rounded' onClick={()=>{
               dispatch(setShipmentlogPaginationData({sync_status:"SYNCED", page_num:1 }))
            }}  >{shipmentCount['synced_shipment']}</p>
          </div>
          <div className='separator m-4 border-secondary'></div>
        </div>
      </div>
      <div className='col-xl-4' >
        <div className='mt-8 card card-custom card-flush shadow border border-2 '>
          <div
            className='p-4 bg-warning rounded position-absolute bottom-75 ms-4'
            style={{width: 'max-content'}}
          >
            <i className='bi fs-2 bi-hourglass-top text-white fw-bold'></i>
          </div>
          <div className='card-body p-5 mt-2 pb-0 d-flex justify-content-between align-items-center'>
            <p className=' mb-0 fs-4 fw-bold text-muted btn btn-link text-hover-primary'  onClick={()=>{
               dispatch(setShipmentlogPaginationData({sync_status:"PENDING", page_num:1 }))
            }} >Total Pending Shipments</p>
            <p className=' mb-0 fs-4 fw-bold text-info cursor-pointer bg-secondary px-4 py-2 bg-secondary rounded' onClick={()=>{
               dispatch(setShipmentlogPaginationData({sync_status:"PENDING", page_num:1 }))
            }}  >{shipmentCount['pending_shipment']}</p>
          </div>
          <div className='separator m-4 border-secondary'></div>
        </div>
      </div>
      <div className='col-xl-4' >
        <div className='mt-8 card card-custom card-flush shadow border border-2 '>
          <div
            className='p-4 bg-danger rounded position-absolute bottom-75 ms-4'
            style={{width: 'max-content'}}
          >
            <i className='bi fs-2 bi-gear-wide-connected text-white fw-bold'></i>
          </div>
          <div className='card-body p-5 pb-0 mt-2 d-flex justify-content-between align-items-center'>
            <p className=' mb-0 fs-4 fw-bold text-muted btn btn-link text-hover-primary'  onClick={()=>{
               dispatch(setShipmentlogPaginationData({sync_status:"FAILED", page_num:1 }))
            }}  >Total Failed Shipments</p>
            <p className=' mb-0 fs-4 fw-bold text-info cursor-pointer bg-secondary px-4 py-2 bg-secondary rounded' onClick={()=>{
               dispatch(setShipmentlogPaginationData({sync_status:"FAILED", page_num:1 }))
            }}  >{shipmentCount['failed_shipment']}</p>
          </div>
          <div className='separator m-4 border-secondary'></div>
        </div>
      </div>
      </div>}
      { dashboardActiveTab ==4 && <div className='bg-white row py-8 pt-0 gy-5 g-xl-4'>
      <div className='col-xl-4' >
        <div className='mt-8 card card-custom card-flush shadow border border-2 '>
          <div
            className='p-4 bg-secondary rounded position-absolute bottom-75 ms-4'
            style={{width: 'max-content'}}
          >
            <i className='bi fs-2 bi-info-square text-info fw-bold'></i>
          </div>
          <div className='card-body p-5 mt-2 pb-0 d-flex justify-content-between align-items-center'>
            <p className=' mb-0 fs-4 fw-bold text-muted text-truncate btn btn-link text-hover-primary' onClick={()=>{
               dispatch(setProductLogPaginationData({ platform_type:"" , page_num:1 }))
            }}  >Total Products</p>
            <p className=' mb-0 fs-4 fw-bold text-info cursor-pointer bg-secondary px-4 py-2 bg-secondary rounded' onClick={()=>{
               dispatch(setProductLogPaginationData({ platform_type:"" , page_num:1 }))
            }}  >{productCount['totalProducts']}</p>
          </div>
          <div className='separator m-4 border-secondary'></div>
        </div>
      </div>
      <div className='col-xl-4' >
        <div className='mt-8 card card-custom card-flush shadow border border-2 '>
          <div
            className='p-4 bg-secondary rounded position-absolute bottom-75 ms-4'
            style={{width: 'max-content'}}
          >
            <i className='bi fs-2 bi-info-square text-info fw-bold'></i>
          </div>
          <div className='card-body p-5 mt-2 pb-0 d-flex justify-content-between align-items-center'>
            <p className=' mb-0 fs-4 fw-bold text-muted btn btn-link text-hover-primary'  onClick={()=>{
               dispatch(setProductLogPaginationData({platform_type:"COREFORCE", page_num:1 }))
            }} >Total Coreforce Products</p>
            <p className=' mb-0 fs-4 fw-bold text-info cursor-pointer bg-secondary px-4 py-2 bg-secondary rounded' onClick={()=>{
               dispatch(setProductLogPaginationData({platform_type:"COREFORCE", page_num:1 }))
            }}  >{productCount['coreforceProduct']}</p>
          </div>
          <div className='separator m-4 border-secondary'></div>
        </div>
      </div>
      <div className='col-xl-4' >
        <div className='mt-8 card card-custom card-flush shadow border border-2 '>
          <div
            className='p-4 bg-secondary rounded position-absolute bottom-75 ms-4'
            style={{width: 'max-content'}}
          >
            <i className='bi fs-2 bi-info-square text-info fw-bold'></i>
          </div>
          <div className='card-body p-5 pb-0 mt-2 d-flex justify-content-between align-items-center'>
            <p className=' mb-0 fs-4 fw-bold text-muted btn btn-link text-hover-primary'  onClick={()=>{
               dispatch(setProductLogPaginationData({platform_type:"EBAY", page_num:1 }))
            }}  >Total eBay Products</p>
            <p className=' mb-0 fs-4 fw-bold text-info cursor-pointer bg-secondary px-4 py-2 bg-secondary rounded' onClick={()=>{
               dispatch(setProductLogPaginationData({platform_type:"EBAY", page_num:1 }))
            }}  >{productCount['ebayProducts']}</p>
          </div>
          <div className='separator m-4 border-secondary'></div>
        </div>
      </div>
      </div>}

      <div className='p-6 border border-2 shadow rounded '>
        <Tabs
          defaultActiveKey={dashboardActiveTab}
          id='order-and-inventory'
          onSelect={(activeKey) => {
            if (activeKey) {
              dispatch(setDashboardActiveTab({tab: Number(activeKey)}))
            }
          }}
          className='mb-3 border-bottom '
        >
          <Tab
            eventKey={1}
            title='Order Log'
            className=''
            tabClassName={dashboardActiveTab==1 ? activeTab : inactiveTab }
            onSelect={() => {
              dispatch(setDashboardActiveTab({tab: 1}))
            }}
          >
            <Orders />
          </Tab>
          <Tab
            eventKey={2}
            title='Inventory Log'
            tabClassName={dashboardActiveTab==2 ? activeTab : inactiveTab }
            onSelect={() => {
              dispatch(setDashboardActiveTab({tab: 2}))
            }}
          >
            <Inventories />
          </Tab>
          <Tab
            eventKey={3}
            title='Shipment Log'
            tabClassName={dashboardActiveTab==3 ? activeTab : inactiveTab }
            onSelect={() => {
              dispatch(setDashboardActiveTab({tab: 3}))
            }}
          >
            <Shipments />
          </Tab>
          <Tab
            eventKey={4}
            title='Product Log'
            tabClassName={dashboardActiveTab==4 ? activeTab : inactiveTab }
            onSelect={() => {
              dispatch(setDashboardActiveTab({tab: 4}))
            }}
          >
            <ProductLogs />
          </Tab>
        </Tabs>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
