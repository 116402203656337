/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate, HashRouter} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {useAppDispatch, useAppSelector} from 'src/redux/sotre.hooks'
import {localStorageTokenName, loggedInAdminAccessToken, refreshTokenCount, showOnceErrorCount} from 'src/utils/constants'
import {setInUserView, setLoggingData} from 'src/redux/slices/authSlice'
import { getUserInfoAsync } from 'src/redux/actions/authActions'
import Loading from 'src/utils/Loading'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const dispatch = useAppDispatch()
  const {isLoggedIn, updateProfileDataLoader } = useAppSelector((state) => state.auth)

  useEffect(() => {
    const token = localStorage.getItem(localStorageTokenName)
    const adminToken = localStorage.getItem(loggedInAdminAccessToken)


    if(adminToken){
      dispatch(setInUserView(true))
      // window.location.href = window.location.origin + "/#/user-list"
    }
    //this is setting coz refresh token api only hit at once so we avoid error message
    localStorage.setItem(refreshTokenCount,'0')
    localStorage.setItem(showOnceErrorCount,'0')
    if (token) {
      dispatch(getUserInfoAsync())
      .then((infoResponse)=>{
        if(infoResponse.payload?.email){
          dispatch(setLoggingData({isLoggedIn: true,}))
        }
      })
    }
    return () => {
      dispatch(setLoggingData({isLoggedIn: false }))
      dispatch(setInUserView(false))
    }
  }, [])

  if(updateProfileDataLoader) return <Loading/>

  return (
    <HashRouter basename={'/'}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {isLoggedIn ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </HashRouter>
  )
}

export {AppRoutes}
