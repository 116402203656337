import { addDays } from 'date-fns';
import moment from 'moment';
import React, { FC, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';

type SelectionRange = {
  startDate: string,
  endDate: string,
}

type CustomDateRangePickerPropsType = {
  isVisible :boolean
  handleClose : ()=>void
  onPickRange:(range:SelectionRange)=>void
}

const CustomDateRangePicker:FC<CustomDateRangePickerPropsType> = ({ isVisible, handleClose, onPickRange }) => {
 
  const [currentRange, setCurrentRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

          
    if(!isVisible) return null

    return (
      <Modal show={isVisible} 
      // onHide={()=>{
      //   handleClose()
      //   setCurrentRange([
      //     {
      //       startDate: new Date(),
      //       endDate: new Date(),
      //       key: 'selection'
      //     }
      //   ])
      // }} 
      centered   >
          <DateRangePicker
          ranges={currentRange}
          onChange={(ev)=>{
            setCurrentRange([ev.selection])
            // onPickRange({startDate: new Date(ev.selection.startDate).toISOString(), endDate: new Date(ev.selection.endDate).toISOString()})
            // handleClose()
          }}
          className='shadow'
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          maxDate={new Date()}
          />
          <div className='d-flex justify-content-end p-2 bg-white' >
            <button className='btn btn-sm btn-primary' onClick={()=>{
              onPickRange({startDate: moment(currentRange[0].startDate).utc(true).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"), endDate: moment(currentRange[0].endDate).utc(true).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") })
              handleClose()
            }} >Apply</button>
          </div>
      </Modal>
      )
}

export default CustomDateRangePicker