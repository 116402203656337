import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'

export const getDashboardStatCounts = createAsyncThunk(
  'misc/getDashboardStatCounts',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', '/dashboard-count-data', [], toolkit, params)
  }
)

export const getDashboardStatCountsForShipment = createAsyncThunk(
  'misc/getDashboardStatCountsForShipment',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', '/dashboard-count-data-shipment', [], toolkit, params)
  }
)

export const getDashboardStatCountsForInventory = createAsyncThunk(
  'misc/getDashboardStatCountsForInventory',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', '/dashboard-count-data-inventory', [], toolkit, params)
  }
)
