import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'

export const getShipmentListAsync = createAsyncThunk(
  'connection/getShipmentListAsync',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', '/get-shipment-logs', [], toolkit, params)
  }
)

