import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import { getDashboardStatCounts, getDashboardStatCountsForInventory, getDashboardStatCountsForShipment } from '../actions/commonActions'

type INITIAL_STATE = {
  isTransparentLoaderActive: boolean
  dashboardCountLoader: boolean
  internalServerError:boolean
  dashboardActiveTab:number
  counts: {
    "Total_orders":number,
    failed_orders:number,
    pending_orders:number,
    synced_orders:number
  }
  shipmentCount: {
    total_shipments:number,
    failed_shipment:number,
    pending_shipment:number,
    synced_shipment:number
  }
  inventoryCount: {
    total_inventory:number,
    failed_inventory:number,
    pending_inventory:number,
    synced_inventory:number
  }
}

const initialState: INITIAL_STATE = {
  isTransparentLoaderActive: false,
  internalServerError:false,
  dashboardCountLoader: false,
  dashboardActiveTab:1,
 counts:{
  "Total_orders":0,
  failed_orders:0,
  pending_orders:0,
  synced_orders:0
 },
 shipmentCount: {
  total_shipments:0,
  failed_shipment:0,
  pending_shipment:0,
  synced_shipment:0
},
inventoryCount: {
  total_inventory:0,
  failed_inventory:0,
  pending_inventory:0,
  synced_inventory:0
}
}

const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  extraReducers: (builder) => {

    // get order count start
    builder.addMatcher(isAnyOf(getDashboardStatCounts.pending), (state) => {
      state.dashboardCountLoader = true
      console.log('getDashboardStatCounts is in pending state ')
    })
    builder.addMatcher(isAnyOf(getDashboardStatCounts.rejected), (state) => {
      state.dashboardCountLoader = false
      console.log('getDashboardStatCounts is in rejected state ')
    })
    builder.addMatcher(isAnyOf(getDashboardStatCounts.fulfilled), (state, action) => {
      state.dashboardCountLoader = false
      console.log('getDashboardStatCounts is in fulfilled state ', action.payload)
      state.counts = action.payload
    })
    //get order count end

    // get shipment count start
    builder.addMatcher(isAnyOf(getDashboardStatCountsForShipment.pending), (state) => {
      state.dashboardCountLoader = true
      console.log('getDashboardStatCountsForShipment is in pending state ')
    })
    builder.addMatcher(isAnyOf(getDashboardStatCountsForShipment.rejected), (state) => {
      state.dashboardCountLoader = false
      console.log('getDashboardStatCountsForShipment is in rejected state ')
    })
    builder.addMatcher(isAnyOf(getDashboardStatCountsForShipment.fulfilled), (state, action) => {
      state.dashboardCountLoader = false
      console.log('getDashboardStatCountsForShipment is in fulfilled state ', action.payload)
      state.shipmentCount = action.payload
    })
    //get shipment count end

    // get shipment count start
    builder.addMatcher(isAnyOf(getDashboardStatCountsForInventory.pending), (state) => {
      state.dashboardCountLoader = true
      console.log('getDashboardStatCountsForInventory is in pending state ')
    })
    builder.addMatcher(isAnyOf(getDashboardStatCountsForInventory.rejected), (state) => {
      state.dashboardCountLoader = false
      console.log('getDashboardStatCountsForInventory is in rejected state ')
    })
    builder.addMatcher(isAnyOf(getDashboardStatCountsForInventory.fulfilled), (state, action) => {
      state.dashboardCountLoader = false
      console.log('getDashboardStatCountsForInventory is in fulfilled state ', action.payload)
      state.inventoryCount = action.payload
    })
    //get shipment count end


  },
  reducers: {
    showTransparentLoader: (state) => {
      state.isTransparentLoaderActive = true
    },

    hideTransparentLoader: (state) => {
      state.isTransparentLoaderActive = false
    },

    setInternalServerError : (state, { payload } : { payload:{isError:boolean} } ) => {
      state.internalServerError = payload.isError
    },

    setDashboardActiveTab : (state, {payload  } : { payload:{tab:number} }  )=>{
        state.dashboardActiveTab = payload.tab
    }
  },
})

export const {showTransparentLoader, hideTransparentLoader, setInternalServerError, setDashboardActiveTab } = commonSlice.actions

export default commonSlice.reducer
