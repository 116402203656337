import React, { FC } from 'react'

const InitialSyncMessage:FC<{message:string}> = ({message}) => {
  return (
    <div>
      <div className='alert alert-primary d-flex align-items-center p-5 mb-10'>
        <span className='svg-icon svg-icon-2hx svg-icon-primary me-3'>
        <i className="bi bi-info-circle fs-1 text-primary"></i>
        </span>

        <div className='d-flex flex-column'>
          <h5 className='mb-1 text-primary'>{message}</h5>
          
        </div>
      </div>
    </div>
  )
}

export default InitialSyncMessage
