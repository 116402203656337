import React, {FC, useEffect, useState} from 'react'
import DataTable from 'react-data-table-component'
import useAppToast from 'src/hooks/useAppToast'
import {getOrdersListAsync, resyncOrderItem} from 'src/redux/actions/orderlogActions'
import {useAppDispatch, useAppSelector} from 'src/redux/sotre.hooks'
import StatusComp from '../modules/orders/StatusComp'
import CustomDateRangePicker from 'src/utils/CustomDateRangePicker'
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import { SYNC_STATUS_LIST, TABLE_ROWS } from 'src/utils/constants'
import moment from 'moment'
import TransparentLoader from 'src/utils/TransparentLoader'
import { getShipmentListAsync } from 'src/redux/actions/shipmentlogActions'
import { getDashboardStatCounts, getDashboardStatCountsForShipment } from 'src/redux/actions/commonActions'
import { getProductLogsAsync, getProductsCountAsync } from 'src/redux/actions/productLogActions'
import { setProductLogPaginationData } from 'src/redux/slices/productlogSlice'

const resyncTooltip = (props) => (
  <Tooltip id="button-tooltip" className='fw-bold custom-tooltip'  {...props}>
      Resync
  </Tooltip>
);

const ResyncButton: FC<{
  rowData
  onResync: () => void
}> = ({onResync, rowData}) => {
  const {isDisconnecting} = useAppSelector((state) => state.accounts)
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      // show={true}
      overlay={(props)=> resyncTooltip(props,)}
    >
    <button
      disabled={isDisconnecting}
      className='btn btn-sm btn-info p-2'
      onClick={() => onResync()}
    >
      <i className='bi bi-arrow-repeat pe-0'></i>
    </button>
    </OverlayTrigger>
  )
}

const ProductLogs: FC = () => {
  const dispatch = useAppDispatch()
  const { dashboardActiveTab} = useAppSelector((state) => state.common)
  const { paginationData,productLogLoading,productLogs,totalProductLogCount  } = useAppSelector((state) => state.productLog)
  const [opendaterangepicker, setOpendaterangepicker] = useState(false)
  const {successToast, errorToast} = useAppToast()
  const [resetFilters, setResetFilters] = useState(false)



  const columns = [
    
    {
      name: 'Product Number #',
      selector: (row) => row.product_id,
    //   sortable: true,
      sortField: 'product_id',
      width:"220px"
    },
    {
      name: 'Platform',
      selector: (row) => row.platform_type,
    //   sortable: true,
      sortField: 'platform_type',
      width:"200px"
       
    },
     
    {
      name: 'UPC Code',
      selector:(row)=>row.upc_code,
    //   sortable: true,
      sortField: 'upc_code',
      width:"240px"
       
    },
    {
      name: 'SKU',
      selector: (row) => row.sku,
    //   sortable: true,
      sortField: 'sku',
      width:"200px"

    },
    {
      name: 'Created At',
      selector: (row) => row.created_at,
    //   sortable: true,
      sortField: 'created_at',
      width:"220px",
      cell: (row, index, column, id) => {
        return <p>{ row?.created_at ? moment.utc(row.created_at).format("DD-MMM-YY, hh:mm A [(UTC)]") : '' }</p>
      },
    },   
    // {
    //   name: 'Date of Shipment',
    //   selector: (row) => row.date_of_shipment,
    //   sortable: true,
    //   sortField: 'date_of_shipment',
    //   width:"220px",
    //   cell: (row, index, column, id) => {
    //     return <p>{ row?.date_of_shipment ? moment.utc(row.date_of_shipment).format("DD-MMM-YY, hh:mm A") : '' }</p>
    //   },
    // },   
  ]

  const handleSort = (ev, direction )=>{
    console.log('ev', ev)
    // dispatch(setProductLogPaginationData({sort_by: ev.sortField, sort_direction:(direction+"").toString().toUpperCase() }))
     
  }
 

  useEffect(() => {
    if(dashboardActiveTab==4){
      dispatch(getProductLogsAsync({...paginationData}))
    }
  }, [paginationData, dashboardActiveTab])

  return (
    <>
      <div>
        {/* table header section start */}
        <div className='navbar my-3 py-2'>
        <div> 
            <form className='form-inline ms-2'>
              <input
                className='form-control mr-sm-2 border border-secondary'
                type='search'
                value={paginationData.search}
                placeholder='Search Product'
                aria-label='Search'
                onChange={(ev) => {
                  dispatch(setProductLogPaginationData({search: ev.target.value, page_num:1 }))
                }}
              />
            </form>
          </div>
          <div className='d-inline-flex'>
              <div className='form-group me-2'>
                {/* <button
                  style={{paddingTop:"8px", paddingBottom:"8px"}}
                  className='form-control border border-secondary'
                  onClick={() => {
                    setOpendaterangepicker(!opendaterangepicker)
                  }}
                >
                  {paginationData.from_date || paginationData.to_date
                    ? moment(paginationData.from_date).format('DD/MMM/YY') +
                      ' - ' +
                      moment(paginationData.to_date).format('DD/MMM/YY')
                    : 'Pick Date Range'}
                </button> */}
                {/* <CustomDateRangePicker
                  isVisible={opendaterangepicker}
                  handleClose={() => setOpendaterangepicker(false)}
                  onPickRange={(range) => {
                    dispatch(setProductLogPaginationData({from_date: range.startDate, to_date: range.endDate}))
                  }}
                /> */}
              </div>
              <div className='form-group me-2'>
                <select
                  style={{paddingTop:"8px", paddingBottom:"8px"}}
                  className='form-select border border-secondary'
                  id='exampleFormControlSelect1'
                  value={paginationData.platform_type}
                  onChange={(ev) => {
                    dispatch(setProductLogPaginationData({ platform_type : ev.target.value}))
                  }}
                >
                  <option value=''>ALL</option>
                  {["COREFORCE","EBAY"].map((item) => {
                    return <option value={item}>{item}</option>
                  })}
                </select>
              </div> 
            <button
             className='btn btn-sm btn-info ms-2'
              onClick={() =>{
                dispatch(setProductLogPaginationData({
                  page_num: 1,
                  page_limit: 10,
                  search: '',
                  platform_type:""
                  // from_date: '',
                //   to_date: '',
                //   sync_status: '',
                //   sort_direction: '',
                //   sort_by: '', // DES for descending
                }))
                setResetFilters(!resetFilters)
              }}
            >
              Reset Filters
            </button>
          </div>
        </div>
        {/* table header section end */}

        <div className='border'>
          <DataTable
            key={'shipmenttable'+resetFilters}
            responsive
            columns={columns}
            data={productLogs}
            progressPending={productLogLoading}
            paginationRowsPerPageOptions={TABLE_ROWS}
            paginationTotalRows={totalProductLogCount}
            onSort={handleSort}
            pagination
            paginationServer
            className='align-items-center'
            highlightOnHover
            sortServer
            onChangePage={(pageNum, totalRows) => {
              dispatch(setProductLogPaginationData({page_num: pageNum}))

              // setPaginationData((pre) => {
              //   return {...pre, page_num: pageNum}
              // })
            }}
            onChangeRowsPerPage={(currentPage)=>{
              dispatch(setProductLogPaginationData({page_limit: currentPage}))

              // setPaginationData((pre) => {
              //   return {...pre, page_limit: currentPage }
              // })
            }}
           
            customStyles={{
              header: {
                style: {fontSize: 16, fontWeight: 'bold', borderBottom: '5px solid black'},
              },
              headCells: {
                style: {justifyContent: 'center', textAlign: 'center', fontSize: 16},
              },
              cells: {
                style: {alignItems: 'center', textAlign: 'center', justifyContent: 'center'},
              },
            }}
          />
        </div>
      </div>
    </>
  )
}

export default ProductLogs
