import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'

export const getConfigurationDataAsync = createAsyncThunk(
  'connection/getConfigurationDataAsync',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', '/get-configuration-data', [], toolkit, params)
  }
)

export const setConfigurationDataAsync = createAsyncThunk(
  'connection/setConfigurationDataAsync',
  async function (payload: FormData, toolkit) {
    return await AxiosClient('POST', '/set-configuration-data', payload, toolkit, [])
  }
)

export const syncConfigurationDataAsync = createAsyncThunk(
  'connection/syncConfigurationDataAsync',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', '/sync-configuration-data', [], toolkit, params)
  }
)

export const getConfigurationSettings = createAsyncThunk(
  'connection/getConfigurationSettings',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', '/platform-configurations', [], toolkit, params)
  }
)
