import React, { FC } from 'react'

const BSIcon:FC<{iconClass:string}> = ({iconClass}) => {
  return (
    <div>
        <i className={iconClass} ></i>
    </div>
  )
}

export default BSIcon