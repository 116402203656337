import { APP_ENV } from "./env.config";

let currentEnv = "development"
if(process.env.NODE_ENV=='development'){
  currentEnv = "development"
}else{
    if(process.env.REACT_APP_ENV=="staging"){
        currentEnv = "staging"
    }
    if(process.env.REACT_APP_ENV=="production"){
        currentEnv = "production"
    }
}

export const appEnv = APP_ENV[currentEnv]