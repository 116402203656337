import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import { getProductLogsAsync, getProductsCountAsync } from '../actions/productLogActions'

type PRODUCT_LOG_ITEM = {
  id: number
  order_id: string
  sync_status: string
  source: string
  synced_at: null|string
  message: null|string
  destination_ref_id:string
  updated_at ?: string
  ordered_at?:string
  order_link?:string
}

type PAGINATION_DATA_FOR_SET = {
  page_num?: number,
  page_limit?: number,
  from_date?: string
  search?: string
  platform_type?:string
  // to_date?: string
  // sync_status?: string
  // sort_direction?: string // DES for descending
  // sort_by?:string
  }

type PAGINATION_DATA = {
  page_num: number,
  page_limit: number,
  search: string
  platform_type:string
  // from_date: string
  // to_date: string
  // sync_status: string
  // sort_direction: string // DES for descending
  // sort_by:string  
}

type INITIAL_STATE = {
  productLogLoading: boolean
  productLogs: PRODUCT_LOG_ITEM[] | []
  totalProductLogCount: number
  paginationData : PAGINATION_DATA
  productCountLoader:boolean
  productCount:{
    totalProducts:number
    coreforceProduct:number
    ebayProducts:number
  }

}

const initialState:INITIAL_STATE = {
  productLogLoading: false,
  productLogs: [],
  totalProductLogCount: 0,
  productCountLoader:false,
  paginationData :{
    page_num: 1,
    page_limit: 10,
    search: '',
    platform_type:""
    // from_date: '',
    // to_date: '',
    // sync_status: '',
    // sort_direction: 'DESC', // DES for descending
    // sort_by:'',
  },
  productCount:{
    totalProducts:0,
    coreforceProduct:0,
    ebayProducts:0
  }
}

const productLogSlice = createSlice({
  name: 'productLogSlice',
  initialState,
  extraReducers: (builder) => {
    // get order list start
    builder.addMatcher(isAnyOf(getProductLogsAsync.pending), (state) => {
      state.productLogLoading = true
      console.log('getProductLogsAsync is in pending state ')
    })
    builder.addMatcher(isAnyOf(getProductLogsAsync.rejected), (state) => {
      state.productLogLoading = false
      console.log('getProductLogsAsync is in rejected state ')
    })
    builder.addMatcher(isAnyOf(getProductLogsAsync.fulfilled), (state, action) => {
      state.productLogLoading = false
      state.totalProductLogCount = action.payload?.total
      state.productLogs = action.payload?.data
      console.log('getProductLogsAsync is in fulfilled state ', action.payload)
    })
    //get order list end

    // log count section
    builder.addMatcher(isAnyOf(getProductsCountAsync.pending ), (state) => {
      state.productCountLoader = true
      console.log('getProductsCountAsync is in pending state ')
    })
    
    builder.addMatcher(isAnyOf(getProductsCountAsync.rejected), (state) => {
      state.productCountLoader = false
      console.log('getProductsCountAsync is in rejected state ')
    })
    builder.addMatcher(isAnyOf(getProductsCountAsync.fulfilled), (state, action) => {
      state.productCount.totalProducts = action.payload?.total_products
      state.productCount.coreforceProduct = action.payload?.coreforce_product_count
      state.productCount.ebayProducts = action.payload?.ebay_product_count
      state.productCountLoader = false  
      console.log('getProductsCountAsync in fulfilled state---', action.payload);
      
    })

    
  },
  reducers: {
    setProductLogPaginationData : (state, action : { payload : PAGINATION_DATA_FOR_SET, type: string   }  )=>{
      state.paginationData = {...state.paginationData, ...action.payload }
    }
  },
})

export const { setProductLogPaginationData } = productLogSlice.actions

export default productLogSlice.reducer
