import React, { FC } from 'react'
import { Modal, Spinner } from 'react-bootstrap'

type TransparentLoaderPropType = {
  isVisible:boolean
}

const TransparentLoader:FC<TransparentLoaderPropType> = ({isVisible}) => {
  return (
    <Modal fullscreen show={isVisible} backdrop contentClassName='bg-transparent' style={{backgroundColor:"transparent"}} >
      <Modal.Body style={{backgroundColor:"#fff1"}} className='justify-content-center align-items-center d-flex' >
        <div className='align-items-center d-flex flex-column' >
        <Spinner animation='border' >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p className=' fw-bold fs-3 mt-2' >Processing...</p>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default TransparentLoader