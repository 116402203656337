import React from 'react'
import {Link} from 'react-router-dom'

const AddConfigurationNotification = () => {
  return (
    <div>
      <div className='alert alert-success d-flex align-items-center p-5 mb-10'>
        <span className='svg-icon svg-icon-2hx svg-icon-primary me-3'>
          <i className='bi bi-info-circle fs-1'></i>
        </span>

        <div className='d-flex flex-column ms-2'>
          <h5 className='mb-1'>
            Your <strong className='text-info'>Corefore</strong> and{' '}
            <strong className='text-info'>eBay</strong> accounts are connected successfully
          </h5>
          <span className='mt-2' >
            <strong className='text-dark fs-4'>Next step:</strong> <em> Configure your{' '}
            <strong className='text-info'>Coreforce</strong> and{' '}
            <strong className='text-info'>eBay</strong> accounts, including any required data
            mappings, to complete the integration setup.</em>
          </span>
          <Link
            style={{width: 'max-content'}}
            className='text-white btn btn-info btn-sm fw-bold mt-4'
            to={'/configuration'}
          >
            Click here to add configuration setting <i className="bi bi-box-arrow-up-right ms-2 fs-3 mb-0"></i>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AddConfigurationNotification
