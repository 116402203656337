import React, {FC, useEffect, useState} from 'react'
import DataTable from 'react-data-table-component'
import useAppToast from 'src/hooks/useAppToast'
import {resyncOrderItem} from 'src/redux/actions/orderlogActions'
import {useAppDispatch, useAppSelector} from 'src/redux/sotre.hooks'
import StatusComp from '../modules/orders/StatusComp'
import CustomDateRangePicker from 'src/utils/CustomDateRangePicker'
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {getInventoryListAsync} from 'src/redux/actions/invetoryActions'
import {SYNC_STATUS_LIST, TABLE_ROWS} from 'src/utils/constants'
import moment from 'moment'
import TransparentLoader from 'src/utils/TransparentLoader'
import {getDashboardStatCounts, getDashboardStatCountsForInventory} from 'src/redux/actions/commonActions'
import {setInventoryLogPaginationData} from 'src/redux/slices/inventorySlice'

const ResyncButton: FC<{
  rowData
  onResync: () => void
}> = ({onResync, rowData}) => {
  const {isDisconnecting} = useAppSelector((state) => state.accounts)
  const [syncing, setSyncing] = useState(false)
  return (
    <button
      disabled={isDisconnecting}
      className='btn btn-sm btn-info p-2'
      onClick={() => onResync()}
    >
      <i className='bi bi-arrow-repeat pe-0'></i>
    </button>
  )
}

const commonTooltip = (props) => (
  <Tooltip id="button-tooltip" className='fw-bold custom-tooltip'  {...props}>
      {props.msg}
  </Tooltip>
);

const Inventories: FC = () => {
  const dispatch = useAppDispatch()
  // const [paginationData, setPaginationData] = useState({
  //   page_num: 1,
  //   page_limit: 10,
  //   from_date: '',
  //   to_date: '',
  //   q: '',
  //   sync_status: '',
  //   sort_direction: 'ASC',
  //   sort_by: '', // DES for descending
  // })
  const {inventories, loadingInventory, totalInventories, paginationData} = useAppSelector(
    (state) => state.inventory
  )
  const {resyncLoader} = useAppSelector((state) => state.orderlog)
  const {dashboardActiveTab} = useAppSelector((state) => state.common)

  const [opendaterangepicker, setOpendaterangepicker] = useState(false)
  const {successToast, errorToast} = useAppToast()
  const [resetFilters, setResetFilters] = useState(false)


  const resyncInventoryItem = (itemData) => {
    dispatch(
      resyncOrderItem({
        record_id: itemData?.product_id + '',
        pid :itemData?.id,
        platform: 'INVENTORY',
      })
    )
      .then((response) => {
        console.log('response in resync', response)
        if (response?.payload?.status == 200) {
          successToast(response.payload?.data?.message)
          dispatch(getInventoryListAsync({...paginationData}))
          dispatch(getDashboardStatCountsForInventory(''))

          return
        } else {
          errorToast(response.payload?.data?.message)
          dispatch(getInventoryListAsync({...paginationData}))
          dispatch(getDashboardStatCountsForInventory(''))

          return
        }
      })
      .catch((error) => {
        console.log('error in resync', error)
      })
  }

  const columns = [
    {
      name: 'Product Id',
      selector: (row) => row.product_id,
      sortable: true,
      sortField: 'product_id',
      width: '140px',
      // style:{ paddingStart:"50px", justifyContent:"flex-start" }
      cell: (row) => (
        <OverlayTrigger
        placement="top"
        overlay={(props)=> commonTooltip({...props, msg:row.inventory_link })}
      >
        <a className='link text-truncate fw-bold' target='_blank' href={row.inventory_link}>{row.product_id}</a>
      </OverlayTrigger>
      ),
    },
    {
      name: 'Source',
      selector: (row) => row.source,
      // sortable: true,
      sortField: 'source',
    },
    {
      name: 'UPC Code',
      selector: (row) => row.upc_code,
      // sortable: true,
      sortField: 'upc_code',
      width: '160px',
    },
    {
      name: 'SKU',
      selector: (row) => row.sku,
      // sortable: true,
      // sortField: 'upc_code',
    },
    {
      name: 'Synced / Updated At',
      selector: (row) => row.synced_at,
      sortable: true,
      sortField: 'synced_at',
      width: '220px',
      center: true,
      cell: (row, index, column, id) => {
        return (
          <p>
            {row?.synced_at ? moment.utc(row.synced_at).format('DD-MMM-YY, hh:mm A [(UTC)]') : ''}
          </p>
        )
      },
    },
    {
      name: 'Inventory Pulled At',
      sortable: true,
      sortField: 'pulled_at',
      width: '220px',
      center: true,
      cell: (row, index, column, id) => {
        return (
          <p>
            {row?.pulled_at ? moment.utc(row?.pulled_at).format('DD-MMM-YY, hh:mm A [(UTC)]') : ''}
          </p>
        )
      },
    },
    {
      name: 'Status',
      selector: (row) => row.sync_status,
      sortable: true,
      sortField: 'sync_status',
      // width: '160px',
      cell: (row, index, column, id) => {
        return <StatusComp status={row.sync_status} message={row?.message} />
      },
    },
    {
      name: 'Action',
      // selector: (row) => row.disconnect_url,
      // sortable: true,
      sortField: 'action',
      cell: (row, index, column, id) => {
        return row.sync_status == 'FAILED' ? (
          <ResyncButton rowData={row} onResync={() => resyncInventoryItem(row)} />
        ) : null
      },
    },
  ]

  useEffect(() => {
    if (dashboardActiveTab == 2) {
      dispatch(getDashboardStatCountsForInventory({...paginationData}))
      dispatch(getInventoryListAsync({...paginationData}))
    }
  }, [paginationData, dashboardActiveTab])

  const handleSort = (ev, direction) => {
    console.log('ev', ev)
    // setPaginationData((pre) => {
    //   return {...pre, sort_by: ev.sortField}
    // })
    dispatch(
      setInventoryLogPaginationData({
        sort_by: ev.sortField,
        sort_direction: (direction + '').toString().toUpperCase(),
      })
    )
  }

  return (
    <>
      <div>
        {/* table header section start */}
        <div className='navbar my-3 py-2'>
          <div>
            <form className='form-inline ms-2'>
              <input
                className='form-control mr-sm-2 border border-secondary'
                type='search'
                value={paginationData.q}
                placeholder='Search Inventory'
                aria-label='Search'
                onChange={(ev) => {
                  dispatch(setInventoryLogPaginationData({q: ev.target.value, page_num: 1}))
                }}
              />
            </form>
          </div>
          <div className='d-inline-flex'>
            <div className='form-group me-2'>
              <button
                style={{paddingTop: '8px', paddingBottom: '8px'}}
                className='form-control border border-secondary'
                onClick={() => {
                  setOpendaterangepicker(!opendaterangepicker)
                }}
              >
                {paginationData.from_date || paginationData.to_date
                  ? moment(paginationData.from_date).format('DD/MMM/YY') +
                    ' - ' +
                    moment(paginationData.to_date).format('DD/MMM/YY')
                  : 'Pick Date Range'}
              </button>
              <CustomDateRangePicker
                isVisible={opendaterangepicker}
                handleClose={() => setOpendaterangepicker(false)}
                onPickRange={(range) => {
                  dispatch(
                    setInventoryLogPaginationData({
                      from_date: range.startDate,
                      to_date: range.endDate,
                    })
                  )
                }}
              />
            </div>
            <div className='form-group me-2'>
              <select
                style={{paddingTop: '8px', paddingBottom: '8px'}}
                className='form-select border border-secondary'
                id='exampleFormControlSelect1'
                value={paginationData.sync_status}
                onChange={(ev) => {
                  dispatch(setInventoryLogPaginationData({sync_status: ev.target.value}))
                }}
              >
                <option value=''>ALL</option>
                {SYNC_STATUS_LIST.map((item) => {
                  return <option value={item}>{item}</option>
                })}
              </select>
            </div>
            <button
              className='btn btn-sm btn-info ms-2'
              onClick={() => {
                dispatch(
                  setInventoryLogPaginationData({
                    page_num: 1,
                    page_limit: 10,
                    from_date: '',
                    to_date: '',
                    q: '',
                    sync_status: '',
                    sort_direction: '',
                    sort_by: '', // DES for descending
                  })
                )
                setResetFilters(!resetFilters)
              }}
            >
              Reset Filters
            </button>
          </div>
        </div>
        {/* table header section end */}

        <div className='border'>
          <DataTable
            key={"inventories"+resetFilters}
            responsive
            columns={columns}
            data={inventories}
            progressPending={loadingInventory}
            paginationRowsPerPageOptions={TABLE_ROWS}
            paginationTotalRows={totalInventories}
            onSort={handleSort}
            paginationServer
            // onSort={handleSort}
            onChangePage={(pageNum, totalRows) => {
              // setPaginationData((pre) => {
              //   return {...pre, page_num: pageNum}
              // })
              dispatch(setInventoryLogPaginationData({page_num: pageNum}))
            }}
            onChangeRowsPerPage={(rowOption) => {
              // setPaginationData((pre) => {
              //   return {...pre, page_limit: rowOption}
              // })
              dispatch(setInventoryLogPaginationData({page_limit: rowOption}))
            }}
            pagination
            className='align-items-center'
            highlightOnHover
            // sortServer
            customStyles={{
              header: {
                style: {fontSize: 16, fontWeight: 'bold', borderBottom: '5px solid black'},
              },
              headCells: {
                style: {justifyContent: 'center', textAlign: 'center', fontSize: 16},
              },
              cells: {
                style: {alignItems: 'center', textAlign: 'center', justifyContent: 'center'},
              },
            }}
          />
        </div>
      </div>
      <TransparentLoader isVisible={resyncLoader} />
    </>
  )
}

export default Inventories
