import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {getOrdersListAsync, resyncOrderItem} from '../actions/orderlogActions'

type ORDER_LOG_ITEM = {
  id: number
  order_id: string
  sync_status: string
  source: string
  synced_at: null|string
  message: null|string
  destination_ref_id:string
  updated_at ?: string
  ordered_at?:string
  order_link?:string
}

type PAGINATION_DATA_FOR_SET = {
  page_num?: number,
  page_limit?: number,
  from_date?: string
  to_date?: string
  q?: string
  sync_status?: string
  sort_direction?: string // DES for descending
  sort_by?:string  }

type PAGINATION_DATA = {
  page_num: number,
  page_limit: number,
  from_date: string
  to_date: string
  q: string
  sync_status: string
  sort_direction: string // DES for descending
  sort_by:string  
}

type INITIAL_STATE = {
  orderlogLoading: boolean
  resyncLoader:boolean
  orderlogs: ORDER_LOG_ITEM[] | []
  totalOrderLogCount: number
  paginationData : PAGINATION_DATA
}

const initialState:INITIAL_STATE = {
  orderlogLoading: false,
  resyncLoader:false,
  orderlogs: [],
  totalOrderLogCount: 0,
  paginationData :{
    page_num: 1,
    page_limit: 10,
    from_date: '',
    to_date: '',
    q: '',
    sync_status: '',
    sort_direction: 'DESC', // DES for descending
    sort_by:'ordered_at'
  }
}

const orderlogSlice = createSlice({
  name: 'orderlogSlice',
  initialState,
  extraReducers: (builder) => {
    // get order list start
    builder.addMatcher(isAnyOf(getOrdersListAsync.pending), (state) => {
      state.orderlogLoading = true
      console.log('getOrdersListAsync is in pending state ')
    })
    builder.addMatcher(isAnyOf(getOrdersListAsync.rejected), (state) => {
      state.orderlogLoading = false
      console.log('getOrdersListAsync is in rejected state ')
    })
    builder.addMatcher(isAnyOf(getOrdersListAsync.fulfilled), (state, action) => {
      state.orderlogLoading = false
      state.totalOrderLogCount = action.payload?.total_records
      state.orderlogs = action.payload?.Response
      console.log('getOrdersListAsync is in fulfilled state ', action.payload)
    })
    //get order list end

    // resync order item start
    builder.addMatcher(isAnyOf(resyncOrderItem.pending), (state) => {
      state.resyncLoader = true
      console.log('resyncOrderItem is in pending state ')
    })
    builder.addMatcher(isAnyOf(resyncOrderItem.rejected), (state) => {
      state.resyncLoader = false
      console.log('resyncOrderItem is in rejected state ')
    })
    builder.addMatcher(isAnyOf(resyncOrderItem.fulfilled), (state, action) => {
      state.resyncLoader = false
      console.log('resyncOrderItem is in fulfilled state ', action.payload)
    })
    //resync order item end
  },
  reducers: {
    setOrderlogPaginationData : (state, action : { payload : PAGINATION_DATA_FOR_SET, type: string   }  )=>{
      state.paginationData = {...state.paginationData, ...action.payload }
    }
  },
})

export const { setOrderlogPaginationData } = orderlogSlice.actions

export default orderlogSlice.reducer
