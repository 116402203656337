import React, {FC, useState} from 'react'
import {Col, Form, Modal} from 'react-bootstrap'
import useAppToast from 'src/hooks/useAppToast'
import {createConnectAsync, getActiveAccountsListAsync} from 'src/redux/actions/connectionActions'
import {useAppDispatch, useAppSelector} from 'src/redux/sotre.hooks'

type CreateConnectionModalPropsType = {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
}

const CreateConnectionModal: FC<CreateConnectionModalPropsType> = ({isVisible, setIsVisible}) => {
  const dispatch = useAppDispatch()
  const [connectionData, setConnectionData] = useState({
    'access-token': '',
    'friendly-name': '',
    'account-type': 'COREFORCE',
    'domain':''
  })

  const [validated, setValidated] = useState(false)
  const {successToast, errorToast} = useAppToast()
  const {isConnecting} = useAppSelector((state) => state.accounts)

  const handleSubmit = (event) => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation()
    } else {
      console.log('connectionData', connectionData)
      const formData = new FormData()
      formData.append('access-token', connectionData['access-token'])
      formData.append('friendly-name', connectionData['friendly-name'])
      formData.append('domain', connectionData['domain'])
      formData.append('account-type', 'COREFORCE')

      dispatch(createConnectAsync(formData))
        .then((response) => {
          console.log('response', response)
          if (response) {
            if (response.payload?.msg == 'Success') {
              successToast(response.payload.message)
              setIsVisible(false)
              dispatch(getActiveAccountsListAsync({}))
            }else if(response?.payload?.status==400){
              errorToast(response.payload?.data?.msg)
            }else{
              errorToast("Something went wrong")
            }

          }
        })
        .catch(()=>{
          errorToast("Something went wrong")
        })
    }

    setValidated(true)
  }

  const onHandleChange = (name, value) => {
    setConnectionData((pre) => {
      return {...pre, [name]: value}
    })
  }

  return (
    <Modal show={isVisible} centered >
      <Modal.Header>Create Connection Modal</Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group as={Col} className='mb-5'>
            <Form.Label>Display Name</Form.Label>
            <Form.Control
              required
              type='text'
              placeholder='Display name'
              value={connectionData['friendly-name']}
              onChange={(ev) => onHandleChange('friendly-name', ev.target.value)}
            />
            <Form.Control.Feedback type='invalid'>
              Display name is required field
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} className='mb-5'>
            <Form.Label>Domain Name</Form.Label>
            <Form.Control
              required
              type='text'
              placeholder='abc.mydoamin.com'
              value={connectionData['domain']}
              onChange={(ev) => onHandleChange('domain', ev.target.value)}
            />
            <Form.Control.Feedback type='invalid'>
              Display name is required field
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} className='mb-5'>
            <Form.Label>API Key</Form.Label>
            <Form.Control
              required
              type='text'
              placeholder='API Key'
              value={connectionData['access-token']}
              onChange={(ev) => onHandleChange('access-token', ev.target.value)}
            />
            <Form.Control.Feedback type='invalid'>API key is required field</Form.Control.Feedback>
          </Form.Group>
          <div className=' d-flex justify-content-end mt-6 '>
            <button disabled={isConnecting} type='submit' className='btn btn-info'>
              Save
            </button>
            <button
              className='ms-2 btn btn-danger'
              disabled={isConnecting}
              onClick={() => {
                setIsVisible(false)
                setValidated(false)
                setConnectionData({
                  'access-token': '',
                  'account-type': 'COREFORCE',
                  'friendly-name': '',
                  'domain':''
                })
              }}
            >
              Close{' '}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default CreateConnectionModal
