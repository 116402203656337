import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {
  changeUserStatusAsync,
  getUserInfoAsync,
  getUserListAsync,
  loginAsUserAsync,
  loginAsync,
  refreshTokenAsync,
  updateUserInfo,
  updateUserPassword,
  verifyEmailForPasswordResetAsync,
} from '../actions/authActions'
import {localStorageTokenName, loggedInAdminAccessToken, loggedInAdminRefreshToken, refreshTokenName} from 'src/utils/constants'

type LOGIN_PAYLOAD = {
  isLoggedIn: boolean
}

export type USER_DETAIL = {
  id?:number
  email: string; 
  first_name: string; 
  last_name: string,
  is_admin_user:boolean
}

type PAGINATION_DATA = {
  page: number,
  page_limit: number,
  search: string
  ordering: string
  is_active:string
}

type PAGINATION_DATA_FOR_SET = {
  page?: number,
  page_limit?: number,
  search?: string
  ordering?: string
  is_active?:string
}

const initialState: {
  refreshingToken: boolean
  updateProfileDataLoader: boolean
  authLoading: boolean
  isLoggedIn: boolean
  resetPasswordLoader: boolean
  userDetail: USER_DETAIL
  userListLoader:boolean
  loginAsUserLoader:boolean
  usersList: USER_DETAIL[] | [],
  usersCount:number
  userListPaginationData : PAGINATION_DATA
  inUserView:boolean
} = {
  refreshingToken: false,
  resetPasswordLoader: false,
  updateProfileDataLoader: false,
  authLoading: false,
  isLoggedIn: false,
  userDetail: {
    first_name: '',
    last_name: '',
    email: '',
    is_admin_user:false
  },
  userListLoader:false,
  loginAsUserLoader:false,
  usersList:[],
  usersCount:0,
  userListPaginationData:{
    page: 1,
    page_limit: 10,
    search: '',
    ordering: '',
    is_active:''
  },
  inUserView:false
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  extraReducers: (builder) => {
    // login start
    builder.addMatcher(isAnyOf(loginAsync.pending), (state) => {
      console.log('loginAsync is in pending state ')
    })
    builder.addMatcher(isAnyOf(loginAsync.rejected), (state) => {
      console.log('loginAsync is in rejected state ')
    })
    builder.addMatcher(isAnyOf(loginAsync.fulfilled), (state, action) => {
      console.log('login res--', action.payload)
      if(action.payload?.access){
        state.isLoggedIn = true
        state.userDetail.is_admin_user = action.payload?.is_admin_user
        state.userDetail.first_name = action.payload?.first_name
        state.userDetail.last_name = action.payload?.last_name
        state.userDetail.email = action.payload?.email
      }
    })
    // login end

    // login start
    builder.addMatcher(isAnyOf(loginAsUserAsync.pending), (state) => {
      console.log('loginAsUserAsync is in pending state ')
      state.loginAsUserLoader = true
    })
    builder.addMatcher(isAnyOf(loginAsUserAsync.rejected), (state) => {
      console.log('loginAsUserAsync is in rejected state ')
      state.loginAsUserLoader = false
    })
    builder.addMatcher(isAnyOf(loginAsUserAsync.fulfilled), (state, action) => {
      console.log('loginAsUserAsync res--', action.payload)
      state.loginAsUserLoader = false
      state.inUserView = true
      // if(action.payload?.access){
      //   state.isLoggedIn = true
      // }
      
    })
    // login end

    // token refresh start
    builder.addMatcher(isAnyOf(refreshTokenAsync.pending), (state) => {
      console.log('refreshTokenAsync is in pending state ')
      state.refreshingToken = true
    })
    builder.addMatcher(isAnyOf(refreshTokenAsync.rejected), (state) => {
      state.refreshingToken = false
      console.log('refreshTokenAsync is in rejected state ')
    })
    builder.addMatcher(isAnyOf(refreshTokenAsync.fulfilled), (state, action) => {
      state.refreshingToken = false
      localStorage.setItem(localStorageTokenName, action.payload.access)
      // localStorage.setItem(refreshTokenName, response.payload.refresh)
      console.log('token refreshed', action.payload)
    })
    // token refresh end

    builder.addMatcher(
      isAnyOf(getUserInfoAsync.pending, updateUserInfo.pending, updateUserPassword.pending),
      (state) => {
        console.log('profile detail is in pending state ')
        state.updateProfileDataLoader = true
      }
    )
    builder.addMatcher(
      isAnyOf(getUserInfoAsync.rejected, updateUserInfo.rejected, updateUserPassword.rejected),
      (state) => {
        console.log('profile detail is in pending state ')
        state.updateProfileDataLoader = false
      }
    )

    // get profile data start
    builder.addMatcher(isAnyOf(getUserInfoAsync.fulfilled), (state, action) => {
      console.log('getUserInfoAsync detail is in pending state ', action.payload)
      state.updateProfileDataLoader = false
      state.userDetail = action.payload
    })
    // get profile data end

    // update profile data
    builder.addMatcher(isAnyOf(updateUserInfo.fulfilled), (state, action) => {
      console.log('updateUserInfo detail is in pending state ', action.payload)
      state.updateProfileDataLoader = false
    })
    // update profile data end

    // reset password section
    builder.addMatcher(isAnyOf(updateUserPassword.fulfilled), (state, action) => {
      console.log('updateUserPassword detail is in pending state ', action.payload)
      state.updateProfileDataLoader = false
    })
    // reset password end

    // verify email for password reset start
    builder.addMatcher(isAnyOf(verifyEmailForPasswordResetAsync.pending), (state) => {
      console.log('verifyEmailForPasswordResetAsync is in pending state ')
      state.resetPasswordLoader = true
    })
    builder.addMatcher(isAnyOf(verifyEmailForPasswordResetAsync.rejected), (state) => {
      state.resetPasswordLoader = false
      console.log('verifyEmailForPasswordResetAsync is in rejected state ')
    })
    builder.addMatcher(isAnyOf(verifyEmailForPasswordResetAsync.fulfilled), (state, action) => {
      state.resetPasswordLoader = false
      console.log('verifyEmailForPasswordResetAsync is in rejected state ', action.payload)
    })
    // verify email for password reset end

     // get user list start
     builder.addMatcher(isAnyOf(getUserListAsync.pending), (state) => {
      console.log('getUserListAsync is in pending state ')
      state.userListLoader = true
    })
    builder.addMatcher(isAnyOf(getUserListAsync.rejected), (state) => {
      console.log('getUserListAsync is in rejected state ')
      state.userListLoader = false
    })
    builder.addMatcher(isAnyOf(getUserListAsync.fulfilled), (state, action) => {
      console.log('getUserListAsync res--', action.payload)
      if (action.payload?.results) {
        state.usersList = action.payload.results
        state.usersCount = action.payload.count
        state.userListLoader = false
      }
    })
    // get user list end

    // update user status start
    builder.addMatcher(isAnyOf(changeUserStatusAsync.pending), (state) => {
      console.log('changeUserStatusAsync is in pending state ')
      state.userListLoader = true
    })
    builder.addMatcher(isAnyOf(changeUserStatusAsync.rejected), (state) => {
      console.log('changeUserStatusAsync is in rejected state ')
      state.userListLoader = false
    })
    builder.addMatcher(isAnyOf(changeUserStatusAsync.fulfilled), (state, action) => {
      console.log('changeUserStatusAsync res--', action.payload)
      state.userListLoader = false
      // if (action.payload?.results) {
      //   state.usersList = action.payload.results
      // }
    })
    // update user status end
  },
  reducers: {
    // use this reducer for login logout
    setLoggingData: (state, action: {payload: LOGIN_PAYLOAD; type: string}) => {
      state.isLoggedIn = action.payload?.isLoggedIn
      //   state.userDetail = action.payload.userData
    },

    logout: (state) => {
      localStorage.removeItem(localStorageTokenName)
      localStorage.removeItem(refreshTokenName)
      localStorage.clear()
      window.location.reload()
      state.isLoggedIn = false
      //   state.userDetail = {refresh_token: '', access_token: ''}
    },

    backToAdminView:() => {
          // to back in admin view set the admin access and refresh token again from the other two vars where we set then on as user login time
          const currentLoggedInAdminAccessToken = localStorage.getItem(loggedInAdminAccessToken)
          const currentLoggedInAdminRefreshToken = localStorage.getItem(loggedInAdminRefreshToken)
          if(currentLoggedInAdminAccessToken){
            localStorage.setItem(localStorageTokenName, currentLoggedInAdminAccessToken!)
            localStorage.setItem(refreshTokenName, currentLoggedInAdminRefreshToken!)
            localStorage.removeItem(loggedInAdminAccessToken)
            localStorage.removeItem(loggedInAdminRefreshToken)
            window.location.reload()
          }else{
            localStorage.clear()
            window.location.reload()
          }
          // window.location.href = window.location.origin + "/#/user-list"        
    },

    changeUserData: (state, action: {payload: {fieldName: string; value: string}}) => {
      state.userDetail[action.payload.fieldName] = action.payload.value
    },

    setUserListPaginationData : (state, action : { payload : PAGINATION_DATA_FOR_SET, type: string   }  )=>{
      state.userListPaginationData = {...state.userListPaginationData, ...action.payload }
    },

    setInUserView: (state, action:{payload:boolean})=>{
        state.inUserView = action.payload
    }
  },
})

export const {setLoggingData, logout, changeUserData, setUserListPaginationData, setInUserView, backToAdminView } = authSlice.actions

export default authSlice.reducer
