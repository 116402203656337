import {FieldArray, Formik} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Tab,
  Tabs,
  Fade,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap'
import Select, {SingleValue} from 'react-select'
import useAppToast from 'src/hooks/useAppToast'
import {
  CAREER_MAPPING_ITEM,
  CONFIG_DATA,
  INITIAL_VALUE_FOR_CREATE_ORDER,
  LOCATION_DATA,
} from 'src/interfaces/configuration.interface'
import {
  getConfigurationDataAsync,
  getConfigurationSettings,
  setConfigurationDataAsync,
  syncConfigurationDataAsync,
} from 'src/redux/actions/configurationActions'
import {useAppDispatch, useAppSelector} from 'src/redux/sotre.hooks'
import BSIcon from 'src/utils/BSIcon'
import Loading from 'src/utils/Loading'
import * as yup from 'yup'
import EmptyPageWithoutACDetails from '../modules/configation/EmptyPageWithoutACDetails'
import {
  getActiveAccountsListAsync,
  isInitialSyncProcessAsync,
} from 'src/redux/actions/connectionActions'
import TransparentLoader from 'src/utils/TransparentLoader'
import {messageTemplate} from 'src/utils/messageTemplate'
import InitialSyncMessage from '../modules/common/components/InitialSyncMessage'
import {configurationTooltipMessage} from 'src/utils/constants'

const fieldNames = {
  type: 'type',
  isOrderConfigurationSync: 'isOrderConfigurationSync',
  isInventoryConfigurationSync: 'isInventoryConfigurationSync',
  isShipmentConfigurationSync: 'isShipmentConfigurationSync',
  coreforce_product_identifier: 'coreforce_product_identifier',
  ebay_product_identifier: 'ebay_product_identifier',
  order_method_id: 'order_method_id',
  order_source: 'order_source',
  datetime: 'datetime',
  status_selected: 'status_selected',
  payment_mapping: 'payment_mapping',
  no_manage_stock_quantity: 'no_manage_stock_quantity',
  coreforce_location_mapping: 'coreforce_location_mapping',
  coreforce_location_multi: 'coreforce_location_multi',
  shipping_product_sku: 'shipping_product_sku',
  bufferQuantity: 'bufferQuantity',
}

const Configuration: FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(1)
  const [selectedStatus, setSelectedStatus] = useState<SingleValue<CONFIG_DATA> | undefined>(
    undefined
  )
  const [locationsSelected, setLocationsSelected] = useState<LOCATION_DATA[] | []>([])
  const activeTabCSS =
    'border border-bottom-2 border-info border-top-0 border-left-0 border-right-0 rounded-0 fw-bold text-info ms-4'
  const inactiveTabCSS =
    ' fw-bold border border-bottom-3 text-muted border-top-0 border-left-0 border-right-0 ms-4'
  const {successToast, errorToast} = useAppToast()
  const dispatch = useAppDispatch()
  const {
    syncLoader,
    loadingConfigurationData,
    core_pay_method_list,
    coreforce_product_identifier_list,
    ebay_pay_method_list,
    ebay_product_identifier_list,
    coreforce_order_method_list,
    status_list,
    configuredSetting,
    coreforce_location_list,
    ebay_shipping_methods,
    unmapped_methods_list,
    // coreforce_shipping_methods,
  } = useAppSelector((state) => state.configuration)

  const selectAllOption = {
    client_id: '',
    contact_id: '',
    description: 'Select All',
    id: '',
    location_code: '',
    location_id: 'SELECT_ALL',
    user: 0,
  }

  const {
    active_accounts,
    loadingAccountDetail,
    isBothAccountConnected,
    initialSyncedStatus,
    initialSyncLoader,
    initialSyncMessage,
  } = useAppSelector((state) => state.accounts)

  const schema = yup.object().shape({
    coreforce_product_identifier: yup.string().required('Field is required'),
    ebay_product_identifier: yup.string().required('Field is required'),
    order_method_id:
      activeIndex == 1
        ? yup.string().when('isOrderConfigurationSync', {
            is: true,
            then: () => yup.string().required(),
          })
        : yup.string().nullable(), // from here need to remove this default order when actual data come
    isOrderConfigurationSync: yup.boolean().default(false),
    isInventoryConfigurationSync: yup.boolean().default(false),
    isShipmentConfigurationSync: yup.boolean().default(false),
    order_source:
      activeIndex == 1
        ? yup.string().when('isOrderConfigurationSync', {
            is: true,
            then: () => yup.string().required('Field is required'),
          })
        : yup.string().nullable(),
    datetime:
      activeIndex == 1
        ? yup.string().when('isOrderConfigurationSync', {
            is: true,
            then: () => yup.string().required('Field is required'),
          })
        : yup.string(),
    status_selected: yup.string(), //array().of(yup.string()),
    payment_mapping:
      activeIndex == 1
        ? yup
            .array()
            .of(
              yup.object().shape({
                ebay_pay_id: yup.string().required(),
                coreforce_pay_id: yup.string().required(),
              })
            )
            .when('isOrderConfigurationSync', {
              is: true,
              then: () =>
                yup
                  .array()
                  .min(1)
                  .of(
                    yup.object().shape({
                      ebay_pay_id: yup.string().required(),
                      coreforce_pay_id: yup.string().required(),
                    })
                  )
                  .required(),
            })
        : yup.array(),
    // no_manage_stock_quantity:
    //   activeIndex == 2
    //     ? yup.string().when('isInventoryConfigurationSync', {
    //         is: 'true',
    //         then: () => yup.string().required('Field is required'),
    //       })
    //     : yup.string(),

    // coreforce_location_mapping:
    //   activeIndex == 2
    //     ? yup
    //         .array()
    //         .of(
    //           yup.object().shape({
    //             location: yup.string().required(),
    //             bufferQty: yup.string().required(), // now this is only value not in percent
    //             // outOfStockQty: yup.string().required(),
    //             // minQty: yup.string().default(null).nullable(),
    //             // maxQty: yup.string().default(null).nullable(),
    //             // isMinOn: yup.boolean().default(false),
    //             // isMaxOn: yup.boolean().default(false),
    //           })
    //         )
    //         .when('isInventoryConfigurationSync', {
    //           is: true,
    //           then: () =>
    //             yup
    //               .array()
    //               .min(1)
    //               .of(
    //                 yup.object().shape({
    //                   location: yup.string().required(),
    //                   bufferQty: yup.string().required(), // now this is only value not in percent
    //                   // outOfStockQty: yup.string().required(),
    //                   // minQty: yup.string().default(null).nullable(),
    //                   // maxQty: yup.string().default(null).nullable(),
    //                   // isMinOn: yup.boolean().default(false),
    //                   // isMaxOn: yup.boolean().default(false),
    //                 })
    //               )
    //               .required(),
    //         })
    //     : yup.array(),
    // coreforce_location_multi:
    //   activeIndex == 2
    //     ? yup.array().when('isInventoryConfigurationSync', {
    //         is: true,
    //         then: () => yup.array().of(yup.string()).required('Field is required'),
    //       })
    //     : yup.array(),
    bufferQuantity:
      activeIndex == 2
        ? yup.string().when('isInventoryConfigurationSync', {
            is: true,
            then: () => yup.string().required(),
          })
        : yup.string(),
    shipping_product_sku:
      activeIndex == 1
        ? yup.string().when('isOrderConfigurationSync', {
            is: true,
            then: () => yup.string().required(),
          })
        : yup.string(),

    //  shipment_location_mapping:
    //   activeIndex == 3
    //     ? yup
    //         .array()
    //         .of(
    //           yup.object().shape({
    //             ebay_shipping_method: yup.string().required(),
    //             coreforce_shipping_method: yup.string().required(),

    //           })
    //         )
    //         .when('isShipmentConfigurationSync', {
    //           is: true,
    //           then: () =>
    //             yup
    //               .array()
    //               .min(1)
    //               .of(
    //                 yup.object().shape({
    //                   ebay_shipping_method: yup.string().required(),
    //                   coreforce_shipping_method: yup.string().required(),
    //                 })
    //               )
    //               .required(),
    //         })
    //     : yup.array(),
    shipment_carrier_mapping : activeIndex===3 ? yup.array().min(1).of(
      yup.object().shape({
        coreforce_carrier_description: yup.string().required(),
        ebay_carrier_method : yup.string().required()
      })
    ) : yup.array() ,
    unmapped_shipment_carrier_mapping: activeIndex===3 ? yup.array().of(
      yup.object().shape({
        coreforce_carrier_description: yup.string().required(),
        ebay_carrier_method : yup.string().required()
      })
    ) : yup.array() 

  })

  const initialValuesForOrderMapping: INITIAL_VALUE_FOR_CREATE_ORDER = {
    type: 'ORDER_CONFIGURATION',
    isOrderConfigurationSync: configuredSetting.allow_order_sync,
    isInventoryConfigurationSync: configuredSetting.allow_inventory_sync,
    isShipmentConfigurationSync: configuredSetting.allow_shipment_sync,
    coreforce_product_identifier: configuredSetting.coreforce_product_identifier
      ? configuredSetting.coreforce_product_identifier
      : coreforce_product_identifier_list[0]?.method_id,
    ebay_product_identifier: configuredSetting.ebay_product_identifier
      ? configuredSetting.ebay_product_identifier
      : ebay_product_identifier_list[0]?.method_id,
    order_method_id: configuredSetting.order_method_id ?? '',
    order_source: configuredSetting.order_source ?? '',
    datetime: configuredSetting.sync_start_date,
    status_selected: configuredSetting.status_selected,
    payment_mapping: configuredSetting.payment_mapping.map((item) => {
      return {ebay_pay_id: item.destination_field, coreforce_pay_id: item.source_field}
    }),
    // no_manage_stock_quantity: '',
    // coreforce_location_mapping: configuredSetting?.inventory_coreforce_location_mapping?.length
    //   ? configuredSetting?.inventory_coreforce_location_mapping?.map((item) => {
    //       return {bufferQty: item.destination_field, location: item.source_field}
    //     })
    //   : [],
    // shipment_location_mapping: configuredSetting?.shipping_method?.length ? configuredSetting?.shipping_method?.map((item) => {
    //   return {ebay_shipping_method : item.destination_field, coreforce_shipping_method: item.source_field}
    // }) : [],
    // coreforce_location_multi: configuredSetting.selected_locations,

    shipping_product_sku: configuredSetting?.shipping_product_sku
      ? configuredSetting?.shipping_product_sku + ''
      : '',
    bufferQuantity: configuredSetting?.buffer_quantity ? configuredSetting?.buffer_quantity : '',

    shipment_carrier_mapping : configuredSetting?.shipment_methods?.length ? configuredSetting.shipment_methods.map((it)=>({coreforce_carrier_description:it?.source_field, ebay_carrier_method: it?.destination_field })) : [{coreforce_carrier_description:'', ebay_carrier_method:''}],
    unmapped_shipment_carrier_mapping: unmapped_methods_list?.length ? unmapped_methods_list.map((it)=>({coreforce_carrier_description:it, ebay_carrier_method:''})) : [],

  }

  useEffect(() => {
    dispatch(getActiveAccountsListAsync(''))
  }, [])

  useEffect(() => {
    if (isBothAccountConnected) {
      dispatch(getConfigurationDataAsync(''))
      dispatch(isInitialSyncProcessAsync(''))
    }
  }, [isBothAccountConnected])

  useEffect(() => {
    let copy: any[] = []
    let copy2: any[] = []
    let exist = status_list.find((it) => {
      if (it.method_id == configuredSetting.status_selected) {
        return it
      }
    })

    console.log('exist', exist)
    if (exist) {
      setSelectedStatus(exist)
    }
    // if (configuredSetting.status_selected.length) {
    //   configuredSetting.status_selected.map((item) => {
    //     let exist = status_list.find((it) => {
    //       if (it.method_id == item) {
    //         return it
    //       }
    //     })
    //     if (exist) {
    //       copy.push(exist)
    //     }
    //     setSelectedStatus((pre) => {
    //       return copy
    //     })
    //   })
    // }
    if (configuredSetting.selected_locations.length) {
      if (coreforce_location_list.length == configuredSetting.selected_locations.length) {
        setLocationsSelected([selectAllOption])
      } else {
        configuredSetting.selected_locations.map((item) => {
          let exist = coreforce_location_list.find((it) => {
            if (it.location_id == item) {
              return it
            }
          })
          if (exist) {
            copy2.push(exist)
          }
          setLocationsSelected((pre) => {
            return copy2
          })
        })
      }
    }
  }, [configuredSetting.status_selected, configuredSetting.selected_locations])

  const tooltip = (
    <Tooltip id='source_detail' className=''>
      <strong>To get the order source code go to</strong>{' '}
      <a target='_blank' href='http://storename.coreware.com/soruces'>
        storename.coreware.com/soruces
      </a>
      <strong> and copy the code you want or create a source and copy the code.</strong>
    </Tooltip>
  )

  const commonTooltip = (msg) => (
    <Tooltip id='source_detail' className=''>
      <strong>{msg}</strong>
    </Tooltip>
  )

  const AstrickMark = () => <span className='text-danger fw-bold fs-2'>*</span>

  if (loadingConfigurationData || loadingAccountDetail || initialSyncLoader) return <Loading />

  if (
    active_accounts.length < 2 || active_accounts.length >= 2
      ? !active_accounts.find((item) => item.platform == 'COREFORCE') ||
        !active_accounts.find((item) => item.platform == 'EBAY')
      : false
  )
    return (
      <EmptyPageWithoutACDetails
        title={messageTemplate.NO_CONNECTION_MESSAGE}
        subtitle={messageTemplate.NO_CONNECTION_HELPER_MESSAGE}
        navigateTo='/connections'
      />
    )

  return (
    <>
      <div className='p-2 py-0'>
        {isBothAccountConnected && initialSyncedStatus && (
          <div>
            <InitialSyncMessage message={initialSyncMessage} />
          </div>
        )}
        {/* title section start */}
        <div className='p-4 mb-8 shadow d-flex flex-row justify-content-between align-items-center'>
          <p className='mb-0 h3'>Configuration Settings</p>
          <button
            className='btn btn-sm btn-info'
            disabled={syncLoader}
            onClick={() => {
              dispatch(syncConfigurationDataAsync('')).then(() => {
                if (isBothAccountConnected) {
                  dispatch(getConfigurationDataAsync(''))
                }
              })
            }}
          >
            <i className='bi bi-arrow-repeat'></i>Fetch Latest Data
          </button>
        </div>
        {/* title section end */}

        {/* Form section start  */}
        <Formik
          validationSchema={schema}
          initialValues={{...initialValuesForOrderMapping}}
          onSubmit={(formDetails, formikHelper) => {
            console.log('values', formDetails)
            // if (activeIndex == 2) {
            //   if (
            //     formDetails.isInventoryConfigurationSync &&
            //     formDetails.coreforce_location_mapping.length == 0
            //   ) {
            //     errorToast('Coreforce locations and multiselect field is required')
            //     return
            //   }
            // }
            const formData = new FormData()
            formData.append(
              'coreforce_product_identifier',
              formDetails.coreforce_product_identifier
            )
            formData.append('ebay_product_identifier', formDetails.ebay_product_identifier)

            if (activeIndex == 1) {
              formData.append('type', 'ORDER_CONFIGURATION')
              formData.append(
                'isOrderConfigurationSync',
                formDetails.isOrderConfigurationSync.toString()
              )
              formData.append('order_method_id', formDetails.order_method_id)
              formData.append('configurationDate', formDetails.datetime)
              formData.append('order_source', formDetails.order_source)
              formData.append('payment_mapping', JSON.stringify(formDetails.payment_mapping))
              formData.append('status_selected', JSON.stringify(formDetails.status_selected))
              formData.append('shipping_product_sku', formDetails.shipping_product_sku)
              // if (formDetails.isOrderConfigurationSync == true) {
              // }
            }
            if (activeIndex == 2) {
              formData.append('type', 'INVENTORY_CONFIGURATION')
              formData.append(
                'isInventoryConfigurationSync',
                formDetails.isInventoryConfigurationSync.toString()
              )
              let location_mapping: {
                location: any
                bufferQty: any
                // outOfStockQty: any
                // minQty: any
                // maxQty: any
              }[] = []
              // if (formDetails.coreforce_location_mapping.length) {
              //   location_mapping = formDetails.coreforce_location_mapping.map((item) => {
              //     return {
              //       location: item.location,
              //       bufferQty: item.bufferQty, // now this is only value not in percent
              //       // outOfStockQty: item.outOfStockQty,
              //       // minQty: item.minQty,
              //       // maxQty: item.maxQty,
              //     }
              //   })
              // }
              // formData.append(
              //   'inventory_coreforce_location_mapping',
              //   JSON.stringify(location_mapping)
              // )
              // formData.append(
              //   'inventory_coreforce_location_multi',
              //   JSON.stringify(formDetails.coreforce_location_multi)
              // )

              formData.append('bufferQty', formDetails.bufferQuantity)
            }

            if (activeIndex == 3) {
              formData.append('type', 'SHIPMENT_CONFIGURATION')
              formData.append('isShipmentSync', formDetails.isShipmentConfigurationSync.toString())
              formData.append('shipment_carrier_mapping', JSON.stringify([...formDetails.shipment_carrier_mapping, ...formDetails.unmapped_shipment_carrier_mapping] ) )
              // formData.append("shipping_methods", JSON.stringify(formDetails.shipment_location_mapping) )
            }

            dispatch(setConfigurationDataAsync(formData))
              .then((response) => {
                console.log('response', response)
                if (response.payload) {
                  successToast(response.payload?.message)
                  // setSelectedStatus([])
                }
                dispatch(getConfigurationDataAsync(''))
              })
              .catch((error) => {
                errorToast('Configuration not saved')
              })
            formikHelper.resetForm()
          }}
        >
          {({values, errors, setFormikState, handleChange, handleSubmit}) => {
            console.log('errors', errors)
            console.log('values', values)

            return (
              <>
                <Form noValidate onSubmit={handleSubmit}>
                  <div className='p-4 card shadow-0'>
                    {/* Select identifier section start  */}
                    <Card className='border border-2 border-light-info  my-8'>
                      {/* <Card.Header className='h2 align-items-center' >Product Module</Card.Header> */}
                      <div className='ribbon ribbon-start'>
                        <div className='ribbon-label bg-info h4'>Select Product Identifier</div>
                      </div>
                      <Card.Body className='mt-4 p-4'>
                        <Row className='mb-5'>
                          <Form.Group as={Col}>
                            <Form.Label>
                              Coreforce Product Unique Identifier <AstrickMark />
                              <OverlayTrigger
                                placement='top'
                                delay={400}
                                overlay={commonTooltip(configurationTooltipMessage.sku_matching)}
                                rootCloseDisabled
                              >
                                <i className='bi bi-info-circle fs-3 text-info ms-3'></i>
                              </OverlayTrigger>{' '}
                            </Form.Label>
                            <Form.Select
                              aria-label='coreforce-poduct_unique_identifier'
                              name={'coreforce_product_identifier'}
                              required
                              value={values.coreforce_product_identifier}
                              onChange={handleChange}
                              defaultValue={coreforce_product_identifier_list[0]?.method_id}
                            >
                              {coreforce_product_identifier_list.length &&
                                coreforce_product_identifier_list.map((item, index) => {
                                  return (
                                    <option
                                      key={'coreforce_identifier' + index}
                                      value={item.method_id}
                                    >
                                      {item.method}
                                    </option>
                                  )
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>
                              This is a required field
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>
                              Ebay Product Unique Identifier <AstrickMark />{' '}
                              <OverlayTrigger
                                placement='top'
                                delay={400}
                                overlay={commonTooltip(configurationTooltipMessage.sku_matching)}
                                rootCloseDisabled
                              >
                                <i className='bi bi-info-circle fs-3 text-info ms-3'></i>
                              </OverlayTrigger>{' '}
                            </Form.Label>
                            <Form.Select
                              aria-label='ebay-product_unique_identifier'
                              name='ebay_product_identifier'
                              required
                              value={values.ebay_product_identifier}
                              onChange={handleChange}
                              defaultValue={ebay_product_identifier_list[0]?.method_id}
                            >
                              {ebay_product_identifier_list.length &&
                                ebay_product_identifier_list.map((item, index) => {
                                  return (
                                    <option key={'ebay_identifier' + index} value={item.method_id}>
                                      {item.method}
                                    </option>
                                  )
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>
                              This is a required field
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      </Card.Body>
                    </Card>
                    {/* Select identifier section end  */}
                    <div className='separator mb-8'></div>
                    <div className='border border-2 pt-2'>
                      <Tabs
                        onSelect={(activeKey) => {
                          if (activeKey) {
                            setActiveIndex(Number(activeKey))
                          }
                        }}
                        tabIndex={1}
                        defaultActiveKey={activeIndex}
                        id='configuration-mapping'
                        className='mb-3 h4'
                        // fill
                        transition={Fade}
                        // variant='pills'
                      >
                        <Tab
                          eventKey={1}
                          title='Order Configuration'
                          tabClassName={
                            activeIndex == 1 ? activeTabCSS + ' custom-tab me-2' : inactiveTabCSS
                          }
                          className='p-6 rounded '
                        >
                          <div>
                            {/* Order sync section start  */}
                            <Row className='mt-2 border rounded pt-3 p-2'>
                              <Form.Group as={Col} className='d-flex align-items-center'>
                                <Form.Label>Enable Order Sync to Coreforce</Form.Label>
                                <Form.Check
                                  type='switch'
                                  name='isOrderConfigurationSync'
                                  checked={values.isOrderConfigurationSync}
                                  className='form-switch ms-6'
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            </Row>
                            {/* Order sync section end  */}

                            {/* Choose default method start  */}
                            {values.isOrderConfigurationSync && (
                              <Card className='border border-2 border-light-info mt-12'>
                                <div className='ribbon ribbon-start'>
                                  <div className='ribbon-label bg-info h4'>
                                    Choose Default Method
                                  </div>
                                </div>
                                <Card.Body className='mt-4 p-4'>
                                  <Row className='mb-5'>
                                    <Form.Group as={Col}>
                                      <Form.Label>
                                        Coreforce Order Methods <AstrickMark />{' '}
                                        <OverlayTrigger
                                          placement='top'
                                          delay={400}
                                          overlay={commonTooltip(
                                            configurationTooltipMessage.order_method
                                          )}
                                          rootCloseDisabled
                                        >
                                          <i className='bi bi-info-circle fs-3 text-info ms-3'></i>
                                        </OverlayTrigger>{' '}
                                      </Form.Label>
                                      <Form.Select
                                        aria-label='coreforce-order-method'
                                        required
                                        name={`order_method_id`}
                                        value={values.order_method_id}
                                        onChange={handleChange}
                                        isInvalid={!!errors.order_method_id}
                                      >
                                        <option value={''}>Open this select menu</option>
                                        {coreforce_order_method_list.length &&
                                          coreforce_order_method_list.map((item, index) => {
                                            return (
                                              <option
                                                key={'coreforce_order_method' + index}
                                                value={item.method_id}
                                              >
                                                {item.method}
                                              </option>
                                            )
                                          })}
                                      </Form.Select>
                                      <Form.Control.Feedback type='invalid'>
                                        This is a required field
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                      <Form.Label>
                                        Order Source <AstrickMark />
                                        <OverlayTrigger
                                          placement='top'
                                          delay={400}
                                          overlay={commonTooltip(
                                            configurationTooltipMessage.order_source
                                          )}
                                          rootCloseDisabled
                                        >
                                          <i className='bi bi-info-circle fs-3 text-info ms-3'></i>
                                        </OverlayTrigger>
                                      </Form.Label>
                                      <Form.Control
                                        type='text'
                                        placeholder='Order Source'
                                        name={fieldNames['order_source']}
                                        required
                                        value={values.order_source}
                                        onChange={handleChange}
                                        isInvalid={!!errors.order_source}
                                      />
                                      <Form.Control.Feedback type='invalid'>
                                        This is a required field
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Row>
                                  <Row>
                                    <Form.Group as={Col}>
                                      <Form.Label>
                                        Enter coreforce product id for shipment item <AstrickMark />
                                        <OverlayTrigger
                                          placement='top'
                                          delay={400}
                                          overlay={commonTooltip(
                                            configurationTooltipMessage.coreforce_product_id
                                          )}
                                          rootCloseDisabled
                                        >
                                          <i className='bi bi-info-circle fs-3 text-info ms-3'></i>
                                        </OverlayTrigger>
                                      </Form.Label>
                                      <Form.Control
                                        type='text'
                                        placeholder='Enter coreforce product id for shipment item'
                                        name={fieldNames['shipping_product_sku']}
                                        autoComplete='off'
                                        required
                                        value={values.shipping_product_sku}
                                        onChange={(ev) => {
                                          if (isNaN(Number(ev.target.value))) {
                                            return
                                          } else {
                                            handleChange(ev)
                                          }
                                        }}
                                        isInvalid={!!errors.shipping_product_sku}
                                      />
                                      <Form.Control.Feedback type='invalid'>
                                        This is a required field
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Row>
                                  {/* <Row>
                                    <Form.Group as={Col}>
                                      <Form.Label>
                                        Corforce Status Id <AstrickMark />
                                        <OverlayTrigger
                                          placement='top'
                                          delay={400}
                                          overlay={commonTooltip(
                                            configurationTooltipMessage.coreforce_product_id
                                          )}
                                          rootCloseDisabled
                                        >
                                          <i className='bi bi-info-circle fs-3 text-info ms-3'></i>
                                        </OverlayTrigger>
                                      </Form.Label>
                                      <Form.Control
                                        type='text'
                                        placeholder='Enter coreforce product id for shipment item'
                                        name={fieldNames['shipping_product_sku']}
                                        autoComplete='off'
                                        required
                                        value={values.shipping_product_sku}
                                        onChange={(ev) => {
                                          if (isNaN(Number(ev.target.value))) {
                                            return
                                          } else {
                                            handleChange(ev)
                                          }
                                        }}
                                        isInvalid={!!errors.shipping_product_sku}
                                      />
                                      <Form.Control.Feedback type='invalid'>
                                        This is a required field
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                      <Form.Label>
                                        Coreforce Status Name <AstrickMark />
                                        <OverlayTrigger
                                          placement='top'
                                          delay={400}
                                          overlay={commonTooltip(
                                            configurationTooltipMessage.coreforce_product_id
                                          )}
                                          rootCloseDisabled
                                        >
                                          <i className='bi bi-info-circle fs-3 text-info ms-3'></i>
                                        </OverlayTrigger>
                                      </Form.Label>
                                      <Form.Control
                                        type='text'
                                        placeholder='Coreforce Status Name'
                                        name={fieldNames['shipping_product_sku']}
                                        autoComplete='off'
                                        required
                                        value={values.shipping_product_sku}
                                        onChange={(ev) => {
                                          if (isNaN(Number(ev.target.value))) {
                                            return
                                          } else {
                                            handleChange(ev)
                                          }
                                        }}
                                        isInvalid={!!errors.shipping_product_sku}
                                      />
                                      <Form.Control.Feedback type='invalid'>
                                        This is a required field
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Row> */}
                                </Card.Body>
                              </Card>
                            )}
                            {/* Choose default method end  */}

                            {/* Payment Mapping start  */}
                            {values.isOrderConfigurationSync && (
                              <Card className='border border-2 border-light-info mt-12'>
                                <div className='ribbon ribbon-start'>
                                  <div className='ribbon-label bg-info h4'>
                                    Payment Mapping Module{' '}
                                    <OverlayTrigger
                                      placement='top'
                                      delay={400}
                                      overlay={commonTooltip(
                                        configurationTooltipMessage.payment_mapping
                                      )}
                                      rootCloseDisabled
                                    >
                                      <i className='bi bi-info-circle fs-3 text-white ms-3'></i>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                                <Card.Body className='mt-4'>
                                  <>
                                    {values.payment_mapping.length ? (
                                      values.payment_mapping.map((payment, index) => {
                                        return (
                                          <Row className='mb-5' key={'products' + index}>
                                            <Form.Group as={Col}>
                                              <Form.Label>
                                                Coreforce Payment Method <AstrickMark />{' '}
                                              </Form.Label>
                                              <Form.Select
                                                aria-label='coreforce-payment-method'
                                                required
                                                name={`payment_mapping[${index}].coreforce_pay_id`}
                                                value={payment.coreforce_pay_id}
                                                onChange={handleChange}
                                                isInvalid={
                                                  errors.payment_mapping?.length &&
                                                  errors.payment_mapping[index] !== undefined
                                                    ? !!errors?.payment_mapping[index][
                                                        'coreforce_pay_id'
                                                      ]
                                                    : false
                                                }
                                              >
                                                <option value={''}>Open this select menu</option>
                                                {core_pay_method_list.length &&
                                                  core_pay_method_list.map((item, index) => {
                                                    return (
                                                      <option
                                                        key={'coreforce_payment_method' + index}
                                                        value={item.method_id}
                                                      >
                                                        {item.method}
                                                      </option>
                                                    )
                                                  })}
                                              </Form.Select>
                                              <Form.Control.Feedback type='invalid'>
                                                This is a required field
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className='col-1 align-self-center pt-8'>
                                              <div className='d-flex align-items-center justify-content-center w-100'>
                                                <BSIcon iconClass='bi bi-arrow-left-right' />
                                              </div>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                              <Form.Label>
                                                Ebay Payment Method <AstrickMark />
                                              </Form.Label>
                                              <Form.Select
                                                aria-label='ebay-payment-method'
                                                required
                                                name={`payment_mapping[${index}].ebay_pay_id`}
                                                value={payment.ebay_pay_id}
                                                onChange={handleChange}
                                                isInvalid={
                                                  errors.payment_mapping?.length &&
                                                  errors.payment_mapping[index] !== undefined
                                                    ? !!errors?.payment_mapping[index][
                                                        'ebay_pay_id'
                                                      ]
                                                    : false
                                                }
                                              >
                                                <option value={''}>Open this select menu</option>
                                                {ebay_pay_method_list.length &&
                                                  ebay_pay_method_list.map((item, index) => {
                                                    return (
                                                      <option
                                                        key={'ebay_payment_method' + index}
                                                        value={item.method_id}
                                                      >
                                                        {item.method}
                                                      </option>
                                                    )
                                                  })}
                                              </Form.Select>
                                              <Form.Control.Feedback type='invalid'>
                                                This is a required field
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group
                                              className={`col-1 align-self-center pt-8  `}
                                            >
                                              <div
                                                className={`d-flex align-items-center justify-content-center w-100 `}
                                              >
                                                <button
                                                  type='button'
                                                  className={`btn p-2 py-1`}
                                                  onClick={() => {
                                                    let temp: any = [...values.payment_mapping]
                                                    let filtered: any = temp.filter(
                                                      (_, ind) => ind !== index
                                                    )

                                                    temp.push({
                                                      ebay_pay_id: '',
                                                      coreforce_pay_id: '',
                                                    })
                                                    setFormikState((pre) => {
                                                      return {
                                                        ...pre,
                                                        values: {
                                                          ...pre.values,
                                                          payment_mapping: filtered,
                                                        },
                                                      }
                                                    })
                                                  }}
                                                >
                                                  <BSIcon iconClass='bi bi-x-circle text-danger' />
                                                </button>
                                              </div>
                                            </Form.Group>
                                          </Row>
                                        )
                                      })
                                    ) : (
                                      <Row className='mb-5'>
                                        <Form.Group as={Col}>
                                          <Form.Label>
                                            Coreforce Payment Method <AstrickMark />
                                          </Form.Label>
                                          <Form.Select
                                            aria-label='coreforce-payment-method-empty-case'
                                            required
                                            name={`payment_mapping[0].coreforce_pay_id`}
                                            value={values.payment_mapping[0]?.coreforce_pay_id}
                                            onChange={handleChange}
                                            isInvalid={!!errors.payment_mapping}
                                          >
                                            <option value={''}>Open this select menu</option>
                                            {core_pay_method_list.length &&
                                              core_pay_method_list.map((item, index) => {
                                                return (
                                                  <option
                                                    key={'coreforce_pay_method' + index}
                                                    value={item.method_id}
                                                  >
                                                    {item.method}
                                                  </option>
                                                )
                                              })}
                                          </Form.Select>
                                          <Form.Control.Feedback type='invalid'>
                                            This is a required field
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className='col-1 align-self-center pt-8'>
                                          <div className='d-flex align-items-center justify-content-center w-100'>
                                            <BSIcon iconClass='bi bi-arrow-left-right' />
                                          </div>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                          <Form.Label>
                                            Ebay Payment Method <AstrickMark />{' '}
                                          </Form.Label>
                                          <Form.Select
                                            required
                                            aria-label='ebay-payment-method-empty-case'
                                            name={`payment_mapping[0].ebay_pay_id`}
                                            value={values.payment_mapping[0]?.ebay_pay_id}
                                            onChange={handleChange}
                                            isInvalid={!!errors.payment_mapping}
                                          >
                                            <option value={''}>Open this select menu</option>
                                            {ebay_pay_method_list.length &&
                                              ebay_pay_method_list.map((item, index) => {
                                                return (
                                                  <option
                                                    key={'ebay_pay_method' + index}
                                                    value={item.method_id}
                                                  >
                                                    {item.method}
                                                  </option>
                                                )
                                              })}
                                          </Form.Select>
                                          <Form.Control.Feedback type='invalid'>
                                            This is a required field
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className={`col-1 align-self-center pt-8  `}>
                                          <div
                                            className={`d-flex align-items-center justify-content-center w-100 `}
                                          ></div>
                                        </Form.Group>
                                      </Row>
                                    )}
                                    <Row as={Col} className='justify-content-end'>
                                      <Button
                                        variant='info'
                                        style={{width: 'min-content'}}
                                        className='p-2 px-4'
                                        onClick={() => {
                                          let temp: any = [...values.payment_mapping]
                                          temp.push({
                                            ebay_pay_id: '',
                                            coreforce_pay_id: '',
                                          })
                                          if (values.payment_mapping.length == 0) {
                                            temp.push({
                                              ebay_pay_id: '',
                                              coreforce_pay_id: '',
                                            })
                                          }
                                          setFormikState((pre) => {
                                            return {
                                              ...pre,
                                              values: {...pre.values, payment_mapping: temp},
                                            }
                                          })
                                        }}
                                      >
                                        <BSIcon iconClass='bi bi-plus-lg fs-4 fw-bold ' />
                                      </Button>
                                    </Row>
                                  </>
                                </Card.Body>
                              </Card>
                            )}
                            {/* Payment Mapping end  */}

                            {/* Date and status section start  */}
                            {values.isOrderConfigurationSync && (
                              <Card className='border border-2 border-light-info mt-12'>
                                <div className='ribbon ribbon-start'>
                                  <div className='ribbon-label bg-info h4'>
                                    Choose Date and Status
                                  </div>
                                </div>
                                <Card.Body className='mt-4 p-4'>
                                  <Row className='mb-5'>
                                    <Form.Group as={Col}>
                                      <Form.Label>
                                        Set a starting point of Order (Go Live Date-Time){' '}
                                        <AstrickMark />{' '}
                                        <OverlayTrigger
                                          placement='top'
                                          delay={400}
                                          overlay={commonTooltip(
                                            configurationTooltipMessage.date_time_for_sync
                                          )}
                                          rootCloseDisabled
                                        >
                                          <i className='bi bi-info-circle fs-3 text-info ms-3'></i>
                                        </OverlayTrigger>{' '}
                                      </Form.Label>
                                      <Form.Control
                                        type='datetime-local'
                                        required
                                        disabled={!!configuredSetting.sync_start_date}
                                        name={fieldNames.datetime}
                                        onChange={handleChange}
                                        isInvalid={!!errors.datetime}
                                        value={values.datetime}
                                      />
                                      <Form.Control.Feedback type='invalid'>
                                        This is a required field
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                      <Form.Label>
                                        Select eBay Order Status to download Orders <AstrickMark />
                                        <OverlayTrigger
                                          placement='top'
                                          delay={400}
                                          overlay={commonTooltip(
                                            configurationTooltipMessage.order_status
                                          )}
                                          rootCloseDisabled
                                        >
                                          <i className='bi bi-info-circle fs-3 text-info ms-3'></i>
                                        </OverlayTrigger>{' '}
                                      </Form.Label>
                                      <Select
                                        required
                                        key={selectedStatus?.method_id}
                                        className='react-select-styled react-select-solid form-control p-0'
                                        isMulti={false}
                                        getOptionLabel={(item) => item?.method}
                                        getOptionValue={(item) => item?.method_id}
                                        classNamePrefix='react-select'
                                        name='status_selected'
                                        defaultValue={selectedStatus}
                                        onChange={(newVals) => {
                                          // let statusIds = newVals.map((item) => item?.method_id)
                                          if (newVals) {
                                            setFormikState((pre) => {
                                              return {
                                                ...pre,
                                                values: {
                                                  ...pre.values,
                                                  status_selected: newVals?.method_id,
                                                },
                                              }
                                            })
                                          }

                                          setSelectedStatus(newVals)
                                        }}
                                        styles={{
                                          menu: (baseStyles) => ({
                                            ...baseStyles,
                                            // width: 200,
                                          }),
                                          valueContainer: (baseStyles) => ({
                                            ...baseStyles,
                                            height: 40,
                                          }),
                                          control:(baseStyles)=>{
                                            return {...baseStyles, borderWidth:0 }
                                          },
                                          container: (baseStyles) => {
                                            let cusSty = true
                                              ? !values.status_selected
                                                ? {border: '0.5px solid red', borderRadius: '0.475rem'}
                                                : {border: '0.5px solid rgba(112 112 112 / 0.67)', borderRadius: '0.475rem'}
                                              : {}

                                            return {...baseStyles, ...cusSty}
                                          },
                                        }}
                                        options={[...status_list]}
                                        placeholder='Select an option'
                                      />
                                      <Form.Control.Feedback type='invalid'>
                                        This is a required field
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Row>
                                </Card.Body>
                              </Card>
                            )}
                            {/* Date and status section end  */}
                          </div>
                        </Tab>
                        <Tab
                          eventKey={2}
                          title='Inventory Configuration'
                          tabClassName={
                            activeIndex == 2 ? activeTabCSS + ' custom-tab-1 ms-2 ' : inactiveTabCSS
                          }
                          className='p-6 rounded'
                        >
                          <div>
                            {/* inventory sync section start */}
                            <Row className='mt-2 border rounded pt-3 p-2'>
                              <Form.Group as={Col} className='d-flex align-items-center'>
                                <Form.Label>Enable Inventory Sync to Ebay</Form.Label>
                                <Form.Check
                                  type='switch'
                                  name={fieldNames['isInventoryConfigurationSync']}
                                  checked={values.isInventoryConfigurationSync}
                                  className='form-switch ms-6'
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            </Row>
                            {/* inventory sync section end */}

                            {/* Quantity Rounds section start  */}
                            {/* {values.isInventoryConfigurationSync && (
                            <Row className='mt-2 border rounded pt-3 p-2 justify-content-between'>
                              <Row className='justify-content-between'>
                                <Row className='col-4 align-content-center'>
                                  <Form.Label>Quantity Rounds</Form.Label>
                                </Row>
                                <Row className='col-8'>
                                  <Form.Select disabled>
                                    <option value=''>
                                      Round Math rules {'(<0.5 rounds to 0, >= 0.5 rounds to 1)'}{' '}
                                    </option>
                                  </Form.Select>
                                </Row>
                              </Row>
                            </Row>
                          )} */}
                            {/* Quantity Rounds section end  */}

                            {/* For no manage Stocks start  */}
                            {/* {values.isInventoryConfigurationSync && (
                            <Row className='mt-2 border rounded pt-3 p-2'>
                              <Row className='justify-content-between '>
                                <Row className='col-8 align-content-center'>
                                  <Form.Label>
                                    For no manage Stocks listing, automatically set quantity to
                                  </Form.Label>
                                </Row>
                                <Row className='col-2'>
                                  <Form.Group>
                                    <Form.Control
                                      placeholder='In %'
                                      required
                                      value={values.no_manage_stock_quantity}
                                      name={fieldNames['no_manage_stock_quantity']}
                                      onChange={handleChange}
                                      isInvalid={!!errors.no_manage_stock_quantity}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                      This is a required field
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Row>
                              </Row>
                            </Row>
                          )} */}
                            {/* For no manage Stocks end  */}

                            {values.isInventoryConfigurationSync && (
                              <Card className='border border-2 border-light-info mt-12'>
                                <div className='ribbon ribbon-start'>
                                  <div className='ribbon-label bg-info h4 z-0'>
                                    Manage Buffer Quantity
                                  </div>
                                </div>
                                <Card.Body className='mt-4'>
                                  <>
                                    <Row className='mb-5 border border-bottom border-secondary py-4'>
                                      <Row as={Col} className='pe-0'>
                                        <Row>
                                          <div
                                            className='position-absolute text-center'
                                            style={{top: '28px'}}
                                          >
                                            <p className='badge badge-light-info p-2'>
                                              Automatically adjust the quantities on this channel in
                                              relation to the total quantity on your Main Store
                                            </p>
                                          </div>
                                          <Form.Group as={Col}>
                                            <Form.Label>
                                              Buffer Quantity <AstrickMark />{' '}
                                              <OverlayTrigger
                                                placement='top'
                                                delay={400}
                                                overlay={commonTooltip(
                                                  configurationTooltipMessage.buffer_quantity
                                                )}
                                                rootCloseDisabled
                                              >
                                                <i className='bi bi-info-circle fs-3 ms-3'></i>
                                              </OverlayTrigger>
                                            </Form.Label>
                                            <Form.Control
                                              placeholder=''
                                              required
                                              value={values.bufferQuantity}
                                              name={fieldNames['bufferQuantity']}
                                              isInvalid={!!errors.bufferQuantity}
                                              onChange={(ev) => {
                                                if (isNaN(Number(ev.target.value))) {
                                                  return
                                                } else {
                                                  handleChange(ev)
                                                }
                                              }}
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                              This is a required field
                                            </Form.Control.Feedback>
                                          </Form.Group>
                                        </Row>
                                      </Row>
                                    </Row>
                                  </>
                                </Card.Body>
                              </Card>
                            )}

                            {/* location mapping section start  */}
                            {/* {values.isInventoryConfigurationSync && (
                              <Card className='border border-2 border-light-info mt-12'>
                                <div className='ribbon ribbon-start'>
                                  <div className='ribbon-label bg-info h4 z-0'>
                                    Location Mapping Module
                                  </div>
                                </div>
                                <Card.Body className='mt-4'>
                                  <>
                                    <div className='position-absolute top-0' style={{left: '30%'}}>
                                      <p className='badge badge-light-info p-2'>
                                        Automatically adjust the quantities on this channel in
                                        relation to the total quantity on your Main Store
                                      </p>
                                    </div>
                                    {values.coreforce_location_mapping.length ? (
                                      values.coreforce_location_mapping.map((crl, index) => {
                                        return (
                                          <Row
                                            className='mb-5 border border-bottom border-secondary py-4'
                                            key={'location-mapping' + index}
                                          >
                                            <Form.Group
                                              as={Col}
                                              className='col-sm-4 align-self-center'
                                            >
                                              <Form.Label>
                                                Coreforce Locations <AstrickMark />
                                              </Form.Label>
                                              <Form.Select
                                                aria-label='coreforce-location'
                                                required
                                                name={`coreforce_location_mapping[${index}].location`}
                                                value={crl.location}
                                                onChange={handleChange}
                                                isInvalid={
                                                  errors.coreforce_location_mapping?.length &&
                                                  errors.coreforce_location_mapping[index] !==
                                                    undefined
                                                    ? !!errors?.coreforce_location_mapping[index][
                                                        'location'
                                                      ]
                                                    : false
                                                }
                                              >
                                                <option value={''}>Open this select menu</option>
                                                {coreforce_location_list.length &&
                                                  coreforce_location_list.map((item, index) => {
                                                    return (
                                                      <option
                                                        key={'coreforce_location' + index}
                                                        value={item.location_id}
                                                      >
                                                        {item.description}
                                                      </option>
                                                    )
                                                  })}
                                              </Form.Select>
                                              <Form.Control.Feedback type='invalid'>
                                                This is a required field
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className='col-1 align-self-center pt-8'>
                                              <div className='d-flex align-items-center justify-content-center w-100'>
                                                <BSIcon iconClass='bi bi-arrow-left-right' />
                                              </div>
                                            </Form.Group>
                                            <Row as={Col} className='pe-0'>
                                              <Row>
                                                <Form.Group as={Col}>
                                                  <Form.Label>
                                                    Buffer Quantity <AstrickMark />
                                                  </Form.Label>
                                                  <Form.Control
                                                    placeholder=''
                                                    required
                                                    value={
                                                      values.coreforce_location_mapping[index][
                                                        'bufferQty'
                                                      ]
                                                    }
                                                    name={`coreforce_location_mapping[${index}].bufferQty`}
                                                    isInvalid={
                                                      errors.coreforce_location_mapping?.length &&
                                                      errors.coreforce_location_mapping[index] !==
                                                        undefined
                                                        ? !!errors?.coreforce_location_mapping[
                                                            index
                                                          ]['bufferQty']
                                                        : false
                                                    }
                                                    onChange={(ev) => {
                                                      if (isNaN(Number(ev.target.value))) {
                                                        return
                                                      } else {
                                                        handleChange(ev)
                                                      }
                                                    }}
                                                  />
                                                  <Form.Control.Feedback type='invalid'>
                                                    This is a required field
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              </Row>
                                            </Row>
                                            <Form.Group
                                              className={`col-1 align-self-center pt-8  `}
                                            >
                                              <div
                                                className={`d-flex align-items-center justify-content-center w-100 `}
                                              >
                                                <button
                                                  type='button'
                                                  className={`btn p-2 py-1 `}
                                                  onClick={() => {
                                                    let temp: any = [
                                                      ...values.coreforce_location_mapping,
                                                    ]
                                                    let filtered: any = temp.filter(
                                                      (_, ind) => ind !== index
                                                    )

                                                    temp.push({
                                                      location: '',
                                                      bufferQty: '',
                                                    })
                                                    setFormikState((pre) => {
                                                      return {
                                                        ...pre,
                                                        values: {
                                                          ...pre.values,
                                                          coreforce_location_mapping: filtered,
                                                        },
                                                      }
                                                    })
                                                  }}
                                                >
                                                  <BSIcon iconClass='bi bi-x-circle text-danger' />
                                                </button>
                                              </div>
                                            </Form.Group>
                                          </Row>
                                        )
                                      })
                                    ) : (
                                      <Row className='mb-5'>
                                        <Form.Group as={Col} className='col-sm-4 align-self-center'>
                                          <Form.Label>
                                            Coreforce Locations <AstrickMark />
                                          </Form.Label>
                                          <Form.Select
                                            aria-label='coreforce-location-empty-case'
                                            required
                                            name={`coreforce_location_mapping[0].location`}
                                            value={values.coreforce_location_mapping[0]?.location}
                                            onChange={handleChange}
                                            isInvalid={!!errors.coreforce_location_mapping}
                                          >
                                            <option value={''}>Open this select menu</option>
                                            {coreforce_location_list.length &&
                                              coreforce_location_list.map((item, index) => {
                                                return (
                                                  <option
                                                    key={'coreforce_pay_method' + index}
                                                    value={item.location_id}
                                                  >
                                                    {item.description}
                                                  </option>
                                                )
                                              })}
                                          </Form.Select>
                                          <Form.Control.Feedback type='invalid'>
                                            This is a required field
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className='col-sm-1 align-self-center pt-8'>
                                          <div className='d-flex align-items-center justify-content-center w-100'>
                                            <BSIcon iconClass='bi bi-arrow-left-right' />
                                          </div>
                                        </Form.Group>
                                        <Row as={Col} className='pe-0'>
                                          <Row>
                                            <Form.Group as={Col}>
                                              <Form.Label>
                                                Buffer Quantity <AstrickMark />
                                              </Form.Label>
                                              <Form.Control
                                                placeholder=''
                                                required
                                                name={`coreforce_location_mapping[0].bufferQty`}
                                                onChange={handleChange}
                                                isInvalid={!!errors.coreforce_location_mapping}
                                              />
                                              <Form.Control.Feedback type='invalid'>
                                                This is a required field
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Row>
                                        </Row>
                                      </Row>
                                    )}
                                    <Row as={Col} className='justify-content-end'>
                                      <Button
                                        variant='info'
                                        style={{width: 'min-content'}}
                                        className='p-2 px-4'
                                        onClick={() => {
                                          let temp: any = [...values.coreforce_location_mapping]
                                          temp.push({
                                            location: '',
                                            bufferQty: '',
                                            
                                          })
                                          if (values.coreforce_location_mapping.length == 0) {
                                            temp.push({
                                              location: '',
                                              bufferQty: '',
                                              
                                            })
                                          }
                                          setFormikState((pre) => {
                                            return {
                                              ...pre,
                                              values: {
                                                ...pre.values,
                                                coreforce_location_mapping: temp,
                                              },
                                            }
                                          })
                                        }}
                                      >
                                        <BSIcon iconClass='bi bi-plus-lg fs-4 fw-bold ' />
                                      </Button>
                                    </Row>
                                  </>
                                </Card.Body>
                              </Card>
                            )} */}

                            {/* location mapping section end  */}
                          </div>
                        </Tab>
                        <Tab
                          eventKey={3}
                          title='Shipment Configuration'
                          tabClassName={
                            activeIndex == 3 ? activeTabCSS + ' custom-tab-1 ms-2 ' : inactiveTabCSS
                          }
                          className='p-6 rounded'
                        >
                          <div>
                            {/* shipment sync section start */}
                            <Row className='mt-2 border rounded pt-3 p-2'>
                              <Form.Group as={Col} className='d-flex align-items-center'>
                                <Form.Label>Enable Shipment Sync to Ebay</Form.Label>
                                <Form.Check
                                  type='switch'
                                  name={fieldNames['isShipmentConfigurationSync']}
                                  checked={values.isShipmentConfigurationSync}
                                  className='form-switch ms-6'
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            </Row>
                            {/* shipment sync section end */}
                            
                            {/* career shipment mapping start */}
                            <Card className='border border-2 border-light-info mt-12'>
                              <div className='ribbon ribbon-start'>
                                <div className='ribbon-label bg-info h4'>
                                  Shipping carrier mapping
                                </div>
                              </div>
                                <Card.Body className='mt-4'>
                                  {
                                    values.unmapped_shipment_carrier_mapping.length ?
                                    <Row className='mb-5 border border-bottom border-secondary py-4' >
                                      <div
                                            className='position-absolute text-center'
                                            style={{top: '28px'}}
                                          >
                                            <p className='badge badge-light-info p-2'>
                                              Please Map newly found shipping carriers
                                            </p>
                                          </div>
                                      {values.unmapped_shipment_carrier_mapping.map((item:CAREER_MAPPING_ITEM, index:number)=>{
                                        return(
                                          <>
                                          <Row className='my-5' key={'career mapping'+index} >
                                              <Form.Group
                                              as={Col}
                                              className='col-sm-5 align-self-center'
                                            >
                                              <Form.Label>Coreforce Carrier Description</Form.Label> <AstrickMark />
                                              <Form.Control
                                                aria-label='coreforce-career'
                                                readOnly
                                                placeholder='Enter coreforce carrier description'
                                                name={`unmapped_shipment_carrier_mapping[${index}].coreforce_carrier_description`}
                                                value={item.coreforce_carrier_description}
                                                isInvalid={
                                                  errors.unmapped_shipment_carrier_mapping?.length &&
                                                  errors.unmapped_shipment_carrier_mapping[index] !==
                                                    undefined
                                                    ? !!errors?.unmapped_shipment_carrier_mapping[index][
                                                        'coreforce_carrier_description'
                                                      ]
                                                    : false
                                                }
                                              />
                                                
                                              <Form.Control.Feedback type='invalid'>
                                                This is a required field
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className='col-1 align-self-center pt-8'>
                                              <div className='d-flex align-items-center justify-content-center w-100'>
                                                <BSIcon iconClass='bi bi-arrow-left-right' />
                                              </div>
                                            </Form.Group>
                                            <Form.Group
                                              as={Col}
                                              className='col-sm-5 align-self-center'
                                            >
                                              <Form.Label>Ebay Carrier Methods</Form.Label> <AstrickMark />
                                              <Form.Select
                                                aria-label='coreforce-career'
                                                required
                                                name={`unmapped_shipment_carrier_mapping[${index}].ebay_carrier_method`}
                                                value={item.ebay_carrier_method}
                                                onChange={handleChange}
                                                isInvalid={
                                                  errors.unmapped_shipment_carrier_mapping?.length &&
                                                  errors.unmapped_shipment_carrier_mapping[index] !==
                                                    undefined
                                                    ? !!errors?.unmapped_shipment_carrier_mapping[index][
                                                        'ebay_carrier_method'
                                                      ]
                                                    : false
                                                }
                                              >
                                                <option value={''}>Open this select menu</option>
                                                {ebay_shipping_methods.length &&
                                                  ebay_shipping_methods.map((item, index) => {
                                                    return (
                                                      <option
                                                        key={'ebay_career_methods' + index}
                                                        value={item.method_id}
                                                      >
                                                        {item.method}
                                                      </option>
                                                    )
                                                  })}
                                              </Form.Select>
                                              <Form.Control.Feedback type='invalid'>
                                                This is a required field
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Row>
                                          </>
                                        )
                                      })}

                                    </Row> : null
                                  }
                                  
                                  {
                                    values.shipment_carrier_mapping.length ?
                                    values.shipment_carrier_mapping.map((item:CAREER_MAPPING_ITEM, index:number)=>{
                                      return(
                                        <>
                                        <Row className='mb-5' key={'career mapping'+index} >
                                            <Form.Group
                                            as={Col}
                                            className='col-sm-5 align-self-center'
                                          >
                                            <Form.Label>Coreforce Carrier Description</Form.Label> <AstrickMark />
                                            <Form.Control
                                              aria-label='coreforce-career'
                                              required
                                              placeholder='Enter coreforce carrier description'
                                              autoComplete='off'
                                              name={`shipment_carrier_mapping[${index}].coreforce_carrier_description`}
                                              value={item.coreforce_carrier_description}
                                              onChange={handleChange}
                                              isInvalid={
                                                errors.shipment_carrier_mapping?.length &&
                                                errors.shipment_carrier_mapping[index] !==
                                                  undefined
                                                  ? !!errors?.shipment_carrier_mapping[index][
                                                      'coreforce_carrier_description'
                                                    ]
                                                  : false
                                              }
                                            />
                                              
                                            <Form.Control.Feedback type='invalid'>
                                              This is a required field
                                            </Form.Control.Feedback>
                                          </Form.Group>
                                          <Form.Group className='col-1 align-self-center pt-8'>
                                            <div className='d-flex align-items-center justify-content-center w-100'>
                                              <BSIcon iconClass='bi bi-arrow-left-right' />
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            as={Col}
                                            className='col-sm-5 align-self-center'
                                          >
                                            <Form.Label>Ebay Carrier Methods</Form.Label> <AstrickMark />
                                            <Form.Select
                                              aria-label='coreforce-career'
                                              required
                                              name={`shipment_carrier_mapping[${index}].ebay_carrier_method`}
                                              value={item.ebay_carrier_method}
                                              onChange={handleChange}
                                              isInvalid={
                                                errors.shipment_carrier_mapping?.length &&
                                                errors.shipment_carrier_mapping[index] !==
                                                  undefined
                                                  ? !!errors?.shipment_carrier_mapping[index][
                                                      'ebay_carrier_method'
                                                    ]
                                                  : false
                                              }
                                            >
                                              <option value={''}>Open this select menu</option>
                                              {ebay_shipping_methods.length &&
                                                ebay_shipping_methods.map((item, index) => {
                                                  return (
                                                    <option
                                                      key={'ebay_career_methods' + index}
                                                      value={item.method_id}
                                                    >
                                                      {item.method}
                                                    </option>
                                                  )
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type='invalid'>
                                              This is a required field
                                            </Form.Control.Feedback>
                                          </Form.Group>
                                          <Form.Group className={`col-1 align-self-center pt-8  `}>
                                            <div
                                              className={`d-flex align-items-center justify-content-center w-100 `}
                                            >
                                              <button
                                                type='button'
                                                className={`btn p-2 py-1 ${
                                                  (index === 0 && values.shipment_carrier_mapping.length === 1) ? 'd-none' : ''
                                                } `}
                                                onClick={() => {
                                                  let temp: any = [
                                                    ...values.shipment_carrier_mapping,
                                                  ]
                                                  let filtered: any = temp.filter(
                                                    (_, ind) => ind !== index
                                                  )

                                                  temp.push({
                                                    ebay_carrier_method: '',
                                                    coreforce_carrier_description: '',
                                                  })
                                                  setFormikState((pre) => {
                                                    return {
                                                      ...pre,
                                                      values: {
                                                        ...pre.values,
                                                        shipment_carrier_mapping: filtered,
                                                      },
                                                    }
                                                  })
                                                }}
                                              >
                                                <BSIcon iconClass='bi bi-x-circle text-danger' />
                                              </button>
                                            </div>
                                          </Form.Group>
                                        </Row>
                                        </>
                                      )
                                    }) : (null)
                                  }
                                    <Row as={Col} className='justify-content-end'>
                                      <Button
                                        variant='info'
                                        style={{width: 'min-content'}}
                                        className='p-2 px-4'
                                        onClick={() => {
                                          let temp: any = [...values.shipment_carrier_mapping]
                                          temp.push({
                                            ebay_carrier_method: '',
                                            coreforce_carrier_description: '',
                                          })
                                          if (values.shipment_carrier_mapping.length == 0) {
                                            temp.push({
                                              ebay_carrier_method: '',
                                              coreforce_carrier_description: '',
                                            })
                                          }
                                          setFormikState((pre) => {
                                            return {
                                              ...pre,
                                              values: {...pre.values, shipment_carrier_mapping: temp},
                                            }
                                          })
                                        }}
                                      >
                                        <BSIcon iconClass='bi bi-plus-lg fs-4 fw-bold ' />
                                      </Button>
                                    </Row>
                                </Card.Body>
                              </Card>
                            {/* career shipment mapping end */}




                            {/* location mapping section start  */}
                            {/* { values.isShipmentConfigurationSync && (
                            <Card className='border border-2 border-light-info mt-12'>
                              <div className='ribbon ribbon-start'>
                                <div className='ribbon-label bg-info h4'>
                                  Shipment Mapping Module
                                </div>
                              </div>
                              <Card.Body className='mt-4'>
                                <>
                                  {values.shipment_location_mapping.length ? (
                                    values.shipment_location_mapping.map((crl, index) => {
                                      return (
                                        <Row
                                          className='mb-5 border border-bottom border-secondary py-4'
                                          key={'shipment-location-mapping' + index}
                                        >
                                          <Form.Group
                                            as={Col}
                                            className='col-sm-5 align-self-center'
                                          >
                                            <Form.Label>Coreforce Shipping Methods</Form.Label>
                                            <Form.Select
                                              aria-label='coreforce-location'
                                              required
                                              name={`shipment_location_mapping[${index}].coreforce_shipping_method`}
                                              value={crl.coreforce_shipping_method}
                                              onChange={handleChange}
                                              isInvalid={
                                                errors.shipment_location_mapping?.length &&
                                                errors.shipment_location_mapping[index] !==
                                                  undefined
                                                  ? !!errors?.shipment_location_mapping[index][
                                                      'coreforce_shipping_method'
                                                    ]
                                                  : false
                                              }
                                            >
                                              <option value={''}>Open this select menu</option>
                                              {coreforce_shipping_methods.length &&
                                                coreforce_shipping_methods.map((item, index) => {
                                                  return (
                                                    <option
                                                      key={'coreforce_shipping_methods' + index}
                                                      value={item.method_id}
                                                    >
                                                      {item.method}
                                                    </option>
                                                  )
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type='invalid'>
                                              This is a required field
                                            </Form.Control.Feedback>
                                          </Form.Group>
                                          <Form.Group className='col-1 align-self-center pt-8'>
                                            <div className='d-flex align-items-center justify-content-center w-100'>
                                              <BSIcon iconClass='bi bi-arrow-left-right' />
                                            </div>
                                          </Form.Group>
                                          <Form.Group
                                            as={Col}
                                            className='col-sm-5 align-self-center'
                                          >
                                            <Form.Label>Ebay Shipping Methods</Form.Label>
                                            <Form.Select
                                              aria-label='coreforce-location'
                                              required
                                              name={`shipment_location_mapping[${index}].ebay_shipping_method`}
                                              value={crl.ebay_shipping_method}
                                              onChange={handleChange}
                                              isInvalid={
                                                errors.shipment_location_mapping?.length &&
                                                errors.shipment_location_mapping[index] !==
                                                  undefined
                                                  ? !!errors?.shipment_location_mapping[index][
                                                      'ebay_shipping_method'
                                                    ]
                                                  : false
                                              }
                                            >
                                              <option value={''}>Open this select menu</option>
                                              {ebay_shipping_methods.length &&
                                                ebay_shipping_methods.map((item, index) => {
                                                  return (
                                                    <option
                                                      key={'ebay_shipping_methods' + index}
                                                      value={item.method_id}
                                                    >
                                                      {item.method}
                                                    </option>
                                                  )
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type='invalid'>
                                              This is a required field
                                            </Form.Control.Feedback>
                                          </Form.Group>
                                          <Form.Group className={`col-1 align-self-center pt-8  `}>
                                            <div
                                              className={`d-flex align-items-center justify-content-center w-100 `}
                                            >
                                              <button
                                                type='button'
                                                className={`btn p-2 py-1 ${
                                                  index == 0 ? 'd-none' : ''
                                                } `}
                                                onClick={() => {
                                                  let temp: any = [
                                                    ...values.shipment_location_mapping,
                                                  ]
                                                  let filtered: any = temp.filter(
                                                    (_, ind) => ind !== index
                                                  )

                                                  temp.push({
                                                    ebay_shipping_method: '',
                                                    coreforce_shipping_method: '',
                                                  })
                                                  setFormikState((pre) => {
                                                    return {
                                                      ...pre,
                                                      values: {
                                                        ...pre.values,
                                                        shipment_location_mapping: filtered,
                                                      },
                                                    }
                                                  })
                                                }}
                                              >
                                                <BSIcon iconClass='bi bi-x-circle text-danger' />
                                              </button>
                                            </div>
                                          </Form.Group>
                                        </Row>
                                      )
                                    })
                                  ) : (
                                    <Row className='mb-5'>
                                      <Form.Group as={Col} className='col-sm-5 align-self-center'>
                                        <Form.Label>Coreforce Shipping Methods</Form.Label>
                                        <Form.Select
                                          aria-label='coreforce-shipping-method'
                                          required
                                          name={`shipment_location_mapping[0].coreforce_shipping_method`}
                                          value={values.shipment_location_mapping[0]?.coreforce_shipping_method}
                                          onChange={handleChange}
                                          isInvalid={!!errors.shipment_location_mapping}
                                        >
                                          <option value={''}>Open this select menu</option>
                                          {coreforce_shipping_methods.length &&
                                            coreforce_shipping_methods.map((item, index) => {
                                              return (
                                                <option
                                                  key={'coreforce_shipping_methods' + index}
                                                  value={item.method_id}
                                                >
                                                  {item.method}
                                                </option>
                                              )
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>
                                          This is a required field
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                      <Form.Group className='col-sm-1 align-self-center pt-8'>
                                        <div className='d-flex align-items-center justify-content-center w-100'>
                                          <BSIcon iconClass='bi bi-arrow-left-right' />
                                        </div>
                                      </Form.Group>
                                      <Form.Group as={Col} className='col-sm-5 align-self-center'>
                                        <Form.Label>Ebay Shipping Methods</Form.Label>
                                        <Form.Select
                                          aria-label='coreforce-location-empty-case'
                                          required
                                          name={`shipment_location_mapping[0].ebay_shipping_method`}
                                          value={values.shipment_location_mapping[0]?.ebay_shipping_method}
                                          onChange={handleChange}
                                          isInvalid={!!errors.shipment_location_mapping}
                                        >
                                          <option value={''}>Open this select menu</option>
                                          {ebay_shipping_methods.length &&
                                            ebay_shipping_methods.map((item, index) => {
                                              return (
                                                <option
                                                  key={'ebay_shipping_methods' + index}
                                                  value={item.method_id}
                                                >
                                                  {item.method}
                                                </option>
                                              )
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>
                                          This is a required field
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                     
                                    </Row>
                                  )}
                                  <Row as={Col} className='justify-content-end'>
                                    <Button
                                      variant='info'
                                      style={{width: 'min-content'}}
                                      className='p-2 px-4'
                                      onClick={() => {
                                        let temp: any = [...values.shipment_location_mapping]
                                        temp.push({
                                          ebay_shipping_method: '',
                                          coreforce_shipping_method: '', // now this is only value not in percent
                                           
                                        })
                                        if (values.shipment_location_mapping.length == 0) {
                                          temp.push({
                                            ebay_shipping_method: '',
                                            coreforce_shipping_method: '', // now this is only value not in percent
                                             
                                          })
                                        }
                                        setFormikState((pre) => {
                                          return {
                                            ...pre,
                                            values: {
                                              ...pre.values,
                                              shipment_location_mapping: temp,
                                            },
                                          }
                                        })
                                      }}
                                    >
                                      <BSIcon iconClass='bi bi-plus-lg fs-4 fw-bold ' />
                                    </Button>
                                  </Row>
                                </>
                              </Card.Body>
                            </Card>
                          )} */}
                            {/* location mapping section end  */}
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                    <Row as={Col}>
                      <div className='d-flex justify-content-center mt-2'>
                        <Button type='submit' className='btn btn-sm btn-success'>
                          Save Mapping
                        </Button>
                      </div>
                    </Row>
                  </div>
                </Form>
              </>
            )
          }}
        </Formik>

        {/* Form section end  */}
      </div>
      <TransparentLoader isVisible={syncLoader} />
    </>
  )
}

export default Configuration
