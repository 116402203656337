export const localStorageTokenName = 'access_token'
export const refreshTokenName = 'refresh_token'
export const refreshTokenCount = 'refresh_token_count'
export const showOnceErrorCount = 'show_once_error_count'
export const emailForResetPass = 'email_for_reset_pass'
export const isAdminUser = 'isUserAdmin'
export const loggedInAdminAccessToken = 'loggedInAdminAccessToken'
export const loggedInAdminRefreshToken = 'loggedInAdminRefreshToken'

export const SYNC_STATUS_LIST = ['PENDING', 'SYNCED', 'PROCESSING', 'FAILED']

export const TABLE_ROWS = [10, 15, 20, 25]

export const configurationTooltipMessage = {
  sku_matching:
    'Select the product identifier type used to match products between Coreforce and eBay. This unique identifier ensures accurate syncing of each product from eBay to Coreforce.',
  order_method:
    "Choose an order creation method for Coreforce. The selected method will be applied to all orders by default, reflecting under the 'Order Method' section of Coreforce orders.",
  order_source:
    "Select the Coreforce source code corresponding to eBay orders. This selection will appear in the 'Source' field of Coreforce orders. If not created yet, please create a source for eBay orders within Coreforce.",
  payment_mapping:
    'Ensure accurate mapping of eBay payment methods corresponds to Coreforce payment methods. Incorrect mapping may lead to payment delays or reporting errors.',
  date_time_for_sync:
    'Select a date and time for eBay orders to sync with Coreforce, preferably starting from today. Once set, this date cannot be changed.',

  order_status:
    'Select the status, which will determine the stage at which orders are pulled and synced into Coreforce.',
    
  coreforce_product_id:"Please add a non-inventory item in CoreForce and enter its product ID here. This item will be designated as a shipping charge line item. By doing this, every shipping charge processed for any eBay order will automatically link to this product as line item, and the product's price will represent the shipping charge.",
  buffer_quantity:"Set the minimum amount of a particular item to be maintained in stock. This quantity acts as a safety net, ensuring that there's always enough on hand to meet demand or unexpected fluctuations in supply. Adjust this value to optimize inventory levels and maintain smooth operations."
}
