import React, {FC, useState} from 'react'
import {ACCOUNT_DETAIL} from 'src/interfaces/account.interface'
import CreateConnectionModal from './CreateConnectionModal'

const CoreForceConnectionCard: FC<{data: ACCOUNT_DETAIL}> = ({data}) => {

  const [showConnectForm, setShowConnectForm] = useState(false)

  return (
    <>
      <div className='card p-4 border border-2 cursor-pointer' style={{minHeight: '140px'}}>
        <div className='row align-items-center p-4'>
          <div className='col-3 card p-2'>
            <img
              src='https://lwfiles.mycourse.app/62d452ca3621337b25d05ded-public/b1ca24cf9667cee4184f55f8e1523438.png'
              className='bg-transparent w-100'
            />
          </div>
          <div className='col-9'>
            <p className='h3'>CoreForce connection</p>
          </div>
        </div>
        <div className='row px-4 py-2'>
          <div className='col-9'>
            <p className='h6 text-muted text-truncate '>
              This logo image consists only of simple geometric shapes or text. It does not meet the
              threshold of originality needed for copyright protection
            </p>
          </div>
          <div className={`p-4 border border-1 rounded ${showConnectForm ? '' : 'd-none' }`} >
          </div>
        </div>
        <button className='btn btn-info btn-sm align-self-end' onClick={()=>setShowConnectForm(!showConnectForm)} >
          {'Connect'}
        </button>
      </div>
      <CreateConnectionModal isVisible={showConnectForm} setIsVisible={setShowConnectForm} />
    </>
  )
}

export default CoreForceConnectionCard
