import React from 'react'
import UsersTable from '../modules/users/UsersTable'

const Users = () => {
  return (
    <div>
      <UsersTable/>
    </div>
  )
}

export default Users