import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import useAppToast from 'src/hooks/useAppToast'
import { useAppDispatch } from 'src/redux/sotre.hooks'
import { resetPasswordAfterVerifyEmailAsync } from 'src/redux/actions/authActions'
import { emailForResetPass } from 'src/utils/constants'

const initialValues = {
  password: '',
  confirmPassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  confirmPassword: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const { successToast, errorToast, warningToast } = useAppToast()
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {

      const resetPassEmail = localStorage.getItem(emailForResetPass)

      if(values.password!==values.confirmPassword){
        errorToast("Password not matched")
        setSubmitting(false)
       return
        
      }

      if(!resetToken){
        errorToast("Invalid url")
        setSubmitting(false)
        return 
      }

      if(!resetPassEmail){
        errorToast('Invalid email')
        setSubmitting(false)
        return
      }

      dispatch(resetPasswordAfterVerifyEmailAsync({
        email:resetPassEmail,
        token:resetToken,
        password:values.password,
        confirm_password: values.confirmPassword
      })).then((response)=>{
        console.log('response', response)
        if(response.payload?.status==400){
          warningToast(response.payload?.data?.message)
          setSubmitting(false)
        }

        if(response.payload?.status==200){
          successToast(response.payload?.message)
          setTimeout(() => {
            navigate("/auth/login", {replace:true})
          }, 1000);
        }
        setSubmitting(false)
      })

      // setLoading(true)
      // setHasErrors(undefined)
      // setTimeout(() => {
      //   successToast("Password changed successfully")
      //   navigate("/auth/login", {replace:true})
      //   setLoading(false)
      // }, 1000);
    },
  })

  useEffect(() => {
    if(params?.token){
      // localStorage.setItem(emailForResetPass, 'yumesh@gmail.com')

      setResetToken(params?.token)
    }
    
  }, [])
  

  const [resetToken, setResetToken] = useState('')

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>Please enter your new password here.</div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Sent password reset. Please check your email</div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
        <input
          type='password'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.password && formik.errors.password},
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button disabled={loading||formik.isSubmitting} type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
