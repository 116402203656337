import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {loginAsync} from '../actions/authActions'
import {localStorageTokenName, refreshTokenName} from 'src/utils/constants'
import {createConnectAsync, disconnectAccountAsync, getAccountsDetailsAsync, getActiveAccountsListAsync, isInitialSyncProcessAsync} from '../actions/connectionActions'
import {ACCOUNT_DETAIL, ACTIVE_ACCOUNT_ITEM} from 'src/interfaces/account.interface'

const initialState: {
  loadingAccountDetail: boolean
  isDisconnecting:boolean
  isConnecting:boolean
  initialSyncLoader:boolean
  isBothAccountConnected:boolean
  accounts: ACCOUNT_DETAIL[] | []
  active_accounts : ACTIVE_ACCOUNT_ITEM[] | []
  initialSyncedStatus:boolean
  initialSyncMessage:string
} = {
  loadingAccountDetail: true,
  isDisconnecting:false,
  isConnecting:false,
  initialSyncLoader:false,
  isBothAccountConnected:false,
  initialSyncedStatus:false,
  initialSyncMessage:"",
  accounts: [],
  active_accounts : []
}

const connectionSlice = createSlice({
  name: 'connectionSlice',
  initialState,
  extraReducers: (builder) => {
    // get account details start
    builder.addMatcher(
      isAnyOf(getAccountsDetailsAsync.pending, getActiveAccountsListAsync.pending),
      (state) => {
        state.loadingAccountDetail = true
        console.log('getAccountsDetailsAsync is in pending state ')
      }
    )
    builder.addMatcher(
      isAnyOf(getAccountsDetailsAsync.rejected, getActiveAccountsListAsync.rejected),
      (state) => {
        state.loadingAccountDetail = false
        console.log('getAccountsDetailsAsync is in rejected state ')
      }
    )
    builder.addMatcher(isAnyOf(getAccountsDetailsAsync.fulfilled), (state, action) => {
      state.loadingAccountDetail = false
      console.log('getAccountsDetailsAsync is in fulfilled state ', action.payload)
      if (action.payload?.data?.length) {
        state.accounts = action.payload?.data
      }
    })
    //get account details end

    // get active account list start

    builder.addMatcher(isAnyOf(getActiveAccountsListAsync.fulfilled), (state, action) => {
      console.log('getActiveAccountsListAsync is in fulfilled state ', action.payload)
      if(action.payload?.data?.length){
        state.isBothAccountConnected = action?.payload?.data.length >=2
        ? (!!(action?.payload?.data.find((item) => item.platform == 'COREFORCE')) &&
        !!(action?.payload?.data.find((item) => item.platform == 'EBAY')))
        : false
      }
      state.active_accounts = action.payload.data        
      state.loadingAccountDetail = false
    })
    // get active account list end


    // disconnect account start
    builder.addMatcher(
      isAnyOf(disconnectAccountAsync.pending),
      (state) => {
        state.isDisconnecting = true
        console.log('disconnectAccountAsync is in pending state ')
      }
    )
    builder.addMatcher(
      isAnyOf(disconnectAccountAsync.rejected),
      (state) => {
        state.isDisconnecting = false
        console.log('disconnectAccountAsync is in rejected state ')
      }
    )
    builder.addMatcher(isAnyOf(disconnectAccountAsync.fulfilled), (state, action) => {
      state.isDisconnecting = false
      console.log('disconnectAccountAsync is in fulfilled state ', action.payload)
      
    })
    //disconnect account end

    // connect account start
    builder.addMatcher(
      isAnyOf(createConnectAsync.pending),
      (state) => {
        state.isConnecting = true
        console.log('createConnectAsync is in pending state ')
      }
    )
    builder.addMatcher(
      isAnyOf(createConnectAsync.rejected),
      (state) => {
        state.isConnecting = false
        console.log('createConnectAsync is in rejected state ')
      }
    )
    builder.addMatcher(isAnyOf(createConnectAsync.fulfilled), (state, action) => {
      state.isConnecting = false
      console.log('createConnectAsync is in fulfilled state ', action.payload)
      
    })
    //connect account end

     // initial sync section start
     builder.addMatcher(isAnyOf(isInitialSyncProcessAsync.pending), (state) => {
      console.log('isInitialSyncProcessAsync is in pending state ')
      state.initialSyncLoader = true
    })
    builder.addMatcher(isAnyOf(isInitialSyncProcessAsync.rejected), (state) => {
      console.log('isInitialSyncProcessAsync is in rejected state ')
      state.initialSyncLoader = false
    })
    builder.addMatcher(isAnyOf(isInitialSyncProcessAsync.fulfilled), (state, action) => {
      console.log('isInitialSyncProcessAsync is in fulfilled state', action.payload )
      state.initialSyncedStatus = action.payload?.inital_sync_status
      state.initialSyncMessage = action.payload?.message
      state.initialSyncLoader = false
    })
    // initial sync section en
  },
  reducers: {},
})

export const {} = connectionSlice.actions

export default connectionSlice.reducer
