/**
 * This file contain all environment variables for different deployment environment  
 * These are the types of deployment 
 */

enum ENV_TYPES  { staging =  "staging", development = "development", production = "production"}

type APP_ENV_TYPE =  {
    [key in ENV_TYPES ] : {
        val1:string;
        BASE_URL:string;
    }
}

export const APP_ENV:APP_ENV_TYPE = {
    development:{
        val1: process.env.REACT_APP_DEV_TEST_ENV!,
        BASE_URL: process.env.REACT_APP_API_URL_DEV!,
    },
    staging:{
        val1: process.env.REACT_APP_STAGING_ENV!,
        BASE_URL: process.env.REACT_APP_API_URL!,
    },
    production:{
        val1: process.env.REACT_APP_PRODUCTION_ENV!,
        BASE_URL: process.env.REACT_APP_API_URL!
    }
}