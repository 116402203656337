import React, {FC, useEffect, useState} from 'react'
import DataTable from 'react-data-table-component'
import useAppToast from 'src/hooks/useAppToast'
import {getOrdersListAsync, resyncOrderItem} from 'src/redux/actions/orderlogActions'
import {useAppDispatch, useAppSelector} from 'src/redux/sotre.hooks'
// import StatusComp from '../modules/orders/StatusComp'
import CustomDateRangePicker from 'src/utils/CustomDateRangePicker'
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {SYNC_STATUS_LIST, TABLE_ROWS, localStorageTokenName, loggedInAdminAccessToken, loggedInAdminRefreshToken, refreshTokenName} from 'src/utils/constants'
import moment from 'moment'
import TransparentLoader from 'src/utils/TransparentLoader'
import StatusComp from '../orders/StatusComp'
import AlertModal from 'src/utils/AlertModal'
import { changeUserStatusAsync, deleteUserAsync, getUserInfoAsync, getUserListAsync, loginAsUserAsync } from 'src/redux/actions/authActions'
import { setInUserView, setUserListPaginationData } from 'src/redux/slices/authSlice'
import { useNavigate } from 'react-router-dom'

const commonTooltip = (props) => (
  <Tooltip id='button-tooltip' className='fw-bold custom-tooltip' {...props}>
    {props?.msg}
  </Tooltip>
)

const StatusCell = ({status}) => {
  const activeStatusLabel = <p className='badge badge-success mb-0'>Active</p>
  const inActiveStatusLabel = <p className='badge badge-danger mb-0'>Inactive</p>

  return (
    <div className='align-self-end'>
      <p>{status==true ? activeStatusLabel : inActiveStatusLabel}</p>
    </div>
  )
}

const LoginAsUser: FC<{
  rowData
  onLogin: () => void
  disable: boolean
}> = ({onLogin, rowData, disable}) => {
  const {isDisconnecting} = useAppSelector((state) => state.accounts)
  const [syncing, setSyncing] = useState(false)
  return (
    <OverlayTrigger
      placement='top'
      // delay={{show: 250, hide: 400}}
      // show={true}
      overlay={(props) => commonTooltip({...props, msg: `Login as ${rowData?.first_name}`})}
    >
      <button
        disabled={isDisconnecting || disable}
        className='btn btn-sm btn-info me-2 '
        onClick={() => onLogin()}
      >
        <i className='bi bi-box-arrow-in-right'></i>
      </button>
    </OverlayTrigger>
  )
}

const BlockThisUser: FC<{
  rowData
  onBlock: () => void
  onUnblock: () => void
}> = ({onBlock, onUnblock, rowData}) => {
  const {isDisconnecting} = useAppSelector((state) => state.accounts)
  const [syncing, setSyncing] = useState(false)
  return (
    <OverlayTrigger
      placement='top'
      overlay={(props) =>
        commonTooltip({
          ...props,
          msg:
            rowData?.is_active
              ? `Block ${rowData?.first_name}`
              : `Unblock ${rowData?.first_name}`,
        })
      }
    >
      {!rowData?.is_active? (
        <button className='btn btn-sm btn-danger' onClick={onUnblock}>
          <i className='bi bi-person-fill-slash'></i>
        </button>
      ) : (
        <button className='btn btn-sm btn-success' onClick={onBlock}>
          <i className='bi bi-person-fill'></i>
        </button>
      )}
    </OverlayTrigger>
  )
}

const DeleteThisUser: FC<{
  rowData
  disable:boolean
  onDelete: () => void
}> = ({onDelete, disable, rowData}) => {
   
  return (
    <OverlayTrigger
      placement='top'
      overlay={(props) =>
        commonTooltip({
          ...props,
          msg: `Delete ${rowData?.first_name}`,
        })
      }
    >
        <button disabled={disable} className='btn btn-sm btn-danger ms-2' onClick={onDelete}>
          <i className='bi bi-trash'></i>
        </button>
    </OverlayTrigger>
  )
}

type USER_DATA = {
  id:string
  first_name: string
  last_name: string
  email: string
  is_active:boolean
}

const UsersTable: FC = () => {
  const dispatch = useAppDispatch()
 const { userListLoader, usersCount, usersList, userListPaginationData, loginAsUserLoader, updateProfileDataLoader  } = useAppSelector(state=>state.auth)
  const {successToast, errorToast} = useAppToast()
  const navigate = useNavigate()
  const [confirmation, setConfirmation] = useState(false)
  const [confirmChangeStatus, setConfirmChangeStatus] = useState(false)
  const [userData, setUserData] = useState< null | USER_DATA >(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isBlocking, setIsBlocking] = useState(false)
  const [isUnblocking, setIsUnblocking] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [resetFilters, setResetFilters] = useState(false)
  
  const loginAsUser = () => {
    if(userData?.id){
      dispatch(loginAsUserAsync({userId:userData?.id}))
      .then((response)=>{
        console.log('response as normal user login', response.payload)
        if(response.payload?.access){
          const currentLoggedInAdminAccessToken = localStorage.getItem(localStorageTokenName)
          const currentLoggedInAdminRefreshToken = localStorage.getItem(refreshTokenName)
          localStorage.setItem(localStorageTokenName, response.payload.access)
          localStorage.setItem(refreshTokenName, response.payload.refresh)
          localStorage.setItem(loggedInAdminAccessToken, currentLoggedInAdminAccessToken! )
          localStorage.setItem(loggedInAdminRefreshToken, currentLoggedInAdminRefreshToken! )
          setConfirmation(false)
          dispatch(getUserInfoAsync())
          .then((res)=>{
            setUserData(null)
            navigate('/dashboard')
            // dispatch(setInUserView(true))
            window.location.reload()
          })
        }
        // after successful login as normal user
        // logged in admin ka access and refresh token ko shift in other key
      })
    }
  }

  const blockThisUser = (data: USER_DATA, userIndex: number) => {
    setIsLoading(true)
    dispatch(changeUserStatusAsync({userId:data.id, changeThisStatus:false}))
    .then((response)=>{
      console.log('response', response)
      if(!response.payload?.is_active){
        successToast(response.payload.message)
        dispatch(getUserListAsync({...userListPaginationData}))
        setIsLoading(false)
        setIsBlocking(false)
      }
    })    
  }

  const unBlockThisUser = (data: USER_DATA, userIndex: number) => {
    setIsLoading(true)
    dispatch(changeUserStatusAsync({userId:data.id, changeThisStatus:true}))
    .then((response)=>{
      console.log('response', response)
      if(response.payload?.is_active){
        successToast(response.payload.message)
        dispatch(getUserListAsync({...userListPaginationData}))
        setIsLoading(false)
        setIsUnblocking(false)
      }
    })
    
  }

  const deleteThisUser = (data:USER_DATA, userIndex:number) => {
      setIsLoading(true)
      dispatch(deleteUserAsync({userId:data.id}))
      .then((response)=>{
        successToast("User deleted successfully")
        dispatch(getUserListAsync({...userListPaginationData}))
        setIsLoading(false)
        setIsDeleting(false)
      })
  }

  const columns = [
    {
      name: 'First name',
      sortable:true,
      selector: (row) => row.first_name,
      sortField: 'first_name',
    },
    {
      name: 'Last Name',
      sortable:true,
      selector: (row) => row.last_name,
      sortField: 'last_name',
      width: '220px',
    },
    {
      name: 'Email',
      sortable: true,
      sortField: 'email',
      cell: (row) => (
        <OverlayTrigger
      placement='top'
      overlay={(props) => commonTooltip({...props, msg: row.email})}
    >
      <p>{row.email}</p>
    </OverlayTrigger>
      ),
    },
    {
      name: 'Status',
      center: true,
      cell: (row, index) => <StatusCell status={row.is_active} />,
    },
    {
      name: 'Action',
      sortField: 'action',
      cell: (row, index, column, id) => {
        return (
          <div className='d-flex '>
            <LoginAsUser
              rowData={row}
              disable={row?.is_active == false}
              onLogin={() => {
                setUserData(row)
                setConfirmation(true)
              }}
            />

            <BlockThisUser
              onBlock={() => {
                setUserData(row)
                setIsBlocking(true)
              }}
              onUnblock={() => {
                setUserData(row)
                setIsUnblocking(true)
              }}
              rowData={row}
            />

            <DeleteThisUser
            key={'deletebtn'+row?.id}
            rowData={row}
            disable={false}
            onDelete={()=>{
              setUserData(row)
              setIsDeleting(true)
            }}
            />
          </div>
        )
      },
    },
  ]

  const handleSort = (ev, direction ) => {
    if(direction=='asc'){
      dispatch(setUserListPaginationData({ ordering:ev.sortField}))
    }

    if(direction=='desc'){
      dispatch(setUserListPaginationData({ ordering: "-"+ev.sortField}))
    }
  }

  useEffect(() => {
    dispatch(getUserListAsync({...userListPaginationData}))
  }, [userListPaginationData])

  return (
    <>
      <div>
        {/* table header section start */}
        <div className='navbar my-3 py-2'>
        <div> 
            <form className='form-inline ms-2'>
              <input
                className='form-control mr-sm-2 border border-secondary'
                type='search'
                value={userListPaginationData.search}
                placeholder='Search User'
                aria-label='Search'
                onChange={(ev)=>{
                  dispatch(setUserListPaginationData({search: ev.target.value, page:1 }))

                }}
               
              />
            </form>
          </div>
          <div className='d-inline-flex'>
              
              <div className='form-group me-2'>
                <select
                  // style={{paddingTop:"8px", paddingBottom:"8px"}}
                  className='form-select border border-secondary'
                  id='exampleFormControlSelect1'
                  value={userListPaginationData.is_active}
                  onChange={(ev) => {
                    dispatch(setUserListPaginationData({is_active:ev.target.value }))
                  }}
                >
                  <option value=''>All</option>
                  <option value='true'>Active</option>
                  <option value='false'>Inactive</option>
                </select>
              </div> 
            <button
              className='btn btn-sm btn-info ms-2'
              onClick={() =>{
                dispatch(setUserListPaginationData({
                  page: 1,
                  page_limit: 10,
                  search: '',
                  ordering: '',
                  is_active:''
                }))
                setResetFilters(!resetFilters)
              }}
            >
              Reset Filters
            </button>
          </div>
         
        </div>
        {/* table header section end */}

        <div className='border'>
          <DataTable
           key={"usertable"+ resetFilters}
            title='Users List'
            responsive
            fixedHeader
            columns={columns}
            data={usersList}
            progressPending={userListLoader}
            paginationServer
            paginationRowsPerPageOptions={TABLE_ROWS}
            paginationTotalRows={usersCount}
            onSort={handleSort}
            pagination
            className='align-items-center'
            highlightOnHover
            sortServer
            onChangePage={(page, totalRows)=>{
              dispatch(setUserListPaginationData({ page: page}))
            }}
            onChangeRowsPerPage={(currentPage) => {
              console.log('currentPage', currentPage)
              dispatch(setUserListPaginationData({ page_limit: currentPage}))
            }}
            customStyles={{
              header: {
                style: {fontSize: 16, fontWeight: 'bold',},
              },
              headCells: {
                style: {justifyContent: 'center', textAlign: 'center', fontSize: 16},
              },
              cells: {
                style: {
                  alignItems: 'center',
                  textAlign: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                },
              },
              table: {
                style: {
                  height: '60vh',
                },
              },
            }}
          />
        </div>
      </div>
      <TransparentLoader isVisible={isLoading||updateProfileDataLoader||loginAsUserLoader} />
      <AlertModal
        key={'confirm login modal'}
        title={`You are going to login as ${userData?.first_name}`}
        subtitle={`Upon logging in, you will be authorized as ${userData?.first_name}`}
        setIsVisible={() => setConfirmation(true)}
        successDisabled={loginAsUserLoader}
        cancelBtnTitle='Cancel'
        successBtnTitle='Yes login'
        isVisible={confirmation}
        isTermsVisible={false}
        onCancel={() => {
          setConfirmation(false)
          setUserData(null)
        }}
        onSuccess={() => {
          loginAsUser()
        }}
      />

      <AlertModal
        key={'confirm block modal'}
        title={`Your action to block ${userData?.first_name}`}
        subtitle={`After blocking ${userData?.first_name} they will be unable to access the portal`}
        setIsVisible={() => setIsBlocking(true)}
        successDisabled={isLoading}
        cancelBtnTitle='Cancel'
        successBtnTitle='Yes Block'
        isVisible={isBlocking}
        isTermsVisible={false}
        onCancel={() => {
          setIsBlocking(false)
          setUserData(null)
        }}
        onSuccess={() => {
          if(userData){
            blockThisUser(userData,0)
          }
        }}
      />

      <AlertModal
        key={'confirm unblock modal'}
        title={`You are going to unblock ${userData?.first_name}`}
        subtitle={`Once unblocked, they will regain access to the portal`}
        setIsVisible={() => setIsUnblocking(true)}
        successDisabled={isLoading}
        cancelBtnTitle='Cancel'
        successBtnTitle='Yes Unblock'
        variant='success'
        isVisible={isUnblocking}
        isTermsVisible={false}
        onCancel={() => {
          setIsUnblocking(false)
          setUserData(null)
        }}
        onSuccess={() => {
          if(userData){
            unBlockThisUser(userData,0)
          }
        }}
      />

      <AlertModal
        key={'confirm delete modal'}
        title={`Your action to delete ${userData?.first_name}`}
        subtitle={`After deleting ${userData?.first_name} they will be unable to access the portal`}
        setIsVisible={() => setIsDeleting(true)}
        successDisabled={isLoading}
        cancelBtnTitle='Cancel'
        successBtnTitle='Yes Delete'
        isVisible={isDeleting}
        isTermsVisible={false}
        onCancel={() => {
          setIsDeleting(false)
          setUserData(null)
        }}
        onSuccess={() => {
          if(userData){
            deleteThisUser(userData,0)
          }
        }}
      />
    </>
  )
}

export default UsersTable
