import {lazy, FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate, useLocation, useNavigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import Configuration from '../pages/Configuration'
import Logs from '../pages/Logs'
import Connections from '../pages/Connections'
import {MasterLayout} from 'src/_master-config/layout/MasterLayout'
import {WithChildren} from 'src/_master-config/helpers'
import {getCSSVariableValue} from 'src/_master-config/assets/ts/_utils'
import Orders from '../pages/Orders'
import { refreshTokenCount, showOnceErrorCount } from 'src/utils/constants'
import Users from '../pages/Users'
import { useAppSelector } from 'src/redux/sotre.hooks'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))

  const location = useLocation()
  const { userDetail, inUserView } = useAppSelector(state=>state.auth)
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.setItem(showOnceErrorCount,'0')
    localStorage.setItem(refreshTokenCount ,'0')

    console.log('location', location)
    if(!userDetail.is_admin_user && location.pathname == '/user-list' ){
      navigate('/dashboard')
    }

    if(userDetail.is_admin_user && (location.pathname == '/dashboard'||location.pathname == '/configuration'||location.pathname == '/connections') ){
      navigate('/user-list')
    }
    
  }, [location, userDetail])
  


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route path='/configuration' element={<Configuration />} />
        <Route path='/logs' element={<Logs />} />
        <Route path='/connections' element={<Connections />} />
        <Route path='/orders' element={<Orders />} />
        <Route path='/user-list' element={<Users />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
