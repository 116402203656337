import React from 'react'
import { KTIcon } from 'src/_master-config/helpers'

const ListEmptyDetail = () => {
  return (
    <div className='card p-8' >
        <div className='card-detail' >
            <div className='d-flex' >
                <KTIcon  iconName='abstract-40' className="svg-icon-muted svg-icon-2hx fs-1"  />
            <h3 className='card-title ms-4' >No items found</h3>
            </div>
        </div>
    </div>
  )
}

export default ListEmptyDetail