import React, {FC} from 'react'
import {Modal} from 'react-bootstrap'

type AlertModalPropsType = {
  title: string
  subtitle: string
  cancelBtnTitle: string
  successBtnTitle: string
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  onSuccess: () => void
  onCancel: () => void
  variant?:'danger'|'warning'|'success'
  successDisabled?:boolean
  isTermsVisible?:boolean
}

const AlertModal: FC<AlertModalPropsType> = ({
  isVisible,
  setIsVisible,
  subtitle,
  title,
  cancelBtnTitle = '',
  successBtnTitle = '',
  onCancel,
  onSuccess,
  successDisabled=false,
  isTermsVisible=true,
  variant='danger'

}) => {
  return (
    <Modal show={isVisible} centered  contentClassName={`bg-light-${variant}`} >
      <Modal.Body>
        <div className={`alert alert-dismissible bg-light-${variant} d-flex flex-center flex-column py-10 px-10 px-lg-20`}>
          <div className='text-center'>
            <h5 className='fw-bold fs-1 mb-5'>Alert</h5>

            <div className={`separator separator-dashed border-${variant} opacity-25 mb-5`}></div>

            <div className='mb-9'>
             <h4>{title}</h4> <span>{subtitle}</span>
              { isTermsVisible ?
                <>
                  <a href='#' className='fw-bolder me-1'>
                  Terms and Conditions
                </a>{' '}
                for more info.
                </> : null
              }
            </div>
            <div className='d-flex flex-center flex-wrap'>
              <button
                onClick={onCancel}
                className={`btn btn-outline btn-sm btn-outline-${variant} btn-active-${variant} m-2`}>
                {cancelBtnTitle}
              </button>
              <button disabled={successDisabled} onClick={onSuccess} className='btn btn-outline btn-outline-success btn-sm m-2'>{successBtnTitle}</button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AlertModal
