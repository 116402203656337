import React, {FC, useState} from 'react'
import useAppToast from 'src/hooks/useAppToast'
import {ACCOUNT_DETAIL} from 'src/interfaces/account.interface'

const EbayConnectionCard: FC<{data: ACCOUNT_DETAIL}> = ({data}) => {
  const [disable, setDisable] = useState(false)
  const { warningToast } = useAppToast()

  return (
    <>
      <div className='card p-4 border ' style={{minHeight: '140px'}}>
        <div className='row align-items-center p-4'>
          <div className='col-3 card '>
            <img
              src='https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/EBay_logo.png/640px-EBay_logo.png'
              className='bg-transparent w-100'
            />
          </div>
          <div className='col-9'>
            <p className='h3'>Ebay connection</p>
          </div>
        </div>
        <div className='row px-4 py-2'>
          <div className='col-9'>
            <p className='h6 text-muted text-truncate '>
              This logo image consists only of simple geometric shapes or text. It does not meet the
              threshold of originality needed for copyright protection
            </p>
          </div>
        </div>
        <button
          className='btn btn-info btn-sm align-self-end'
          disabled={disable}
          onClick={() => {
            setDisable(true)
            if (data.is_connected == '0') {
              window.location.href = data.url
            }else{
              warningToast("Url not found")
              setDisable(false)
            }
          }}
        >
          Connect
        </button>
      </div>
    </>
  )
}

export default EbayConnectionCard
