import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {AuthInit} from './modules/auth'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'
import { LayoutProvider, LayoutSplashScreen } from 'src/_master-config/layout/core';
import { I18nProvider } from 'src/_master-config/i18n/i18nProvider';
import { ThemeModeProvider } from 'src/_master-config/partials';
import { MasterInit } from 'src/_master-config/layout/MasterInit';

const App = () => {
  return (
    <>
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
    <ToastContainer />
    </>
  )
}

export {App}
