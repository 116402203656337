import { createAsyncThunk } from "@reduxjs/toolkit"
import AxiosClient from "../AxiosClient"

export const getProductLogsAsync = createAsyncThunk(
    'connection/getProductLogsAsync',
    async function (params: any, toolkit) {
      return await AxiosClient('GET', '/products', [], toolkit, params)
    }
  )

  export const getProductsCountAsync = createAsyncThunk(
    'connection/getProductsCountAsync',
    async function (params: any, toolkit) {
      return await AxiosClient('GET', '/products-count', [], toolkit, params)
    }
  )