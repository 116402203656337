import React from 'react'

const Loading = () => {
  return (
    <div style={{minHeight:"80vh"}} className='d-flex justify-content-center align-items-center'>
      <div className='spinner-border' role='status'>
        <span className='sr-only'>Loading...</span>
      </div>
    </div>
  )
}

export default Loading
