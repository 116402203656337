import React, {FC, useEffect, useState} from 'react'
import DataTable from 'react-data-table-component'
import useAppToast from 'src/hooks/useAppToast'
import {getOrdersListAsync, resyncOrderItem} from 'src/redux/actions/orderlogActions'
import {useAppDispatch, useAppSelector} from 'src/redux/sotre.hooks'
import StatusComp from '../modules/orders/StatusComp'
import CustomDateRangePicker from 'src/utils/CustomDateRangePicker'
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import { SYNC_STATUS_LIST, TABLE_ROWS } from 'src/utils/constants'
import moment from 'moment'
import TransparentLoader from 'src/utils/TransparentLoader'
import { getShipmentListAsync } from 'src/redux/actions/shipmentlogActions'
import { getDashboardStatCounts, getDashboardStatCountsForShipment } from 'src/redux/actions/commonActions'
import { setShipmentlogPaginationData } from 'src/redux/slices/shipmentlogSlice'

const resyncTooltip = (props) => (
  <Tooltip id="button-tooltip" className='fw-bold custom-tooltip'  {...props}>
      Resync
  </Tooltip>
);

const ResyncButton: FC<{
  rowData
  onResync: () => void
}> = ({onResync, rowData}) => {
  const {isDisconnecting} = useAppSelector((state) => state.accounts)
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      // show={true}
      overlay={(props)=> resyncTooltip(props,)}
    >
    <button
      disabled={isDisconnecting}
      className='btn btn-sm btn-info p-2'
      onClick={() => onResync()}
    >
      <i className='bi bi-arrow-repeat pe-0'></i>
    </button>
    </OverlayTrigger>
  )
}

const Shipments: FC = () => {
  const dispatch = useAppDispatch()
  const {  resyncLoader } = useAppSelector((state) => state.orderlog)
  const { dashboardActiveTab} = useAppSelector((state) => state.common)
  const { shipmentLogLoading, shipmentlog, totalShipmentLogCount, shipmentPaginationData  } = useAppSelector((state) => state.shipment)
  const [opendaterangepicker, setOpendaterangepicker] = useState(false)
  const {successToast, errorToast} = useAppToast()
  const [resetFilters, setResetFilters] = useState(false)

  // const [paginationData, setPaginationData] = useState({
  //   page_num: 1,
  //   page_limit: 10,
  //   from_date: '',
  //   to_date: '',
  //   q: '',
  //   sync_status: '',
  //   sort_direction: '', // DES for descending
  //   sort_by:''
  // })
  const resyncOrderLogItem = (itemData) => {
    dispatch(resyncOrderItem({
      record_id : itemData?.coreforce_order_id+'',
      shipping_id : itemData?.api_shipment_id+'',
      platform : 'SHIPMENT'
     }))
     .then((response)=>{
     
      if(response?.payload?.status==200){
        successToast(response.payload?.data?.message)
        dispatch(getShipmentListAsync({...shipmentPaginationData}))
        dispatch(getDashboardStatCountsForShipment({...shipmentPaginationData}))


        return
      }else{
        errorToast(response.payload?.data?.message)
        dispatch(getShipmentListAsync({...shipmentPaginationData}))
        dispatch(getDashboardStatCountsForShipment({...shipmentPaginationData}))


        return 
      }
     })
     .catch((error)=>{
      console.log('error in resync', error)
     })
  }


  const columns = [
    
    {
      name: 'Tracking Number #',
      selector: (row) => row.tracking_id,
      sortable: true,
      sortField: 'tracking_id',
      width:"220px"
    },
    {
      name: 'Source Shipment #',
      selector: (row) => row.api_shipment_id,
      sortable: true,
      sortField: 'api_shipment_id',
      width:"200px"
       
    },
    {
      name: 'Source',
      selector: (row) => row.source_shipment,
      // sortable: true,
      sortField: 'source_shipment',
      width:"200px"
       
    },
    {
      name: 'Destination Shipment #',
      selector:(row)=>row.destination_ref,
      sortable: true,
      sortField: 'destination_ref',
      width:"240px"
       
    },
    {
      name: 'Coreforce Order #',
      selector: (row) => row.coreforce_order_id,
      sortable: true,
      sortField: 'coreforce_order_id',
      width:"200px"

    },
    {
      name: 'eBay Order #',
      selector: (row) => row.ebay_order_id,
      sortable: true,
      sortField: 'ebay_order_id',
      width:"200px"

    },
    {
      name: 'Status',
      sortable:true,
      sortField:'sync_status',
      selector: (row) => row.sync_status,
      cell: (row, index, column, id) => {
        return <StatusComp status={row.sync_status} message={row?.message} />
      },
    },
    {
      name: 'Synced / Updated At',
      selector: (row) => row.synced_at,
      sortable: true,
      sortField: 'synced_at',
      width:"220px",
      cell: (row, index, column, id) => {
        return <p>{ row?.synced_at ? moment.utc(row.synced_at).format("DD-MMM-YY, hh:mm A [(UTC)]") : '' }</p>
      },
    },   
    // {
    //   name: 'Date of Shipment',
    //   selector: (row) => row.date_of_shipment,
    //   sortable: true,
    //   sortField: 'date_of_shipment',
    //   width:"220px",
    //   cell: (row, index, column, id) => {
    //     return <p>{ row?.date_of_shipment ? moment.utc(row.date_of_shipment).format("DD-MMM-YY, hh:mm A") : '' }</p>
    //   },
    // },   
    {
      name: 'Shipment Pulled At',
      selector: (row) => row.last_pulled_at,
      sortable: true,
      sortField: 'last_pulled_at',
      width:"220px",
      cell: (row, index, column, id) => {
        return <p>{ row?.last_pulled_at ? moment.utc(row.last_pulled_at).format("DD-MMM-YY, hh:mm A [(UTC)]") : '' }</p>
      },
    },
    {
      name: 'Action',
      sortField: 'action',
      width:"120px",
      cell: (row, index, column, id) => {
        return row.sync_status == 'FAILED' ? (
          <ResyncButton
            rowData={row}
            onResync={() => {
              resyncOrderLogItem(row)
            }}
          />
        ) : null
      },
    },
  ]

  const handleSort = (ev, direction )=>{
    console.log('ev', ev)
    dispatch(setShipmentlogPaginationData({sort_by: ev.sortField, sort_direction:(direction+"").toString().toUpperCase() }))
     
  }
 

  useEffect(() => {
    if(dashboardActiveTab==3){
      dispatch(getDashboardStatCountsForShipment({...shipmentPaginationData}))
      dispatch(getShipmentListAsync({...shipmentPaginationData}))
    }
  }, [shipmentPaginationData, dashboardActiveTab])

  return (
    <>
      <div>
        {/* table header section start */}
        <div className='navbar my-3 py-2'>
        <div> 
            <form className='form-inline ms-2'>
              <input
                className='form-control mr-sm-2 border border-secondary'
                type='search'
                value={shipmentPaginationData.q}
                placeholder='Search Shipment'
                aria-label='Search'
                onChange={(ev) => {
                  dispatch(setShipmentlogPaginationData({q: ev.target.value, page_num:1 }))
                }}
              />
            </form>
          </div>
          <div className='d-inline-flex'>
              <div className='form-group me-2'>
                <button
                  style={{paddingTop:"8px", paddingBottom:"8px"}}
                  className='form-control border border-secondary'
                  onClick={() => {
                    setOpendaterangepicker(!opendaterangepicker)
                  }}
                >
                  {shipmentPaginationData.from_date || shipmentPaginationData.to_date
                    ? moment(shipmentPaginationData.from_date).format('DD/MMM/YY') +
                      ' - ' +
                      moment(shipmentPaginationData.to_date).format('DD/MMM/YY')
                    : 'Pick Date Range'}
                </button>
                <CustomDateRangePicker
                  isVisible={opendaterangepicker}
                  handleClose={() => setOpendaterangepicker(false)}
                  onPickRange={(range) => {
                    dispatch(setShipmentlogPaginationData({from_date: range.startDate, to_date: range.endDate}))
                  }}
                />
              </div>
              <div className='form-group me-2'>
                <select
                  style={{paddingTop:"8px", paddingBottom:"8px"}}
                  className='form-select border border-secondary'
                  id='exampleFormControlSelect1'
                  value={shipmentPaginationData.sync_status}
                  onChange={(ev) => {
                    dispatch(setShipmentlogPaginationData({sync_status: ev.target.value}))
                  }}
                >
                  <option value=''>ALL</option>
                  {SYNC_STATUS_LIST.map((item) => {
                    return <option value={item}>{item}</option>
                  })}
                </select>
              </div> 
            <button
             className='btn btn-sm btn-info ms-2'
              onClick={() =>{
                dispatch(setShipmentlogPaginationData({
                  page_num: 1,
                  page_limit: 10,
                  from_date: '',
                  to_date: '',
                  q: '',
                  sync_status: '',
                  sort_direction: '',
                  sort_by: '', // DES for descending
                }))
                setResetFilters(!resetFilters)
              }}
            >
              Reset Filters
            </button>
          </div>
        </div>
        {/* table header section end */}

        <div className='border'>
          <DataTable
            key={'shipmenttable'+resetFilters}
            responsive
            columns={columns}
            data={shipmentlog}
            progressPending={shipmentLogLoading}
            paginationRowsPerPageOptions={TABLE_ROWS}
            paginationTotalRows={totalShipmentLogCount}
            onSort={handleSort}
            pagination
            paginationServer
            className='align-items-center'
            highlightOnHover
            sortServer
            onChangePage={(pageNum, totalRows) => {
              dispatch(setShipmentlogPaginationData({page_num: pageNum}))

              // setPaginationData((pre) => {
              //   return {...pre, page_num: pageNum}
              // })
            }}
            onChangeRowsPerPage={(currentPage)=>{
              dispatch(setShipmentlogPaginationData({page_limit: currentPage}))

              // setPaginationData((pre) => {
              //   return {...pre, page_limit: currentPage }
              // })
            }}
           
            customStyles={{
              header: {
                style: {fontSize: 16, fontWeight: 'bold', borderBottom: '5px solid black'},
              },
              headCells: {
                style: {justifyContent: 'center', textAlign: 'center', fontSize: 16},
              },
              cells: {
                style: {alignItems: 'center', textAlign: 'center', justifyContent: 'center'},
              },
            }}
          />
        </div>
      </div>
      <TransparentLoader isVisible={resyncLoader} />
    </>
  )
}

export default Shipments
