import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'

export const getInventoryListAsync = createAsyncThunk(
  'connection/getInventoryListAsync',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', '/get-inventory-logs', [], toolkit, params)
  }
)

