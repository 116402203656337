import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'

export const getAccountsDetailsAsync = createAsyncThunk(
  'connection/getAccountsDetailsAsync',
  async function (params: any, toolkit) {

    let location =''
    if(window.location.port){
      location = window.location.protocol + "//" + window.location.hostname + ":" + + window.location.port + "/%23/" + 'connections'

    }else{
      location = window.location.protocol + "//" + window.location.hostname + "/%23/" + 'connections'

    }
    console.log('location', location)

    return await AxiosClient('GET', `/account-details?redirectUrl=${location}`, [], toolkit, params)
  }
)

export const getActiveAccountsListAsync = createAsyncThunk(
  'connection/getActiveAccountsListAsync',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', '/active-accounts', [], toolkit, params)
  }
)

export const disconnectAccountAsync = createAsyncThunk(
  'connection/disconnectAccountAsync',
  async function (params: {disconnectId: number}, toolkit) {
    return await AxiosClient(
      'DELETE',
      `/disconnect-account/${params.disconnectId}`,
      [],
      toolkit,
      []
    )
  }
)

export const createConnectAsync = createAsyncThunk(
  'connection/createConnectAsync',
  async function (payload: FormData, toolkit) {
    return await AxiosClient('POST', '/create-connection', payload, toolkit, [])
  }
)

export const isInitialSyncProcessAsync = createAsyncThunk(
  'connection/isInitialSyncProcessAsync',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', '/initial-sync-status', [], toolkit, params)
  }
)
