import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import { getInventoryListAsync } from '../actions/invetoryActions'

type INVENTORY_LOG_ITEM = {
id:number
message:null|string
product_id:string
source:string
sync_status:string
synced_at:string
upc_code: string
pulled_at?:string
}

type PAGINATION_DATA_FOR_SET = {
  page_num?: number,
  page_limit?: number,
  from_date?: string
  to_date?: string
  q?: string
  sync_status?: string
  sort_direction?: string // DES for descending
  sort_by?:string  }

type PAGINATION_DATA = {
  page_num: number,
  page_limit: number,
  from_date: string
  to_date: string
  q: string
  sync_status: string
  sort_direction: string // DES for descending
  sort_by:string  
}

type INITIAL_STATE = {
  loadingInventory: boolean,
  inventories: INVENTORY_LOG_ITEM[]|[],
  totalInventories:number
  paginationData : PAGINATION_DATA
}

const initialState:INITIAL_STATE = {
  loadingInventory: false,
  inventories:[],
  totalInventories:0,
  paginationData :{
    page_num: 1,
    page_limit: 10,
    from_date: '',
    to_date: '',
    q: '',
    sync_status: '',
    sort_direction: '', // DES for descending
    sort_by:''
  }
}

// const initialState = {
//   loadingInventory: false,
//   inventories:[],
//   totalInventories:0
// }

const inventorySlice = createSlice({
  name: 'inventorySlice',
  initialState,
  extraReducers: (builder) => {
    // get order list start
    builder.addMatcher(isAnyOf(getInventoryListAsync.pending), (state) => {
      state.loadingInventory = true
      console.log('getInventoryListAsync is in pending state ')
    })
    builder.addMatcher(isAnyOf(getInventoryListAsync.rejected), (state) => {
      state.loadingInventory = false
      console.log('getInventoryListAsync is in rejected state ')
    })
    builder.addMatcher(isAnyOf(getInventoryListAsync.fulfilled), (state, action) => {
      console.log('getInventoryListAsync is in fulfilled state ', action.payload)
      state.inventories = action.payload.Response
      state.totalInventories = action.payload.all_data
      state.loadingInventory = false
    })
    //get order list end

    
  },
  reducers: {
    setInventoryLogPaginationData : (state, action : { payload : PAGINATION_DATA_FOR_SET, type: string   }  )=>{
      state.paginationData = {...state.paginationData, ...action.payload }
    }
  },
})

export const { setInventoryLogPaginationData } = inventorySlice.actions

export default inventorySlice.reducer
