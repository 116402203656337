import React from 'react'

const Logs = () => {
  return (
    <div className='p-4'>
      <div className='col-6 mt-4'>
        <div className='mb-10'>
          <div className='form-check form-switch form-check-custom form-check-solid p-0'>
            <label className='form-label ms-0' htmlFor='flexSwitchDefault'>
              Enable Order Pull from switch
            </label>
            <input
              className='form-check-input ms-2 h-20px w-30px'
              type='checkbox'
              value=''
              id='flexSwitchDefault'
            />
          </div>
        </div>
        <div className='mb-10'>
          <label className='form-label'>Select Go Live Date/Time</label>
          <input type='datetime-local' className='form-control' placeholder='name@example.com' />
        </div>
      </div>
    </div>
  )
}

export default Logs
