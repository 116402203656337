import {FC, useRef, useState} from 'react'
import { Button, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SYNC_STATUS } from 'src/interfaces/common.interface'
import { useAppSelector } from 'src/redux/sotre.hooks';

const renderTooltip = (props, msg) => (
  <Tooltip id="button-tooltip" className='fw-bold custom-tooltip'  {...props}>
      {msg}
  </Tooltip>
);

function Example({msg}) {
  

  return (
    <>
     <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={(props)=> renderTooltip(props, msg)}
    >
      <button className='btn btn-sm p-2' >
          <i className="bi bi-info-circle text-white fw-bold"></i>
      </button>
    </OverlayTrigger>
      
   
    </>
  );
}

const StatusComp: FC<{status: SYNC_STATUS, message?:string}> = ({status, message=''}) => {
  if (status == 'FAILED') {
    return (
      <>
      <div style={{width:"100px"}} className='d-inline-flex bg-danger rounded' >
        <span className={`badge badge-danger p-3 cursor-pointer text-center justify-content-center `} >
          Failed 
        </span>
        <Example msg={message} />
      </div>
      </>
    )
  } else if (status == 'PENDING') {
    return (
      <span
      style={{width:"100px"}}
        className={`badge badge-warning p-3 cursor-pointer text-center justify-content-center `}
      >
        Pending
      </span>
    )
  } else if (status == 'PROCESSING') {
    return (
      <span
        style={{width:"100px"}}
        className={`badge badge-info p-3 cursor-pointer text-center justify-content-center `}
      >
        Processing
      </span>
    )
  } else if (status == 'SYNCED') {
    return (
      <span
        style={{width:"100px"}}
        className={`badge badge-success p-3 cursor-pointer text-center justify-content-center `}
      >
        Synced
      </span>
    )
  }

  return null
}

export default StatusComp
