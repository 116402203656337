import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {getShipmentListAsync} from '../actions/shipmentlogActions'

type SHIPMENT_LOG_ITEM = {
  destination_ref: string
  coreforce_order_id: string
  sync_status: string
  date_of_shipment: string
  synced_at: string
  tracking_id: string
  shipment_pull_status: string
  source_shipment: string
  last_pulled_at?: string
  api_shipment_id?:number|string
}

type PAGINATION_DATA_FOR_SET = {
  page_num?: number
  page_limit?: number
  from_date?: string
  to_date?: string
  q?: string
  sync_status?: string
  sort_direction?: string // DES for descending
  sort_by?: string
}

type PAGINATION_DATA = {
  page_num: number
  page_limit: number
  from_date: string
  to_date: string
  q: string
  sync_status: string
  sort_direction: string // DES for descending
  sort_by: string
}

type INITIAL_STATE = {
  shipmentLogLoading: boolean
  shipmentlog: SHIPMENT_LOG_ITEM[] | []
  totalShipmentLogCount: number
  shipmentPaginationData: PAGINATION_DATA
}

const initialState: INITIAL_STATE = {
  shipmentLogLoading: false,
  shipmentlog: [],
  totalShipmentLogCount: 0,
  shipmentPaginationData: {
    page_num: 1,
    page_limit: 10,
    from_date: '',
    to_date: '',
    q: '',
    sync_status: '',
    sort_direction: '', // DES for descending
    sort_by: '',
  },
}

const shipmentlogSlice = createSlice({
  name: 'shipmentlogSlice',
  initialState,
  extraReducers: (builder) => {
    // get order list start
    builder.addMatcher(isAnyOf(getShipmentListAsync.pending), (state) => {
      state.shipmentLogLoading = true
      console.log('getShipmentListAsync is in pending state ')
    })
    builder.addMatcher(isAnyOf(getShipmentListAsync.rejected), (state) => {
      state.shipmentLogLoading = false
      console.log('getShipmentListAsync is in rejected state ')
    })
    builder.addMatcher(isAnyOf(getShipmentListAsync.fulfilled), (state, action) => {
      if (action.payload?.data) {
        state.totalShipmentLogCount = action.payload?.total
        state.shipmentlog = action.payload?.data
      }
      state.shipmentLogLoading = false
      console.log('getShipmentListAsync is in fulfilled state ', action.payload)
    })
    //get order list end
  },

  reducers: {
    setShipmentlogPaginationData: (
      state,
      action: {payload: PAGINATION_DATA_FOR_SET; type: string}
    ) => {
      state.shipmentPaginationData = {...state.shipmentPaginationData, ...action.payload}
    },
  },
})

export const {setShipmentlogPaginationData} = shipmentlogSlice.actions

export default shipmentlogSlice.reducer
