import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'

export const getOrdersListAsync = createAsyncThunk(
  'connection/getOrdersListAsync',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', '/get-order-logs', [], toolkit, params)
  }
)

export const resyncOrderItem = createAsyncThunk(
    'connection/resyncOrderItem',
    async function (params: any, toolkit) {
      return await AxiosClient('GET', '/resync-data', [], toolkit, params)
    }
  )