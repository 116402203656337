import {configureStore} from '@reduxjs/toolkit'
import authSlice from './slices/authSlice'
import accountSlice from './slices/connectionSlice'
import configurationSlice from './slices/configurationSlice'
import commonSlice from './slices/commonSlice'
import orderlogSlice from './slices/orderlogSlice'
import inventorySlice from './slices/inventorySlice'
import shipmentlogSlice from './slices/shipmentlogSlice'
import plroductlogSlice from './slices/productlogSlice'

const store = configureStore({
  reducer: {
    test: () => {
      return null
    },

    auth: authSlice,
    accounts: accountSlice,
    configuration:configurationSlice,
    common:commonSlice,
    orderlog: orderlogSlice,
    inventory:inventorySlice,
    shipment: shipmentlogSlice,
    productLog: plroductlogSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck:false}),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
